<template>
	<v-card :loading="formLoading" :disabled="formLoading" 
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template v-if="formTitle">
			<v-card-title class="title font-weight-regular">
				<span>{{ formTitle }}</span>
			</v-card-title>
			<v-divider></v-divider>
		</template>
		<v-card-text class="card-text-padding">
			<v-container class="no-padding" fluid>
				<v-form :ref="`${formNameBefore}`">
					<slot name="before"></slot>
				</v-form>

				

				<v-row no-gutters>
          <v-col cols="12">
						<v-stepper v-model="step">
							<v-stepper-header>
								<template v-for="n in steps">
									<v-stepper-step
										:key="`${n.value}-step`"
										:complete="step > n.value"
										:step="n.value"
									>
									{{n.text}}
									</v-stepper-step>

									<v-divider
										v-if="n.value !== steps.length"
										:key="n.value"
									></v-divider>
								</template>
							</v-stepper-header>

							<v-stepper-items>
								<v-stepper-content
									v-for="n in steps"
									:key="`${n.value}-content`"
									:step="n.value"
								>
									<v-card>
										<template v-if="!hideSubheading">
											<v-card-title class="title font-weight-regular justify-space-between grey lighten-3">
												<span>{{ n.text }}</span>
												<!-- <v-avatar
													color="primary"
													class="subheading white--text"
													size="30"
													v-text="n.value"
												></v-avatar> -->
											</v-card-title>
											<v-divider></v-divider>
										</template>
										<v-card-title v-else-if="$vuetify.breakpoint.smAndDown" class="title font-weight-regular grey lighten-3">
											<span>{{ n.text }}</span>
										</v-card-title>

										<v-card-text class="card-text-padding grey lighten-5">
											<v-form :ref="`${formName + n.value}`">
												<slot :name="`formContent-${n.value}`"></slot>
											</v-form>
										</v-card-text>
									</v-card>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-col>
        </v-row>
			</v-container>
			<small v-if="!hideLabelRequiredFields">*Indica los campos requeridos</small>
		</v-card-text>

		<v-divider></v-divider>
		<v-card-actions>
			<v-btn v-if="step > 1" :disabled="step === 1" color="blue darken-1" text @click="$emit('prevStepButton')">Atras</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="!hideCancelButton" color="blue darken-1" text @click="cancel()">{{cancelButtonText}}</v-btn>
			<v-btn v-if="!hideSaveButton && step === steps.length" color="primary darken-1" depressed @click="save()" :loading="formLoading">{{saveButtonText}}</v-btn>
			<v-btn v-if="step < steps.length" color="primary darken-1" depressed @click="nextStep()">Siguiente</v-btn>
			<!-- <v-btn v-else :disabled="step === steps.length" color="primary darken-1" depressed @click="nextStep()">Siguiente</v-btn> -->
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name:"BaseStepperForm",
	props:{
		formTitle: String,
		formLoading: {type: Boolean, default: false},
		step: Number,
		steps: Array,
		// resetForm: {type: Boolean, default: false},
		hideSubheading: {type: Boolean, default: false},
		saveButtonText: {type: String, default: 'Guardar'},
		cancelButtonText: {type: String, default: 'Cancelar'},
		hideSaveButton: {type: Boolean, default: false},
		hideCancelButton: {type: Boolean, default: false},
		hideLabelRequiredFields: {type: Boolean, default: false},
	},

	data() {
		return {
			formName: 'stepper_form_',
			formNameBefore: 'stepper_form_before',
		}
	},

	// watch: {
	// 	resetForm(val) {
  //     !val || this.reset();
  //   }
	// },

	methods: {
		nextStep(){
			var before = this.$refs[this.formNameBefore].validate();
			var form = this.formName + this.step;
			if (!this.$refs[form][0].validate() || !before) {
				return;
			}
      this.$emit('nextStepButton');
		},

		reset(){
			var form;
			this.$refs[this.formNameBefore].resetValidation();
			this.steps.forEach(step => {
				form = this.formName + step.value;
				this.$refs[form][0].resetValidation();
			});
		},
		
		cancel(){
			this.reset();
			this.$emit('cancelButton');
		},

		save(){
			var before = this.$refs[this.formNameBefore].validate();
			var form;
			var isValid = false;
			this.steps.forEach(step => {
				form = this.formName + step.value;
				var stepForm = this.$refs[form][0].validate();
				isValid = (stepForm && before);
			});

			if (isValid) {
				this.$emit('saveButton');
			}
		}
	},
}
</script>

<style scoped>
	/* .v-card > .v-card__text { */
	.card-text-padding {
    padding: 10px 15px 10px;
  }

	.v-stepper__content {
    padding: 16px 16px 16px
	}
</style>