<template>
	<base-form-simple
		formTitle="Seleccione un producto"
		:formLoading="productFormLoading"
		hideSaveButton
		@cancelButton="closeSearch"
	>
		<template v-slot:formContent>
			<v-container class="no-padding" fluid>
				<v-row align="center" >
					<template v-if="$vuetify.breakpoint.mdAndUp">
						<v-col cols="12">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<base-text-field v-on="on" v-model="productFilter" 
										clearable
										class="text-xs-center"
										prepend-inner-icon="mdi-magnify" 
										label="Ingrese el código o nombre del producto."
										:hint="productFilterHint"
										@keyup.enter="fetchProductsByFilter()" counter maxlength="50" ></base-text-field>
								</template>
									<span>Ingrese el código o nombre del producto y presione la tecla 'Enter'</span>
							</v-tooltip>
						</v-col>
					</template>
					<template v-else>
						<v-col cols="12" sm="7" md="8" align="center" :class="{'pb-0' : $vuetify.breakpoint.xs}">
							<base-text-field v-model="productFilter" 
								clearable
								class="text-xs-center"
								prepend-inner-icon="mdi-magnify" 
								label="Ingrese el código o nombre del producto."
								:hint="productFilterHint"
								counter maxlength="50" ></base-text-field>
						</v-col>
						<v-col cols="12" sm="5" md="4" align="center" :class="{'pb-6' : $vuetify.breakpoint.xs}">
							<base-btn color="secondary" @click="fetchProductsByFilter()" icon="mdi-magnify" btnText="Buscar Producto" :disabled="!productFilterIsValid"></base-btn>
						</v-col>
					</template>
					<v-col cols="12">
						<base-form-container
							title="Lista de productos"
							titleClasses="info darken-1 white--text"
							noPaddingContent
							flat
						>
							<template v-slot:content>
								<base-data-iterator
									v-model="sortBy"
									:headers="productHeaders" 
									:items="products"
									hideHeader
									elevation="0"
								> 
									<template v-slot:props="{ props }">
										<v-row>
											<v-col v-for="item in props.items"
												:key="item.customCode" 
												cols="12" sm="6" md="4" lg="3"
											>
												<v-card
													class="mx-auto"
													color="grey lighten-4"
												>
													<base-product-img v-model="item.image" :productId="item.id"
														gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
														aspect-ratio="1.3"
														contain
													>
													</base-product-img>

													<v-card-text
														class="pt-5"
														style="position: relative;"
      										>
														<v-scale-transition v-model="item.isSelected" mode="out-in">
															<v-btn v-if="item.isSelected"
																absolute
																color="red"
																class="white--text"
																fab
																x-small
																right
																top
																@click="$emit('removeProduct', item)"
															>
																<v-icon>mdi-close</v-icon>
															</v-btn>
															<v-btn v-else
																absolute
																color="orange"
																class="white--text"
																fab
																x-small
																right
																top
																@click="addProduct(item)"
															>
																<v-icon>mdi-cart</v-icon>
															</v-btn>
														</v-scale-transition>

														<h4 class="font-weight-blod orange--text">
															{{item.visualName}}
														</h4>

														<div class="font-weight-regular grey--text subtitle-2 mb-2">
															{{item.customCode}}
														</div>

														<div class="font-weight-medium grey--text body-1 mb-2">
															<template v-if="mode == 'Sales'">Stock disp.:</template>
															<template v-else>Stock total:</template>
															 {{item.stock}}
														</div>

														<div v-if="mode == 'Sales'" class="font-weight-medium grey--text body-1 mb-2">
															Bs. {{ item.salePricePerUnit }}
														</div>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
									</template>

									<template v-if="productsNotFound" v-slot:no-data>
										No se encontraron registros coincidentes con <strong>'{{productsNotFound}}'</strong>
									</template>
								</base-data-iterator>
							</template>
						</base-form-container>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</base-form-simple>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	name:"BaseProductFinder",
	props:{
		mode: {
			type: String,
			required: true,
			validator: v => ['Sales', 'Purchases', 'Inventory'].includes(v),
		},
		// saleMode: { type: Boolean, default: true },
		productsAdded: { type: Array, required: true },

		selectedBranchOfficeId: { type: String },
		inventoryMovementTypeId: { type: [String, Number] }
	},

	data() {
		return {
			sortBy: 'visualName',
			productFormLoading:false,
			productFilter:'',
			productFilterIsValid:false,
			productHeaders: [
				{ text: "Código", value: "customCode", sortable: false, visible: false },
				{ text: "Producto", value: "visualName", sortable: false },
        // { text: "Categoría", value: "category", sortable: false },
				{ text: "Stock", value: "stock", sortable: false },
				{ text: "Precio", value: "salePricePerUnit", sortable: false },
			],
			products:[],
			productsNotFound:'',
			validationRules: {
				productFilterRules: {
					counter: value => (!!value && value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
				},
			},
		}
	},

	watch: {
		productsAdded:{
			deep: true,
			immediate: true,
			handler: 'updateProductsAdded'
		}
	},

	computed: {
		productFilterHint(){
			var result = this.validationRules.productFilterRules.counter(this.productFilter);
			if (result == true) {
				this.productFilterIsValid = true;
				return '';
			}
			else {
				this.productFilterIsValid = false;
				return result;
			}
		},
	},

	methods: {
		allowedHeaders(obj){
			if ('visible' in obj)
			{
				return obj.visible;
			}
			else {
				return true;
			}
		},

		async fetchProductsByFilter(){
			let me=this;
			if (!me.productFilterIsValid) {
				return;
			}
			
			try {
				me.SHOW_LOADING()
				me.products=[]
				me.productFormLoading=true;
				let request;
				let url;
				if (me.mode == 'Sales') {
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'branchOfficeId': me.companyData.branchOfficeId,
						'filter': me.productFilter,
					};
					url='api/ProductInventory/GetProductsByFilter';
				}
				else if (me.mode == 'Purchases'){
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'filter': me.productFilter,
					};
					url='api/Product/GetAllByFilter';
				}
				else if (me.mode == 'Inventory') {
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'branchOfficeId': me.selectedBranchOfficeId,
						'filter': me.productFilter,
						'inventoryMovementTypeId': parseInt(me.inventoryMovementTypeId, 10)
					};
					url='api/ProductInventory/GetProductInventoryByFilter';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						me.productsNotFound=me.productFilter;
						return;
					}
					me.productsNotFound='';
					if (data.products.length <= 0) {
						me.productsNotFound=me.productFilter;
					}
					me.products = data.products;
					me.updateProductsAdded(me.productsAdded);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.productFormLoading=false
      }
		},

		updateProductsAdded(val) {
			var newProducts = this.products.slice();
			newProducts.map(x => {
				x.isSelected = false;
			});

			if (val.length <= 0 || newProducts.length <= 0) {
				this.products = newProducts;
				return;
			}

			newProducts.forEach(x => {
				if (val.includes(x.id)) {
					x.isSelected = true;
				}
			});

			this.products = newProducts;
		},

		addProduct(item) {
			this.$emit('addProduct', item)
		},

		closeSearch() {
			this.products=[];
			this.productFilter='';
			this.productFilterIsValid=false;
			this.productsNotFound='';
			this.$emit('cancelButton');
    },
	},
}
</script>