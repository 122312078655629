<template>
	<v-dialog fullscreen hide-overlay v-model="showDialog" persistent scrollable>

		<template v-slot:activator="{ on }">
			<v-btn v-on="on" class="ml-2" min-width="0" icon @click="lock()">
				<v-icon large>mdi-lock-clock</v-icon>
			</v-btn>
		</template>


		<v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.90);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
					<v-card shaped
						class="mx-auto"
						max-width="500"
						:loading="formLoading" :disabled="formLoading"
					>
						<v-card-title class="pl-1 pt-1">
							<v-card flat color="transparent">
								<v-img
									width="180"
									:src="require('@/assets/logo_globaldesk.svg')"
								></v-img>
							</v-card>
						</v-card-title>

						<v-card class="px-md-4 pt-6" flat align="center">
							<v-card flat color="transparent">
								<v-img
									width="150"
									:src="require('@/assets/logo_gavcom_150_90.png')"
								></v-img>
							</v-card>
						</v-card>

						<v-form ref="LoginForm">
							<v-card-text>
								<h3 class="title font-weight-light text-center mb-4">
									¿Deseas continuar en el sistema?
								</h3>
								<v-row no-gutters justify="center">
									<v-col cols="12" sm="10" md="8">
										<!-- <base-text-field autofocus v-model="editedItem.user" label="Usuario" maxlength="100" 
											prepend-icon="mdi-account"
											:rules="[commonValRules.required]" required
										></base-text-field> -->
										<base-text-field 
											v-model="editedItem.password"
											label="Contraseña"
											prepend-icon="mdi-lock"
											:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPwd ? 'text' : 'password'"
											maxlength="25"
											@click:append="showPwd = !showPwd"
											:rules="[commonValRules.required]"
											required
											@keypress.enter="login()"
										></base-text-field>
									</v-col>
								</v-row>
							</v-card-text>
						</v-form>

						<v-card-actions>
							<v-btn rounded
								color="secondary"
								depressed
								@click="$router.push({ name: 'Logout' })"
								:loading="formLoading"
							>
								<v-icon size="25" left>mdi-logout</v-icon>
								Salir
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn rounded
								color="primary"
								depressed
								@click="login()"
								:loading="formLoading"
							>
								<v-icon size="25" left>mdi-login</v-icon>
								Ingresar
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import MainExternalData from '@/mixins/main-external-data'
export default {
	name: 'BaseFormLock',
	mixins: [MainExternalData],

	data() {
		return {
			inactivityInterval: null,
			idleTime: 0,
			maxIdleTime: 100, //10 segundos = 10000, 60000 = 1 minuto
			showDialog: false,
			formLoading: false,
			showPwd: false,
			editedItem: {
				user:'',
				password:''
			},
			defaultItem: {
				user:'',
				password:''
			},
		}
	},
	
	created() {
		// console.log("Created-lock")
		this.inactivityTime();
	},

	methods: {
		async login() {
			let me=this;
			if (!me.$refs.LoginForm.validate()) {
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'user': me.editedItem.user,
					'password': me.editedItem.password,
				}
				// let data = await me.getObjectResponseWithoutHeaders('api/Account/Login', request);
				// if (data === undefined) {
				// 	return;
				// }

				// switch (data.next) {
				// 	case 0:
				// 		me.saveToken(data.token);
				// 		break;
				// 	case 1:
				// 		me.saveTemporaryToken(data.token);
				// 		await me.choiceCompanies();
				// 		break;
				// 	case 2:
				// 		me.saveTemporaryToken(data.token);
				// 		await me.choiceBranchOffices();
				// 		break;
				// }

			// me.$refs.LoginForm.reset();
      // me.editedItem = Object.assign({}, this.defaultItem);
				
				me.clean();
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		clean() {
			this.showDialog = false;
			this.idleTime = 0;
			this.inactivityInterval = null;
			this.resetTimer();
			this.$refs.LoginForm.reset();
			this.editedItem = Object.assign({}, this.defaultItem);
		},

		inactivityTime() {
			window.onload = this.resetTimer;
			document.onmousedown = this.resetTimer;
			document.onmousemove = this.resetTimer;
			document.onkeypress = this.resetTimer;
		},

		resetTimer() { 
			// console.log('resetTimer-1', this.idleTime)
			this.idleTime = 0;
			if (this.inactivityInterval) {
				return;
			}
			// console.log('resetTimer-2', this.idleTime)
			const inactivityInterval = setInterval(this.timerIncrement, this.maxIdleTime)
			this.inactivityInterval = inactivityInterval;
		},

		timerIncrement() { 
			this.idleTime = this.idleTime + 1;
			// console.log('timerIncrement', this.idleTime)
			if (this.idleTime >= this.maxIdleTime) {
				// console.log('timerStop', this.idleTime)
				clearInterval(this.inactivityInterval)
				this.showDialog = true;
			}
		},

		lock() {
			this.idleTime = this.maxIdleTime
		}
	},

}
</script>
<style>
	
</style>