var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({class:_vm.classes,attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":_vm.sortBy,"hide-default-footer":"","items-per-page":_vm.itemsPerPage.value,"page":_vm.page,"hide-default-header":"","sort-desc":_vm.sortDesc,"expanded":_vm.expanded},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.itemsPerPage, "value", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.itemsPerPage, "value", $event)},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.numberOfPages = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(!_vm.hideHeader)?{key:"top",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar',{attrs:{"dark":"","color":"blue darken-1"}},[(!_vm.hideReloadButton)?[_c('base-btn',{attrs:{"color":"secondary","icon":"mdi-reload","btnText":"Actualizar"},on:{"click":function($event){return _vm.$emit('loadDataTable')}}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})]:_vm._e(),(!_vm.hideSearchEngine)?[_c('base-text-field',{staticClass:"text-xs-center",attrs:{"color":"white","clearable":"","single-line":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Búsqueda"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')]:_vm._e(),_c('base-select',{attrs:{"color":"white","value":_vm.sortBy,"single-line":"","hide-details":"","items":_vm.keys,"prepend-inner-icon":"mdi-sort","label":"Ordene por"},on:{"change":function($event){return _vm.$emit('change', $event)}}}),_c('v-btn-toggle',{attrs:{"dense":"","rounded":"","group":"","mandatory":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue","value":false}},on),[_c('v-icon',[_vm._v("mdi-sort-ascending")])],1)]}}],null,false,274892663)},[_c('span',[_vm._v(_vm._s(_vm.ascTooltip))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue","value":true}},on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}],null,false,3790592540)},[_c('span',[_vm._v(_vm._s(_vm.descTooltip))])])],1),(!_vm.hideNewItemButton)?[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('base-btn',{attrs:{"color":"primary","icon":_vm.newItemButtonIcon,"btnText":_vm.newItemButtonText},on:{"click":function($event){return _vm.$emit('newItem')}}})]:_vm._e()],2):_c('v-card',{staticClass:"mb-2",attrs:{"dark":"","color":"blue darken-1","elevation":"10"}},[_c('v-container',{staticClass:"no-padding px-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-1",attrs:{"align":"center","justify":"center"}},[(!_vm.hideSearchEngine)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-text-field',{staticClass:"text-xs-center",attrs:{"color":"white","clearable":"","single-line":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Búsqueda"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"6"}},[_c('base-select',{staticClass:"pt-2 mr-1",attrs:{"color":"white","value":_vm.sortBy,"single-line":"","hide-details":"","items":_vm.keys,"prepend-inner-icon":"mdi-sort","label":"Ordene por"},on:{"change":function($event){return _vm.$emit('change', $event)}}}),_c('v-btn-toggle',{attrs:{"dense":"","rounded":"","group":"","mandatory":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue","value":false}},on),[_c('v-icon',[_vm._v("mdi-sort-ascending")])],1)]}}],null,false,274892663)},[_c('span',[_vm._v(_vm._s(_vm.ascTooltip))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue","value":true}},on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}],null,false,3790592540)},[_c('span',[_vm._v(_vm._s(_vm.descTooltip))])])],1)],1),(!_vm.hideReloadButton)?_c('v-col',{attrs:{"cols":"6","sm":"6","align":"center"}},[_c('v-btn',{attrs:{"rounded":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('loadDataTable')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Actualizar ")],1)],1):_vm._e(),(!_vm.hideNewItemButton)?_c('v-col',{attrs:{"cols":"6","sm":"6","align":"center"}},[_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.$emit('newItem')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.newItemButtonIcon))]),_vm._v(" "+_vm._s(_vm.newItemButtonText)+" ")],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._t("content")],2)]},proxy:true}:null,(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',{class:_vm.headerBackgroundColor},_vm._l((headers),function(h,i){return _c('th',{key:i,staticClass:"custom-data-table-header"},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}}:null,{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_vm._t("group.header",null,null,{items: items, isOpen: isOpen, toggle: toggle})]}},_vm._l((_vm.headers.filter(function (x) { return x.value !== 'data-table-expand'; })),function(h){return {key:("item." + (h.value)),fn:function(ref){
var item = ref.item;
return [_vm._t(("item." + (h.value)),[_vm._v(" "+_vm._s(item[h.value])+" ")],{"item":item})]}}}),{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_vm._t("expanded-item",null,null,{ item: item })],2)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_vm._t("item-data-table-expand",null,null,{ expand: expand, isExpanded: isExpanded, item: item })]}},(_vm.showEnabledField)?{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled)?[_c('span',{staticClass:"blue--text"},[_vm._v("Activo")])]:[_c('span',{staticClass:"red--text"},[_vm._v("Inactivo")])]]}}:null,{key:"no-data",fn:function(){return [_vm._t("no-data",[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"text-center",attrs:{"text":"","elevation":"2","color":"red","transition":"scale-transition"}},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v("mdi-alert-circle")]),_vm._v(" "),_vm._t("no-results",[_vm._v("No se encontraron registros coincidentes con "),_c('strong',[_vm._v("'"+_vm._s(_vm.search)+"'")])])],2)]},proxy:true},(!_vm.hideFooter)?{key:"footer",fn:function(){return [_c('v-divider',{staticClass:"my-0"}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : '',attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticClass:"grey--text pt-2"},[_vm._v("Filas")]):_c('span',{staticClass:"grey--text"},[_vm._v("Filas por página")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'ml-2',attrs:{"dark":"","text":"","color":"grey"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage.text)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2810217712)},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number.text))])],1)}),1)],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : '',attrs:{"cols":"12","sm":"6","align":_vm.$vuetify.breakpoint.smAndDown ? '' : 'end'}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticClass:"mr-4 pt-2 grey--text"},[_vm._v(" Pág. "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]):_c('span',{staticClass:"mr-4 grey--text"},[_vm._v(" Página "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","text":"","small":""},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","text":"","small":""},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)]},proxy:true}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }