<template>
	<base-form-simple
		formTitle="Seleccione un cliente"
		:formLoading="customerFormLoading"
		hideSaveButton
		@cancelButton="closeSearch"
	>
		<template v-slot:formContent>
			<v-container class="no-padding" fluid>
				<v-row align="center" >
					<template v-if="$vuetify.breakpoint.mdAndUp">
					<v-col cols="12">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<base-text-field v-on="on" v-model="customerFilter" 
									clearable
									class="text-xs-center"
									prepend-inner-icon="mdi-magnify" 
									label="Ingrese el nombre del cliente."
									:hint="customerFilterHint"
									@keyup.enter="fetchCustomersByFilter()" counter maxlength="50" ></base-text-field>
							</template>
								<span>Ingrese el nombre y presione la tecla 'Enter'</span>
						</v-tooltip>
					</v-col>
					</template>
					<template v-else>
						<v-col cols="12" sm="7" md="8" align="center" :class="{'pb-0' : $vuetify.breakpoint.xs}">
							<base-text-field v-model="customerFilter" 
								clearable
								class="text-xs-center"
								prepend-inner-icon="mdi-magnify" 
								label="Ingrese el nombre del cliente."
								:hint="customerFilterHint"
								counter maxlength="50" ></base-text-field>
						</v-col>
						<v-col cols="12" sm="5" md="4" align="center" :class="{'pb-6' : $vuetify.breakpoint.xs}">
							<base-btn color="secondary" @click="fetchCustomersByFilter()" icon="mdi-magnify" btnText="Buscar Cliente" :disabled="!customerFilterIsValid"></base-btn>
						</v-col>
					</template>
					<v-col cols="12">
							<v-card dark color="info darken-1"  class="justify-center">
								<v-card-title class="justify-center">Lista de clientes</v-card-title>
							</v-card>
							<base-data-table 
								:headers="customerHeaders" 
								:items="customers"
								hideHeader
								
							>
								<template v-slot:item.options="{ item }">
									<base-icon-btn @click="selected(item)" icon="mdi-check-bold" tooltip="Seleccionar Cliente"></base-icon-btn>
								</template>

								<template v-if="customersNotFound" v-slot:no-data>
									No se encontraron registros coincidentes con <strong>'{{customersNotFound}}'</strong>
								</template>
							</base-data-table>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</base-form-simple>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	name:"BaseCustomerFinder",
	props:{
	},

	data() {
		return {
			customerFormLoading:false,
			customerFilter:'',
			customerFilterIsValid:false,
			customerHeaders: [
				{ text: "Seleccionar", value: "options", sortable: false },
				// { text: "Nacionalidad", value: "nationality", sortable: false },
				{ text: "Nombre", value: "fullName", sortable: false },
			],
			customers:[],
			customersNotFound:'',
			validationRules: {
				customerFilterRules: {
					counter: value => (!!value && value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
				},
			},
		}
	},

	computed: {
		customerFilterHint(){
			var result = this.validationRules.customerFilterRules.counter(this.customerFilter);
			if (result == true) {
				this.customerFilterIsValid = true;
				return '';
			}
			else {
				this.customerFilterIsValid = false;
				return result;
			}
		},
	},

	methods: {
		async fetchCustomersByFilter(){
			let me=this;
			if (!me.customerFilterIsValid) {
				return;
			}
			
			try {
				me.SHOW_LOADING()
				me.customers=[]
				me.customerFormLoading=true
				let request = {
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'filter': me.customerFilter,
				};
				await me.getObjectResponse('api/Customer/GetAllByFilter', request).then(data => {
					if (data !== undefined) {
						me.customersNotFound=me.customerFilter;
					}
					else {
						me.customersNotFound='';
					}
					me.customers = data.customers; 
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.customerFormLoading=false
      }
		},

		clear() {
			this.customers=[];
			this.customerFilter="";
			this.customerFilterIsValid=false;
			this.customersNotFound='';
		},
		
		selected(item) {
			this.clear();
			this.$emit('selected', item);
    },

		closeSearch() {
			this.clear();
			this.$emit('cancelButton');
    },
	},
}
</script>