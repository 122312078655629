import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

  const routes = [
    {
      path: '',
      component: () => import(/* webpackChunkName: "CoreIndex" */ '@/layouts/gavcom/core/Index.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import(/* webpackChunkName: "Home" */ '@/views/home/Dashboard.vue'),
          meta: {
            requireAuth: true
          }
        },

        //******** CONFIGURACIONES ********/
        {
          path: '/userprofile',
          name: 'Perfil',
          component: () => import(/* webpackChunkName: "Perfil" */ '@/views/settings/UserProfile.vue'),
          meta: {
            requireAuth: true
          }
        },

        //******** ALMACEN ********/
        {
          path: '/categories',
          name: 'Categorías',
          component: () => import(/* webpackChunkName: "Categorías" */ '@/views/warehouse/ProductCategory.vue'),
          meta: {
            admin: true,
            prod: true,
          }
        },
        {
          path: '/products',
          name: 'Productos',
          component: () => import(/* webpackChunkName: "Productos" */ '@/views/warehouse/Product.vue'),
          meta: {
            admin: true,
            prod: true,
          }
        },
        {
          path: '/inventory',
          name: 'Inventario',
          component: () => import(/* webpackChunkName: "Inventario" */ '@/views/warehouse/Inventory.vue'),
          meta: {
            admin: true,
            inv: true,
          }
        },
        //******** ADMINISTRACIÓN ********/
        {
          path: '/roles',
          name: 'Roles',
          component: () => import(/* webpackChunkName: "Roles" */ '@/views/company/Role.vue'),
          meta: {
            admin: true
          }
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: () => import(/* webpackChunkName: "Usuarios" */ '@/views/company/User.vue'),
          meta: {
            admin: true
          }
        },
        {
          path: '/branchoffices',
          name: 'Sucursales',
          component: () => import(/* webpackChunkName: "Sucursales" */ '@/views/company/BranchOffice.vue'),
          meta: {
            admin: true
          }
        },
        //******** COMPRAS ********/
        {
          path: '/providers',
          name: 'Proveedores',
          component: () => import(/* webpackChunkName: "Proveedores" */ '@/views/purchase/Provider.vue'),
          meta: {
            admin: true,
            prov: true,
          }
        },
        {
          path: '/purchases',
          name: 'Compras',
          component: () => import(/* webpackChunkName: "Compras" */ '@/views/purchase/Index.vue'),
          meta: {
            admin: true,
            comp: true,
          }
        },
        {
          path: '/purchases/credits',
          name: 'CreditosCompras',
          component: () => import(/* webpackChunkName: "CreditosCompras" */ '@/views/purchase/Credit.vue'),
          meta: {
            admin: true,
            credcomp: true,
          }
        },
        //******** VENTAS ********/
        {
          path: '/customers',
          name: 'Clientes',
          component: () => import(/* webpackChunkName: "Clientes" */ '@/views/sale/Customer.vue'),
          meta: {
            admin: true,
            cli: true,
          }
        },
        {
          path: '/sales',
          name: 'Ventas',
          component: () => import(/* webpackChunkName: "Ventas" */ '@/views/sale/Index.vue'),
          meta: {
            admin: true,
            vent: true,
          }
        },
        {
          path: '/sales/credits',
          name: 'CreditosVentas',
          component: () => import(/* webpackChunkName: "CreditosVentas" */ '@/views/sale/Credit.vue'),
          meta: {
            admin: true,
            credvent: true,
          }
        },
        //******** REPORTES ********/
        {
          path: '/inventoryreport',
          name: 'ReporteInventario',
          component: () => import(/* webpackChunkName: "ReporteInventario" */ '@/views/report/Inventory.vue'),
          meta: {
            admin: true,
            repo: true,
          }
        },
        {
          path: '/purchasesreport',
          name: 'ReporteCompras',
          component: () => import(/* webpackChunkName: "ReporteCompras" */ '@/views/report/Purchases.vue'),
          meta: {
            admin: true,
            repo: true,
          }
        },
        {
          path: '/salesreport',
          name: 'ReporteVentas',
          component: () => import(/* webpackChunkName: "ReporteVentas" */ '@/views/report/Sales.vue'),
          meta: {
            admin: true,
            repo: true,
          }
        },
      ],
    },
      
    {
      path: '',
      component: () => import(/* webpackChunkName: "FullPageIndex" */ '@/layouts/gavcom/full_page/Index.vue'),
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "Login" */ '@/views/pages/Login.vue'),
          meta: {
            free: true
          }
        },
        {
          path: '/register',
          name: 'Registro',
          component: () => import(/* webpackChunkName: "Registro" */ '@/views/pages/Register.vue'),
          meta: {
            free: true
          }
        },
        {
          path: '/account/verify-email/:token',
          name: 'VerifyEmail',
          component: () => import(/* webpackChunkName: "VerifyEmail" */ '@/views/pages/VerifyEmail.vue'),
          meta: {
            free: true
          }
        },
        {
          path: '/account/send-verification-email',
          name: 'SendVerificationEmail',
          component: () => import(/* webpackChunkName: "SendVerificationEmail" */ '@/views/pages/SendVerificationEmail.vue'),
          meta: {
            free: true
          }
        },
        {
          path: '/account/forgot-password',
          name: 'ForgotPassword',
          component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/pages/ForgotPassword.vue'),
          meta: {
            free: true
          }
        },
        // {
        //   path: '/pricing',
        //   name: 'Precios',
        //   component: () => import(/* webpackChunkName: "Precios" */ '@/views/pages/Pricing.vue'),
        //   meta: {
        //     free: true
        //   }
        // },
        {
          path: '/loading',
          name: 'InitialData',
          component: () => import(/* webpackChunkName: "InitialData" */ '@/views/pages/InitialData.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/account/change-password',
          name: 'ChangePassword',
          component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/pages/ChangePassword.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/account/logout',
          name: 'Logout',
          component: () => import(/* webpackChunkName: "Logout" */ '@/views/pages/Logout.vue'),
          meta: {
            requireAuth: true
          }
        },
      ]
    },

    {
      path: '/customer-form/:token',
      name: 'CustomerForm',
      component: () => import(/* webpackChunkName: "CustomerForm" */ '@/views/pages/CustomerForm.vue'),
      meta: {
        free: true
      }
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   console.log(to, from, savedPosition);
  //   return new Promise(resolve => {
  //     const position = (function() {
  //       if (savedPosition) {
  //         return savedPosition;
  //       } else if (to.hash) {
  //         return {
  //           selector: to.hash
  //         };
  //       }
  //     })();
  //     router.app.$root.$once("triggerScroll", () => {
  //       router.app.$nextTick(() => resolve(position));
  //     });
  //   });
  // }
})

router.beforeEach((to, from, next) => {
  store.dispatch("autoLogin")
  // console.log('router-1', to)
  if (to.matched.some(record => record.meta.free)) {
    // console.log('router-2')
    // if (to.name === 'Login' || to.name === 'Registro') {
    if (to.name === 'Login' || to.name === 'Registro' || to.name === 'VerifyEmail' || to.name === 'SendVerificationEmail' || to.name === 'ForgotPassword') {
      // console.log('router-3')
      if (store.state.token) {
        // console.log('has-token')
        next({ name: 'Home' })  
      }
    }
    // console.log('router-4')
    next()
  } else if (store.state.token && store.state.authData) {
    // console.log('router-5')
    if (to.matched.some(record => record.meta.requireAuth)) {
      // console.log('router-6')
      next()
    } else if (to.matched.some(record => record.meta.admin) && store.state.authData.roles.includes('Admin')) {
      next()
    } else if (to.matched.some(record => record.meta.cli) && store.state.authData.roles.includes('Cli')) {
      next()
    } else if (to.matched.some(record => record.meta.comp) && store.state.authData.roles.includes('Comp')) {
      next()
    } else if (to.matched.some(record => record.meta.credcomp) && store.state.authData.roles.includes('CredComp')) {
      next()
    } else if (to.matched.some(record => record.meta.cont) && store.state.authData.roles.includes('Cont')) {
      next()
    } else if (to.matched.some(record => record.meta.fac) && store.state.authData.roles.includes('Fac')) {
      next()
    } else if (to.matched.some(record => record.meta.inv) && store.state.authData.roles.includes('Inv')) {
      next()
    } else if (to.matched.some(record => record.meta.prod) && store.state.authData.roles.includes('Prod')) {
      next()
    } else if (to.matched.some(record => record.meta.prov) && store.state.authData.roles.includes('Prov')) {
      next()
    } else if (to.matched.some(record => record.meta.repo) && store.state.authData.roles.includes('Repo')) {
      next()
    } else if (to.matched.some(record => record.meta.vent) && store.state.authData.roles.includes('Vent')) {
      next()
    } else if (to.matched.some(record => record.meta.credvent) && store.state.authData.roles.includes('CredVent')) {
      next()
    }
    else {
      //TODO Que vaya a una pagina 404 o acceso denegado
      next({ name: 'Home' })
    }
  } else {
    next({ name: 'Login' })
  }
})


export default router
