<template>
	<v-tooltip v-model="show" bottom>
		<!-- <template v-slot:activator="{ on, attrs }"> -->
		<template v-slot:activator="{ on }">
			<v-hover>
				<template v-slot="{ hover }">
					<v-btn :elevation="hover ? maxElevation : minElevation" 
						:icon="!fabMode" 
						:fab="fabMode" 
						@click="$emit('click')" 
						class="mx-1"
						:color="btnColor"
						v-bind="$attrs" 
						v-on="on"
						small
					>
						<v-icon :color="color" :size="size">{{icon}}</v-icon>
					</v-btn>
				</template>
			</v-hover>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'BaseIconBtn',

	props: {
		size: { type: [Number, String], default: 22 },
		icon: { type: String, required: true },
		// btnClass: { type: String, default: ''},
		btnColor: { type: String, default: 'teal darken-1'},
		color: { type: String, default: 'teal darken-1'},
		tooltip: { type: String, default: 'Tooltip...'},
		minElevation: { type: [Number, String], default: 1 },
		maxElevation: { type: [Number, String], default: 8 },

		fabMode: { type: Boolean, default: false}
	},

	data() {
		return {
			show: false,
		}
	},
}
</script>