<template>
	<base-text-field class="centered-input custom-max-width"
		v-bind="$attrs"
    v-on="$listeners"
		v-bind:value="value" v-on:input="$emit('input', $event)"
		:placeholder="placeholder"
		:rules="customRules"
		:required="required"
		:prefix="prefix" 
		:clearable="customClearable"
		maxlength="10"
		@keypress="onlyForCurrency($event, value)"
	></base-text-field>
</template>

<script>
// Mixins
import DataTools from '@/mixins/data-tools'

export default {
	name: 'BaseCurrencyField',
	mixins: [DataTools],

	model:{
		prop:'value',
		event:'input'
	},

	props: {
		value: {type: [String, Number] },
		prefix: {type: String, default: 'Bs.' },
		required: {type: Boolean, default: false},
		disableDefaultRules: {type: Boolean, default: false},
		rules: { type: Array }
	},

	data() {
		return {
			placeholder: '0.00',
			moneyRules: {
				counter: value => (value != null && value.toString().length <= 10) || 'No debe tener más de 10 caracteres.',
			},
		}
	},

	computed: {
		customRules() {
			let result = [];
			if (this.required) {
				result.push(this.commonValRules.required);
			}

			if (this.disableDefaultRules) {
				this.rules.forEach(rule => {
					result.push(rule)
				});
				return result;
			}
			
			result.push(this.moneyRules.counter);

			if (this.rules && this.rules.length > 0) {
				this.rules.forEach(rule => {
					result.push(rule)
				});
			}
			return result;
		},

		customClearable() {
			if (this.$attrs.disabled || this.$attrs.readonly) {
				return ;
			}
			return true;
		}
	},
}
</script>