<template>
	<v-window v-model="step" touchless>
		<v-window-item :value="1">
			<base-form-simple
				formTitle="Selección de productos"
				:formLoading="productFormLoading"
				hideSaveButton
				@cancelButton="closeSearch"
			>
				<template v-slot:titleContent>
					<v-spacer></v-spacer>
					<v-icon @click="closeSearch()">mdi-close</v-icon>
				</template>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row align="center" >
							<template v-if="$vuetify.breakpoint.mdAndUp">
								<v-col cols="12">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<base-text-field v-on="on" v-model="productFilter" 
												clearable
												class="text-xs-center"
												prepend-inner-icon="mdi-magnify" 
												label="Ingrese el código o nombre del producto."
												:hint="productFilterHint"
												@keyup.enter="fetchProductsByFilter()" counter maxlength="50" ></base-text-field>
										</template>
											<span>Ingrese el código o nombre del producto y presione la tecla 'Enter'</span>
									</v-tooltip>
								</v-col>
							</template>
							<template v-else>
								<v-col cols="12" sm="7" md="8" align="center" :class="{'pb-0' : $vuetify.breakpoint.xs}">
									<base-text-field v-model="productFilter" 
										clearable
										class="text-xs-center"
										prepend-inner-icon="mdi-magnify" 
										label="Ingrese el código o nombre del producto."
										:hint="productFilterHint"
										counter maxlength="50" ></base-text-field>
								</v-col>
								<v-col cols="12" sm="5" md="4" align="center" :class="{'pb-6' : $vuetify.breakpoint.xs}">
									<base-btn color="secondary" @click="fetchProductsByFilter()" icon="mdi-magnify" btnText="Buscar Producto" :disabled="!productFilterIsValid"></base-btn>
								</v-col>
							</template>
							<v-col cols="12">
								<base-form-container
									title="Lista de productos"
									titleClasses="info darken-1 white--text"
									noPaddingContent
									flat
								>
									<template v-slot:content>
										<base-data-iterator
											v-model="sortBy"
											:headers="productHeaders" 
											:items="products"
											hideHeader
											elevation="0"
										> 
											<template v-slot:props="{ props }">
												<v-row>
													<v-col v-for="item in props.items"
														:key="item.customCode" 
														cols="12" sm="6" md="4" lg="3"
													>
													<v-hover v-slot="{ hover }">
														<v-card
															class="mx-auto"
															color="grey lighten-4"
														>
															<base-product-img v-model="item.image" :productId="item.id"
																gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																aspect-ratio="1.3"
																contain
															>

																<v-fab-transition leave-absolute>
																	<v-overlay
																		v-if="hover"
																		absolute
																		color="grey darken-3"
																	>
																		<v-btn
																			icon
																			@click="getMoreInfo(item)"
																		>
																			<v-icon color="grey lighten-2" size="50">mdi-eye-outline</v-icon>
																		</v-btn>
																	
																	</v-overlay>
																</v-fab-transition>
															</base-product-img>

															<v-card-text
																class="pt-5"
																style="position: relative;"
															>
																<v-scale-transition v-if="selectProduct(item.stock, 1)" mode="out-in" origin="center">
																	<v-btn v-if="item.isSelected" key="a"
																		absolute
																		color="red"
																		class="white--text"
																		fab
																		x-small
																		right
																		top
																		@click="$emit('removeProduct', item)"
																	>
																		<v-icon>mdi-close</v-icon>
																	</v-btn>
																	<v-btn v-else key="b"
																		absolute
																		color="orange"
																		class="white--text"
																		fab
																		x-small
																		right
																		top
																		@click="addProduct(item)"
																	>
																		<v-icon>mdi-cart</v-icon>
																	</v-btn>
																</v-scale-transition>

																<h4 class="font-weight-blod orange--text">
																	{{item.visualName}}
																</h4>

																<div class="font-weight-regular grey--text subtitle-2 mb-2">
																	{{item.customCode}}
																</div>

																<div class="font-weight-medium grey--text body-1 mb-2">
																	<span :class="item.stock > 0 ? '' : 'red--text'">{{item.stock > 0 ? `Stock: ${item.stock}` : 'Agotado'}}</span>
																</div>

																<div v-if="mode == 'Sales'" class="font-weight-medium grey--text body-1 mb-2">
																	Bs. {{ item.salePricePerUnit }}
																</div>
															</v-card-text>
														</v-card>
													</v-hover>
													</v-col>
												</v-row>
											</template>

											<template v-if="productsNotFound" v-slot:no-data>
												No se encontraron registros coincidentes con <strong>'{{productsNotFound}}'</strong>
											</template>
										</base-data-iterator>
									</template>
								</base-form-container>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>
		</v-window-item>
		<v-window-item :value="2">
			<base-form-container
				:title="`Cod. ${selectedProduct.customCode}`"
				titleClasses="grey lighten-3"
			>
				<template v-slot:titleContent>
					<v-spacer></v-spacer>
					<v-icon @click="back()">mdi-arrow-left</v-icon>
				</template>
				<template v-slot:content>
					<v-row justify="center">
						<v-col cols="12" sm="8">
							<v-sheet
								class="mx-auto"
								elevation="0"
								max-width="100%"
							>
								<v-expand-transition>
									<v-card v-if="selectedImage != null"
										class="pa-0"
										elevation="8"
									>
										<v-img
											:src="selectedProduct.pictures.length > 0 ? selectedProduct.pictures[selectedImage] : defaultProductImage"
											:lazy-src="defaultProductImage" 
											aspect-ratio="1" 
											contain
										>
											<template v-slot:placeholder>
												<v-row
													class="fill-height ma-0"
													align="center"
													justify="center"
												>
													<v-progress-circular
														indeterminate
														color="grey lighten-5"
													></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</v-card>
								</v-expand-transition>

								<v-slide-group v-if="selectedProduct.pictures.length > 0"
									v-model="selectedImage"
									center-active
									mandatory
									show-arrows
								>
									<v-slide-item
										v-for="(pic, index) in selectedProduct.pictures"
										:key="index"
										v-slot="{ toggle }"
									>
										<v-card flat
											class="my-4 mx-2"
											height="auto"
											width="100px"
											@click="toggle"
										>
											<v-img 
												:src="pic"
												:lazy-src="defaultProductImage"
												aspect-ratio="1.3"
												contain
											>
												<template v-slot:placeholder>
													<v-row
														class="fill-height ma-0"
														align="center"
														justify="center"
													>
														<v-progress-circular
															indeterminate
															color="grey lighten-5"
														></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</v-card>
									</v-slide-item>
								</v-slide-group>
							</v-sheet>
						</v-col>
						<v-col cols="12" sm="4">
							<h2 class="font-weight-blod orange--text">
								{{selectedProduct.visualName}}
							</h2>
							<div class="font-weight-regular grey--text subtitle-2 mb-2">
								{{selectedProduct.customCode}}
							</div>
							<div class="font-weight-medium grey--text body-1 mb-2">
								<div><v-icon size="9">mdi-circle</v-icon> <strong>Categoría:</strong> {{selectedProduct.category}}</div>
								<div><v-icon size="9">mdi-circle</v-icon> <strong>Marca:</strong> {{selectedProduct.maker}}</div>
								<div><v-icon size="9">mdi-circle</v-icon> <strong>Hecho en:</strong> {{selectedProduct.madeIn}}</div>
								<div v-if="mode == 'Sales'">
									<v-icon size="9">mdi-circle</v-icon> <strong>Precio:</strong> Bs. {{ selectedProduct.salePricePerUnit }}
								</div>
							</div>
							<v-alert
								text
								elevation="2"
								class="py-2 mt-2"
								:color="selectedProduct.stock > 0 ? 'success' : 'red'"
								transition="scale-transition"
							>
								<v-icon :color="selectedProduct.stock > 0 ? 'success' : 'red'" left>{{selectedProduct.stock > 0 ? 'mdi-check-circle': 'mdi-alert-circle'}}</v-icon> <strong>{{selectedProduct.stock > 0 ? `Stock: ${selectedProduct.stock}` : 'Agotado'}}</strong>
							</v-alert>

							<div v-if="showCardOptions" class="d-flex justify-space-around mb-4">
								<v-scale-transition v-if="selectProduct(selectedProduct.stock, 2)" mode="out-in" origin="center">
									<base-btn v-if="selectedItem.isSelected" key="a" dark color="red" @click="$emit('removeProduct', selectedItem)" icon="mdi-close" btnText="Quitar"></base-btn>
									<base-btn v-else key="b" dark color="orange" @click="addProduct(selectedItem)" icon="mdi-cart" btnText="Agregar"></base-btn>
								</v-scale-transition>

								<v-scale-transition v-if="consultBranchOffices()" mode="out-in" origin="center">
									<base-btn v-if="stepToConsultBranchOffices == 1" dark color="primary" @click="showBranchOfficeStock()" icon="mdi-office-building-marker" btnText="Ver en suc."></base-btn>
									<base-btn v-else key="b" dark color="grey" @click="closeBranchOfficeStock()" icon="mdi-arrow-left" btnText="Volver"></base-btn>
								</v-scale-transition>
							</div>

							<v-window v-model="stepToConsultBranchOffices" touchless>
								<v-window-item :value="1">
									<template v-if="selectedProduct.productDetails.length > 0">
										<h4 class="font-weight-blod orange--text">
											Detalles del producto
										</h4>
										<div class="font-weight-medium grey--text body-1 mb-2">
											<div v-for="(item, index) in selectedProduct.productDetails" :key="index">
												<v-icon size="9">mdi-circle</v-icon> <strong>{{item.title}}:</strong> {{item.text}}
											</div>
										</div>
									</template>

									<template v-if="selectedProduct.details.length > 0">
										<h4 class="font-weight-blod orange--text">
											Información adicional
										</h4>
										<div class="font-weight-medium grey--text body-1">
											<v-responsive
												class="overflow-y-auto"
												max-height="200"
											>
												<div v-for="(item, index) in selectedProduct.details" :key="index">
													<v-icon color="success" small>mdi-check-circle</v-icon> {{item}}
												</div>
											</v-responsive>
										</div>
									</template>
								</v-window-item>
								<v-window-item :value="2">
									<v-list two-line subheader>
										<template v-for="(item, index) in branchOfficesFound">
											<h4 v-if="item.header" :key="item.header" class="font-weight-blod orange--text">
												{{item.header}}
											</h4>

											<v-alert v-if="item.branchOffices.length <= 0" :key="`-1-${item.header}`"
												text
												elevation="2"
												class="text-center py-2 mt-2"
												color="info"
												transition="scale-transition"
											>
												<v-icon color="info" left>mdi-alert-circle</v-icon> <strong>No se encontraron sucursales con stock disponible.</strong>
											</v-alert>

											<template v-else v-for="(branch, bIndex) in item.branchOffices">
												<v-list-item
													:key="branch.title"
													class="px-0 font-weight-medium body-1"
												>
													<v-list-item-avatar class="mr-1">
														<v-icon color="red">
															mdi-map-marker
														</v-icon>
													</v-list-item-avatar>

													<v-list-item-content>
														<v-list-item-title v-text="branch.title"></v-list-item-title>
														<v-list-item-subtitle v-text="branch.address"></v-list-item-subtitle>
														<v-list-item-subtitle v-text="branch.country"></v-list-item-subtitle>
													</v-list-item-content>

													<v-list-item-action class="grey--text">
														<v-list-item-action-text>Stock</v-list-item-action-text>
														<v-avatar
															dark
															color="green"
															class="white--text font-weight-medium body-2"
															size="35"
															v-text="branch.stock"
														></v-avatar>
													</v-list-item-action>
												</v-list-item>

												<v-divider
													v-if="bIndex < item.branchOffices.length - 1"
													:key="bIndex" 
													inset
												></v-divider>
											</template>

											<v-divider :key="`${index}-${item.header}`" class="mb-2"></v-divider>
										</template>
									</v-list>
								</v-window-item>
							</v-window>
						</v-col>
					</v-row>
				</template>
			</base-form-container>
		</v-window-item>
	</v-window>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	name:"BaseProductFinder",
	props:{
		mode: {
			type: String,
			required: true,
			validator: v => ['Sales', 'Purchases', 'Inventory'].includes(v),
		},
		productsAdded: { type: Array, required: true },

		selectedBranchOfficeId: { type: String },
		inventoryMovementTypeId: { type: [String, Number] }
	},

	data() {
		return {
			step: 1,
			sortBy: 'visualName',
			productFormLoading:false,
			productFilter:'',
			productFilterIsValid:false,
			productHeaders: [
				{ text: "Código", value: "customCode", sortable: false, visible: false },
				{ text: "Producto", value: "visualName", sortable: false },
				{ text: "Stock", value: "stock", sortable: false },
				{ text: "Precio", value: "salePricePerUnit", sortable: false },
			],
			products:[],
			productsNotFound:'',
			//SELECTED ITEM
			selectedProduct: {
				productId: '',
				customCode: '',
				visualName: '',
				category: '',
				maker: '',
        madeIn: '',
				stock: '',
				salePricePerUnit: '',
				productDetails: [],
				details: [],
				pictures:[],
				canConsultBranchOffices: false
			},
			defaultSelectedProduct: {
				productId: '',
				customCode: '',
				visualName: '',
				category: '',
				maker: '',
        madeIn: '',
				stock: '',
				salePricePerUnit: '',
				productDetails: [],
				details: [],
				pictures:[],
				canConsultBranchOffices: false
			},
			selectedItem: null,
			selectedImage: null,
			//CONSULT BRANCH OFFICES
			stepToConsultBranchOffices: 1,
			branchOfficesFound: [
				{ 
					header: 'En tu región',
					branchOffices: []
				},
				{ 
					header: 'Otras sucursales',
					branchOffices: []
				}
			],
			validationRules: {
				productFilterRules: {
					counter: value => (!!value && value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
				},
			},
		}
	},

	watch: {
		productsAdded:{
			deep: true,
			immediate: true,
			handler: 'updateProductsAdded'
		}
	},

	computed: {
		productFilterHint(){
			var result = this.validationRules.productFilterRules.counter(this.productFilter);
			if (result == true) {
				this.productFilterIsValid = true;
				return '';
			}
			else {
				this.productFilterIsValid = false;
				return result;
			}
		},

		showCardOptions() {
			return this.selectProduct(this.selectedProduct.stock, 2) || this.consultBranchOffices()
		}
	},

	methods: {
		allowedHeaders(obj){
			if ('visible' in obj)
			{
				return obj.visible;
			}
			else {
				return true;
			}
		},

		async fetchProductsByFilter(){
			let me=this;
			if (!me.productFilterIsValid) {
				return;
			}
			
			try {
				me.SHOW_LOADING()
				me.products=[]
				me.productFormLoading=true;
				let request;
				let url;
				if (me.mode == 'Sales') {
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'branchOfficeId': me.companyData.branchOfficeId,
						'filter': me.productFilter,
					};
					url='api/ProductInventory/GetProductsByFilter';
				}
				else if (me.mode == 'Purchases'){
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'filter': me.productFilter,
					};
					url='api/Product/GetAllByFilter';
				}
				else if (me.mode == 'Inventory') {
					request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,
						'branchOfficeId': me.selectedBranchOfficeId,
						'filter': me.productFilter,
						'inventoryMovementTypeId': parseInt(me.inventoryMovementTypeId, 10)
					};
					url='api/ProductInventory/GetProductInventoryByFilter';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						me.productsNotFound=me.productFilter;
						return;
					}
					me.productsNotFound='';
					if (data.products.length <= 0) {
						me.productsNotFound=me.productFilter;
					}
					me.products = data.products;
					me.updateProductsAdded(me.productsAdded);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.productFormLoading=false
      }
		},

		updateProductsAdded(val) {
			var newProducts = this.products.slice();
			newProducts.map(x => {
				x.isSelected = false;
			});
			if (val.length <= 0 || newProducts.length <= 0) {
				this.products = newProducts;

				if (!!this.selectedItem) {
					this.selectedItem.isSelected = false;
				}
				return;
			}

			newProducts.forEach(x => {
				if (val.includes(x.id)) {
					x.isSelected = true;
				}
			});

			if (!!this.selectedItem) {
				this.selectedItem.isSelected = newProducts.find(x => x.id === this.selectedItem.id).isSelected;
			}

			this.products = newProducts;
		},

		addProduct(item) {
			this.$emit('addProduct', item)
		},

		closeSearch() {
			this.products=[];
			this.productFilter='';
			this.productFilterIsValid=false;
			this.productsNotFound='';
			this.$emit('cancelButton');
    },

		selectProduct(stock, value) {
			if (value == 2) {
				if (this.selectedItem == null || this.selectedItem == undefined) {
					return false;	
				}
			}

			if (this.mode == 'Sales') {
				if (stock <= 0) {
					return false;
				}
			}
			return true;
		},

		consultBranchOffices() {
			if (this.mode == "Sales") {
				return this.selectedProduct.canConsultBranchOffices;
			}
			return false;
		},

		async getMoreInfo(item) {
			let me = this;
			try {
				me.SHOW_LOADING()
				me.productFormLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': item.id,
				};
				await me.getObjectResponse('api/Product/GetMoreInfo', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.selectedProduct.productId = item.id;
					me.selectedProduct.customCode = item.customCode;
					me.selectedProduct.visualName = item.visualName;
					me.selectedProduct.stock = item.stock;
					me.selectedProduct.salePricePerUnit = item.salePricePerUnit;

					me.selectedProduct.category = data.product.category;
					me.selectedProduct.maker = data.product.maker;
					me.selectedProduct.madeIn = data.product.madeIn;
					if (!!data.product.productDetails) {
						me.selectedProduct.productDetails = JSON.parse(data.product.productDetails);
					}
					if (!!data.product.details) {
						me.selectedProduct.details = JSON.parse(data.product.details);
					}
					if (!!data.product.pictures && data.product.pictures.length > 0) {
						me.selectedProduct.pictures = data.product.pictures;	
					} else {
						me.selectedImage = 0;
					}
					me.selectedProduct.canConsultBranchOffices = data.product.canConsultBranchOffices;
					me.selectedItem = Object.assign({}, item);
					me.step = 2;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.productFormLoading=false
      }
		},

		back() {
			let me = this;
			me.updateProductPicture();
			me.selectedProduct = Object.assign({}, me.defaultSelectedProduct);
			me.selectedImage = null;
			me.selectedItem = null;
			me.step = 1;
			me.branchOfficesFound[0].branchOffices = [];
			me.branchOfficesFound[1].branchOffices = [];
			me.stepToConsultBranchOffices = 1;
		},

		updateProductPicture() {
			let me = this;
			var product = me.products.find(x => x.id === me.selectedProduct.productId);
			product.image = null
		},

		async showBranchOfficeStock() {
			let me = this;
			try {
				me.SHOW_LOADING()
				me.productFormLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'code': me.selectedProduct.customCode,
				};
				await me.getObjectResponse('api/ProductInventory/FetchProductInBranchOffices', request).then(data => {
					if (data === undefined) {
						return;
					}
					let branchOfficesArray = data.branchOffices;
					branchOfficesArray.map((x) => {
						if (x.nearby) {
							me.branchOfficesFound[0].branchOffices.push({
								title: x.branchOffice,
								address: x.address,
								country: x.country,
								phoneNumber: x.phoneNumber,
								stock: x.stock
							});
						} else {
							me.branchOfficesFound[1].branchOffices.push({
								title: x.branchOffice,
								address: x.address,
								country: x.country,
								phoneNumber: x.phoneNumber,
								stock: x.stock
							});
						}
					});
					me.stepToConsultBranchOffices = 2;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.productFormLoading=false
      }
		},

		closeBranchOfficeStock() {
			this.branchOfficesFound[0].branchOffices = [];
			this.branchOfficesFound[1].branchOffices = [];
			this.stepToConsultBranchOffices = 1;
		},
	},
}
</script>
<style>
	.v-slide-group__next, .v-slide-group__prev{
		min-width: 10px;
	}
</style>