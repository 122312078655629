<template>
	<v-container class="no-padding-y" fluid>
		<v-row justify="center">
			<v-col cols="12" sm="6" align="center">
				<base-label text="*Género">
					<v-radio-group class="mt-0 pt-0"
						v-model="details.gender"
						row
						:rules="[commonValRules.required]" required
					>
						<v-radio v-for="gender in genders" :key="gender.id"
							:label="gender.visualName"
							:value="gender.id"
							color="info"
						></v-radio>
					</v-radio-group>
				</base-label>
			</v-col>

			<v-col cols="12" sm="3" align="center">
				<base-text-field v-model="details.material" label="Material" counter maxlength="20" 
				placeholder="Ej. Algodón" :rules="[commonValRules.required, validationRules.materialRules.counter]" clearable required></base-text-field>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
// Mixins
import DataTools from '@/mixins/data-tools'

export default {
	name: 'BaseProductClothingDetails',
	mixins: [DataTools],

	props: {
		details: {type: Object, required: true},
	},

	data() {
		return {
			genders:[
				{id: 'M', visualName: "Hombre"},
				{id: 'F', visualName: "Mujer"},
				{id: 'U', visualName: "Unisex"},
			],

			validationRules: {
        materialRules: {
          counter: value => (value != null && value.length <= 20) || 'No debe tener más de 20 caracteres.',
        },
			}
		}
	},

	computed: {
	},

	methods: {
	},
}
</script>