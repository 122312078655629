<template>
	<v-dialog fullscreen hide-overlay v-model="showDialogToChangeBranch" persistent scrollable>
		<v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.90);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
					<v-card shaped
						class="mx-auto"
						max-width="600"
						:loading="formLoading" :disabled="formLoading"
					>
						<v-card-title class="pl-1 pt-1">
							<v-card flat color="transparent">
								<v-img
									width="180"
									:src="require('@/assets/logo_globaldesk.svg')"
								></v-img>
							</v-card>
						</v-card-title>

						<v-card class="px-md-4 pt-6" flat align="center">
							<v-card flat color="transparent">
								<v-img
									width="150"
									:src="require('@/assets/logo_gavcom_150_90.png')"
								></v-img>
							</v-card>
						</v-card>


						<v-card-text class="text-center">
							<h3 class="title font-weight-light text-center mb-4">
								<template v-if="branchOffices.length>0">
									¿Deseas cambiar de sucursal?
								</template>
								<template v-else>
									No cuenta con otras sucursales asignadas
								</template>
							</h3>
							<v-item-group
								v-model="selectedBranchOffice"
								mandatory
								@change="changeSelectedBranchOffice"
							>
								<v-row justify="center">
									<v-col v-for="(item, i) in branchOffices"
										:key="i"
										cols="6"
										sm="6"
										md="6"
										lg="4"
									>
										<v-item v-slot="{ active, toggle }">
											<v-card @click="toggle" class="pa-4"
												:elevation="active ? 8 : 0" 
											>
												<v-icon class="mb-2" size="50">{{item.main ? 'mdi-office-building' : 'mdi-home-city'}}</v-icon>
												<br>
												<v-icon class="mb-2" :color="active ? 'primary' : 'grey'" size="30"> 
													{{ active ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-marked-circle-outline' }}
												</v-icon>
												<h4>{{item.visualName}}</h4>
											</v-card>
										</v-item>
									</v-col>
								</v-row>
							</v-item-group>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-btn 
								color="blue darken-1"
								text
								@click="clean()"
							>
								Cancelar
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn v-if="branchOffices.length>0" rounded
								color="primary darken-1"
								depressed
								@click="changeToSelectedBranch()"
								:loading="formLoading"
							>
								<v-icon size="25" left>mdi-swap-horizontal</v-icon>
								Cambiar
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MainExternalData from '@/mixins/main-external-data'
export default {
	name: 'BaseFormChangeBranchOffice',
	mixins: [MainExternalData],

	data() {
		return {
			formLoading: false,
			branchOffices:[],
			branchOfficeId:'',
			selectedBranchOffice: 0,
		}
	},

	computed: {
    ...mapState(["userData", "showDialogToChangeBranch"]),
  },

	watch: {
		showDialogToChangeBranch(val) {
			if (val === true) {
				this.selectedBranchOffice = 0;
				this.getAssignedBranchOffices();
			}
		}
	},

	methods: {
		...mapActions(['saveToken']),
		async getAssignedBranchOffices() {
      let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading = true
				me.branchOffices = []
				await me.getObjectResponse('api/UserBranchOffice/GetAssignedBranchOffices', me.baseRequest).then(data => {
					if (data !== undefined) {
						var branchOfficesArray = data.branchOffices;
						me.userData.assignedBranchOffices = branchOfficesArray.length;
						branchOfficesArray.map(function(x){
							if (x.id != me.companyData.branchOfficeId)
							{
								me.branchOffices.push(x);
							}
						});
						me.changeSelectedBranchOffice(me.selectedBranchOffice);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		changeSelectedBranchOffice(index) {
			let me = this;
			if (me.branchOffices.length>0)
			{
				me.branchOfficeId = me.branchOffices[index].id;
			}
		},
		
		async changeToSelectedBranch() {
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.branchOfficeId,
				}

				await me.getObjectResponse('api/Account/ChangeToAnotherBranch', request).then(data => {
					if (data !== undefined) {
						me.saveToken(data.token);
						me.clean();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		clean() {
			this.$store.commit("SET_SHOW_DIALOG_TO_CHANGE_BRANCH", false);
			this.selectedBranchOffice = 0;
			this.branchOffices = [];
			this.branchOfficeId = '';
		},
	},

}
</script>
<style>
	
</style>