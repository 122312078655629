<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		:close-on-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<base-text-field
				v-model="dateRangeText"
				label="Rango de fechas"
				hint="Formato DD/MM/YYYY"
				persistent-hint
				prepend-icon="mdi-calendar"
				readonly
				v-bind="$attrs"
				v-on="on"
			></base-text-field>
		</template>
		<v-date-picker
			v-model="dates"
			no-title
			range
			scrollable
			:first-day-of-week="1"
			locale="bo"
			:max="maxDate"
			@input="inputDate"
		>
			<v-spacer></v-spacer>
			<v-btn
				text
				color="info"
				@click="cancelButton"
			>
				Cancelar
			</v-btn>
			<v-btn
				text
				color="primary"
				@click="okButton"
				:disabled="!validDateRange"
			>
				OK
			</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'BaseDateRangePicker',

	props: {
	},

	data() {
		return {
			dates:[],
			maxDate: this.parseDate(new Date().toLocaleDateString('bo')),
			menu: false,
			validDateRange: false,
		}
	},

	computed: {
		dateRangeText() {
			if (this.dates.length <= 0) return null

			const datesFormatted = [this.formatDate(this.dates[0]), this.formatDate(this.dates[1])]
      return datesFormatted.join(' - ')
		},
	},

	created() {
  },

	methods: {
		defaultDates() {
			const [year, month, day] = this.maxDate.split('-')
			this.dates=[`${year}-${month.padStart(2, '0')}-01`, `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`]
		},

		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
		},

		parseDate(date) {
			if (!date) return null

			const [day, month, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},

		inputDate(date){
			if (date.length !== 2) {
				this.validDateRange=false
			}
			else if (date[0] > date[1]) {
				this.validDateRange=false
				this.dates=[]
			}
			else {
				this.validDateRange=true
			}
		},

		cancelButton(){
			this.dates=[];
			this.menu = false
			this.$emit('range', null);
		},

		okButton(){
			if (!this.validDateRange) {
				return;	
			}
			this.menu = false
			const datesFormatted = [this.formatDate(this.dates[0]), this.formatDate(this.dates[1])]
			this.$emit('range', datesFormatted);
		}
	},
}
</script>