var tools = {};
tools.downloadDocument = function (fileName, fileContent) {
	// let blob = new Blob([data.fileContent], {
	// 	tipo: 'application/pdf'
	// });
	// let objectUrl = URL.createObjectURL(blob);
	let link = document.createElement("a");
	let fname = fileName; // El nombre del archivo descargado
	link.href = fileContent; //objectUrl;
	link.setAttribute("download", fname);
	link.style.display = 'none';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default {
	install(Vue, options) {
		Vue.prototype.$Tools = {
			downloadDocument: (fileName, fileContent) => tools.downloadDocument(fileName, fileContent)
		}
	}
}