<template>
	<v-dialog
		v-model="timeModal"
		persistent
		width="700px"
	>
		<template v-slot:activator="{ on }">
			<base-text-field
				v-model="timeRangeText"
				:label="label"
				prepend-inner-icon="mdi-clock-time-four-outline"
				readonly
				v-bind="$attrs"
				v-on="on"
			></base-text-field>
		</template>

		<base-form-simple	v-if="timeModal"
			:formTitle="label"
			hide-label-required-fields
			@cancelButton="cancelButton"
			@saveButton="okButton"
		>
			<template v-slot:formContent>
				<v-container class="no-padding" fluid>
					<v-row v-if="timeModal"
						justify="space-around"
						align="center"
					>
						<v-col style="width: 320px; flex: 0 1 auto;">
							<h2 class="pb-3">De hrs.:</h2>
							<v-time-picker
								v-model="start"
								:max="end"
								scrollable
								format="24hr"
								:width="$vuetify.breakpoint.smAndDown ? '225': '290'"
							></v-time-picker>
						</v-col>
						<v-col style="width: 320px; flex: 0 1 auto;">
							<h2 class="pb-3 ">A hrs.:</h2>
							<v-time-picker
								v-model="end"
								:min="start"
								scrollable
								format="24hr"
								:width="$vuetify.breakpoint.smAndDown ? '225': '290'"
							>
							</v-time-picker>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</base-form-simple>
	</v-dialog>
</template>

<script>
export default {
	name: 'BaseTimeRangePicker',

	model:{
		prop:'input',
		event:'getValue'
	},

	props: {
		input: {type: String, default: null}, 
		label: {type: String, default: 'Rango de Horas'},
	},

	data() {
		return {
			start: null,
      end: null,
			timeModal: false,
			isValidTime: false,
		}
	},

	watch: {
		input(val){
			this.verifyInput();
		}
	},

	computed: {
		timeRangeText() {
			this.isValidTime = false
			if (!this.start || !this.end) return null
			if (!this.validateTime(this.start) || !this.validateTime(this.end)) return null
			this.isValidTime = true
      return `De: ${this.start} - ${this.end}`
		},
	},

	created() {
		this.verifyInput();
	},

	methods: {
		verifyInput(){
			if (!this.input) {
				this.start = null
				this.end = null
				return;
			}
			const time = this.input.split('e:')
			if (time.length !== 2) {
				this.start = null
				this.end = null
				return;
			}

			const [start, end] = time[1].split('-')
			if (!start || !end) {
				this.start = null
				this.end = null
			}
			else {
				this.start = start.trim()
				this.end = end.trim()
			}
		},

		validateTime(value){
			if (!value || !this) {
				return false
			}
			const [hh, mm] = value.split(':')
			if (!hh || !mm) {
				return false
			}
			return true
		},

		cancelButton(){
			this.timeModal = false
			this.$emit('getValue', this.timeRangeText);
		},

		okButton(){
			if (!this.isValidTime) {
				return;	
			}
			this.timeModal = false
			this.$emit('getValue', this.timeRangeText);
		}
	},
}
</script>