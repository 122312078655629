<template>
	<v-text-field :color="color" class="input-uppercase"
		v-bind="$attrs"
    v-on="$listeners"
		:label="customLabel"
		:required="required"
	>
		<template v-slot:append>
			<slot name="append"></slot>
		</template>
	</v-text-field>
</template>

<script>
export default {
	name: 'BaseTextField',

	props: {
		label: {type: String },
		required: {type: Boolean, default: false},
		color: {type: String, default: 'info'}
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		},
	},
}
</script>

<style>
	/* .input-uppercase input{
		text-transform: uppercase
	} */
</style>