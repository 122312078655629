import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=f9257bc6&scoped=true&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"
import style0 from "./Container.vue?vue&type=style&index=0&id=f9257bc6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9257bc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VContainer,VDivider})
