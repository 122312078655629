var database = {};
// window.indexedDB = window.indexedDB || window.webkitIndexedDB ||
// 	window.mozIndexedDB;

if ('webkitIndexedDB' in window) {
	window.IDBTransaction = window.webkitIDBTransaction;
	window.IDBKeyRange = window.webkitIDBKeyRange;
}
								
const STORE_PARAMETERS = 'parameters';

database.indexedDB = {};
database.indexedDB.db = null;

database.indexedDB.onerror = function(e) {
	console.log(e);
};

database.indexedDB.open = function(dbName, dbVersion) {
	var request = indexedDB.open(dbName, dbVersion);

	// We can only create Object stores in a versionchange transaction.
	request.onupgradeneeded = function(e) {
		var db = e.target.result;

		// A versionchange transaction is started automatically.
		e.target.transaction.onerror = database.indexedDB.onerror;

		if(db.objectStoreNames.contains(STORE_PARAMETERS)) {
			db.deleteObjectStore(STORE_PARAMETERS);
		}

		var store = db.createObjectStore(STORE_PARAMETERS,
			{keyPath: "id"});

		// var store = db.createObjectStore(STORE_PARAMETERS,
		// 	{keyPath: ["id","version"]});
	};

	request.onsuccess = function(e) {
		database.indexedDB.db = e.target.result;
	};

	request.onerror = database.indexedDB.onerror;
};

database.indexedDB.addOrUpdateItem = function (storeName, data) {
	return new Promise((resolve, reject) => {
		let result;
		var db = database.indexedDB.db;
    const tx = db.transaction(storeName, 'readwrite');
    tx.oncomplete = _ => resolve(result);
    tx.onerror = event => reject(event.target.error);
    const store = tx.objectStore(storeName);
    const request = store.put(data);
		request.onsuccess = _ => result = request.result;
	});
};

// database.indexedDB.updateItem = function (storeName, data) {
// 	return new Promise((resolve, reject) => {
// 		let result;
// 		var db = database.indexedDB.db;
//     const tx = db.transaction(storeName, 'readwrite');
//     tx.oncomplete = _ => resolve(result);
//     tx.onerror = event => reject(event.target.error);
//     const store = tx.objectStore(storeName);
//     const request = store.put(data);
// 		request.onsuccess = _ => result = request.result;
// 	});
// };

// database.indexedDB.setItem = function (storeName, id, data) {
// 	return new Promise((resolve, reject) => {
// 		let result;
// 		var db = database.indexedDB.db;
//     const tx = db.transaction(storeName, 'readwrite');
//     tx.oncomplete = _ => resolve(result);
//     tx.onerror = event => reject(event.target.error);
//     const store = tx.objectStore(storeName);
//     const request = store.put(data, id);
// 		request.onsuccess = _ => result = request.result;
// 	});
// };

database.indexedDB.deleteItem = function (storeName, id) {
	return new Promise((resolve, reject) => {
		let result;
		var db = database.indexedDB.db;
    const tx = db.transaction(storeName, 'readwrite');
    tx.oncomplete = _ => resolve(result);
    tx.onerror = event => reject(event.target.error);
    const store = tx.objectStore(storeName);
    const request = store.delete(id);
    request.onsuccess = _ => result = request.result;
	});
};

database.indexedDB.getItem = function (storeName, id) {
	return new Promise((resolve, reject) => {
		let result;
		var db = database.indexedDB.db;
    const tx = db.transaction(storeName, 'readwrite');
    tx.oncomplete = _ => resolve(result);
    tx.onerror = event => reject(event.target.error);
    const store = tx.objectStore(storeName);
    const request = store.get(id);
    request.onsuccess = _ => result = request.result;
  });
};

export default {
	install(Vue, options) {
		database.indexedDB.open(options.database, options.version);
		Vue.prototype.$IDB = {
			addOrUpdateParameter: (data) => database.indexedDB.addOrUpdateItem(STORE_PARAMETERS, data),
			deleteParameter: (key) => database.indexedDB.deleteItem(STORE_PARAMETERS, key),
			// updateParameter: (data) => database.indexedDB.updateItem(STORE_PARAMETERS, data),
			// setParameter: (key, data) => database.indexedDB.setItem(STORE_PARAMETERS, key, data),
			getParameter: (key) => database.indexedDB.getItem(STORE_PARAMETERS, key)
		}
	}
}