<template>
	<v-card :loading="formLoading" :disabled="formLoading"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template v-if="formTitle">
			<v-card-title class="title font-weight-regular" :class="titleClasses">
				<span>{{ formTitle }}</span>
				<slot name="titleContent"></slot>
			</v-card-title>
			<v-divider></v-divider>
		</template>

		<v-card-text class="card-text-padding">
			<v-container class="no-padding" fluid>
				<v-form :ref="formName" onSubmit="return false;">
					<slot name="formContent"></slot>
				</v-form>
			</v-container>
			<small v-if="!hideLabelRequiredFields">*Indica los campos requeridos</small>
		</v-card-text>

		<template v-if="!hideActions">
			<v-divider></v-divider>
			<v-card-actions>
				<template v-if="!hideDefaultActions">
					<v-spacer></v-spacer>
					<v-btn v-if="!hideCancelButton" color="blue darken-1" text @click="cancel()">{{cancelButtonText}}</v-btn>
					<v-btn v-if="!hideSaveButton" color="primary darken-1" depressed @click="save()" :loading="formLoading">{{saveButtonText}}</v-btn>
					<slot name="formActions"></slot>
				</template>
				<template v-else>
					<slot name="customFormActions"></slot>
				</template>
			</v-card-actions>
		</template>
	</v-card>
</template>

<script>
export default {
	name:"BaseSimpleForm",
	props:{
		formTitle: String,
		formLoading: {type: Boolean, default: false},
		// resetForm: {type: Boolean, default: false},
		hideDefaultActions: {type: Boolean, default: false},
		saveButtonText: {type: String, default: 'Guardar'},
		cancelButtonText: {type: String, default: 'Cancelar'},
		hideSaveButton: {type: Boolean, default: false},
		hideCancelButton: {type: Boolean, default: false},
		hideLabelRequiredFields: {type: Boolean, default: false},
		hideActions: {type: Boolean, default: false},

		titleClasses: {type: String, default: ''},
	},

	data() {
		return {
			formName: 'simple_form'
		}
	},

	// watch: {
	// 	resetForm(val) {
  //     !val || this.reset();
  //   }
	// },

	methods: {
		reset(){
			this.$refs[this.formName].resetValidation();
		},

		validate(){
			return this.$refs[this.formName].validate()
		},

		cancel(){
			this.reset();
			this.$emit('cancelButton');
		},

		save(){
			if (!this.$refs[this.formName].validate()){
				return;
			}
			this.$emit('saveButton');
		}
	},
}
</script>

<style scoped>
	/* .v-card > .v-card__text { */
	.card-text-padding {
    padding: 10px 15px 10px;
  }
</style>