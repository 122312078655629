<template>
    <router-view />
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'App',

    computed: {
      ...mapGetters(['mainDataLoaded'])
    },

    created() {
      //this.$store.dispatch("autoLogin")
    },

    watch: {
      $route(to, from) {
        // console.log('app-route', to, from)
        if (!to.matched.some(record => record.meta.free)) {
          // console.log('app-route-not-free')
          // console.log('app-route-to', to.name)
          // if (to.name != 'InitialData') {
          //     console.log('app-route-to', to.name)
          //     return;
          //   }

          // console.log('app-store', this.$store.state)
          // console.log('app-store-dataloaded', this.mainDataLoaded)

          if (!this.mainDataLoaded) {
            if (to.name == 'InitialData') {
              // console.log('app-route-is-InitialData')
              return;
            }
            // console.log('app-route-push-InitialData')
            this.$router.push({ name: 'InitialData' });
            return;
          }

          
          
          // this.$router.push({ name: 'InitialData' });
          
          return;
        }
        // console.log('app-route-free')
      }
    },
  }
</script>