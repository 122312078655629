<template>
  <base-material-card
    class="v-card--material-chart"
    image-padding="2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:heading>
      <div v-if="!loaded" style="height: 250px;">
      </div>
      <template v-else>
        <base-chart-bar v-if="type==='Bar'"
          :chartdata="data" 
          :options="options"
          style="max-height: 250px;"
        ></base-chart-bar>

        <base-chart-line v-else-if="type==='Line'"
          :chartdata="data" 
          :options="options"
          style="max-height: 250px;"
        ></base-chart-line>  
      </template>
    </template>

		<slot
			slot="reveal-actions"
			name="reveal-actions"
		/>	

    <slot />

    <slot
      slot="actions"
      name="actions"
    />
  </base-material-card>
</template>

<script>
  export default {
    name: 'MaterialChartCard',
	
    inheritAttrs: false,

    props: {
      type: {
        type: String,
        required: true,
        validator: v => ['Bar', 'Line', 'Pie'].includes(v),
      },

      loaded: {
        type: Boolean,
        default: false
      },

      data: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    },
  }
</script>


