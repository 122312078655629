<template>
	<v-card :loading="formLoading" :disabled="formLoading"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<slot></slot>

		<template v-if="title">
			<v-card-title class="title font-weight-regular" :class="titleClasses">
				<span>{{ title }}</span>
				<slot name="titleContent"></slot>
			</v-card-title>
			<v-divider></v-divider>
		</template>

		<v-card-text :class="cardTextClasses">
			<v-container class="no-padding" fluid>
				<slot name="content"></slot>
			</v-container>
		</v-card-text>

		<template v-if="$slots.formActions">
			<v-divider></v-divider>
			<v-card-actions>
				<slot name="formActions"></slot>
			</v-card-actions>
		</template>
	</v-card>
</template>

<script>
export default {
	name:"BaseContainerForm",
	props:{
		title: String,
		formLoading: {type: Boolean, default: false},
		titleClasses: {type: String, default: ''},
		noPaddingContent: {type: Boolean, default: false}
	},

	computed: {
		cardTextClasses() {
			let classes=[];
			if (this.noPaddingContent) {
				classes.push('no-padding');
			} else {
				classes.push('card-text-padding');
			}
			return classes;
		}
	},
}
</script>

<style scoped>
	.card-text-padding {
    padding: 10px 15px 10px !important;
  }
</style>