<template>
	<v-dialog
		v-model="loading.status"
		fullscreen
		hide-overlay
		persistent
		width="300"
	>	
		<v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.30);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
				<v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: "BaseLoading",
	computed: {
    ...mapState(["loading"]),
  },
}
</script>

<style>

</style>