<template>
	<base-text-field class="centered-input" maxlength="16"
		v-bind="$attrs"
    v-on="$listeners"
		v-mask="mask"
		:placeholder="placeholder"
		:label="label"
		:rules="rules"
		:required="required"
		clearable
	></base-text-field>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'BaseCardNumberField',

	props: {
		required: {type: Boolean, default: false},
		masked: {type: Boolean, default: false},
	},

	data() {
		return {
			label: 'Número de Tarjeta',
			placeholder: '',
			cardNumberRules: {
				counter: value => (value != null && value.length <= 16) || 'No debe tener más de 16 caracteres.',
			},
		}
	},

	computed: {
		...mapState(["commonValRules"]),

		mask() {
			if (this.$attrs.disabled || this.$attrs.readonly) {
				return ;
			}

			if (this.masked) {
				this.placeholder = '0000********0000'
				return '####********####';
			} else {
				this.placeholder = '0000000000000000'
				return ;
			}
		},

		rules() {
			let result = [];
			if (this.required) {
				result.push(this.commonValRules.required);
			}
			if (this.masked) {
				result.push(this.commonValRules.maskedCardNumber);
			} else {
				result.push(this.commonValRules.cardNumber);
			}
			result.push(this.cardNumberRules.counter);
			return result;
		}
	},
}
</script>