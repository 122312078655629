<template>
	<base-form-container
		:title="title"
		titleClasses="grey lighten-3"
	>
		<template v-if="$slots.titleContent" v-slot:titleContent>
			<v-spacer></v-spacer>
			<slot name="titleContent"></slot>
		</template>

		<template v-slot:content>
			<v-form v-if="showForm" ref="DetailForm" onSubmit="return false;" class="px-4 pt-2">
				<v-row justify="center" dense>
					<v-col cols="12" sm="3" align="center">
						<base-select v-model="newDetail.color" :items="availableColors" label="Color" :rules="[commonValRules.required]" required></base-select>					
					</v-col>
					<v-col cols="8" sm="2" align="center">
						<base-number-field v-model="newDetail.stock" label="Stock" maxlength="6"
						prepend-inner-icon="mdi-cart-variant" :rules="[validationRules.stockRules.valid, validationRules.stockRules.counter]" required></base-number-field>
					</v-col>
					<v-col cols="4" sm="2" align="center" class="pt-3">
						<!-- <base-btn color="primary darken-1" @click="newItem" text elevation="1" icon="mdi-plus-thick" btnText="Agregar"></base-btn> -->
						<base-icon-btn fabMode size="25" btnColor="primary" color="white" @click="newItem" icon="mdi-plus-thick" tooltip="Agregar" ></base-icon-btn>
					</v-col>
				</v-row>
			</v-form>

			<v-row justify="center" dense>
				<v-col cols="12" sm="9" md="9">
					<base-data-table
						:headers="detailHeaders" 
						:items="details"
						:sortBy="showForm ? '' : 'id'"
						hideHeader
					>
						<template v-slot:item.options="{ item }">
							<base-icon-btn v-if="itemIsEditable(item)" color="red darken-1" @click="removeDetail(item)" icon="mdi-close" tooltip="Eliminar Detalle"></base-icon-btn>
							<v-switch v-else-if="mode==='Editable'"
								v-model="item.editStock"
								inset
								@change="enableDisableStock(item)"
							></v-switch>
						</template>

						<template v-slot:item.color="{ item }">
							<base-select v-if="itemIsEditable(item)" v-model="item.color" :items="colors" class="custom-max-width" :rules="[commonValRules.required, verifyColor(item)]" required></base-select>
						</template>

						<template v-if="mode != 'DistributeStock' && mode != 'ReduceStock' && mode != 'IncreaseStock'" v-slot:item.stock="{ item }">
							<base-number-field v-if="stockIsEditable(item)" v-model="item.stock" maxlength="6"
							prepend-inner-icon="mdi-cart-variant" :rules="[validationRules.stockRules.valid, validationRules.stockRules.counter]" required></base-number-field>

							<template v-else>
								<v-icon small>mdi-cart-variant</v-icon> {{ item.stock }}
							</template>
						</template>

						<template v-else v-slot:item.stock="{ item }">
							<base-number-field v-model="item.stock" maxlength="6"
							prepend-inner-icon="mdi-cart-arrow-right" :rules="[validationRules.quantityRules.valid, validationRules.quantityRules.counter, validDetailQuantity(item.stock, item.originalStock)]"></base-number-field>
						</template>

						<template v-slot:item.originalStock="{ item }">
							<v-icon small>mdi-cart-variant</v-icon> {{ item.originalStock }}
						</template>
					</base-data-table>
				</v-col>
			</v-row>
		</template>
	</base-form-container>
</template>
<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: 'BaseStockColorDetails',
	mixins: [MainExternalData],

	props: {
		title: {type: String, required: true},
		details: {type: Array, required: true},
		mode: {
			type: String,
			required: true,
			validator: v => ['NewData', 'Editable', 'ShowData', 'DistributeStock', 'ReduceStock', 'IncreaseStock', 'ShowStockMovement'].includes(v),
		}
	},

	data() {
		return {
			colors:[
				{text:'Rojo', value:'Rojo'},
				{text:'Rosado', value:'Rosado'},
				{text:'Purpura', value:'Purpura'},
				{text:'Verde', value:'Verde'},
				{text:'Azul', value:'Azul'},
				{text:'Amarillo', value:'Amarillo'}
			],

			newDetail: {
				color:'',
				stock:''
			},
			defaultNewDetail: {
				color:'',
				stock:''
			},

			validationRules: {
				stockRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) > 0) || 'No puede ser menor a 1.',
				},

				quantityRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) >= 0) || 'No puede ser vacío o menor a 0.',
				},
			},
		}
	},

	computed: {
		showForm() {
			return this.mode === 'NewData' || this.mode === 'Editable'
		},

		detailHeaders(){
			var headingsResult=[]
			if (this.mode === 'NewData' || this.mode === 'Editable') {
				headingsResult.push({ text: "Opciones", value: "options", sortable: false })
			}

			if (this.mode !== 'NewData') {
				headingsResult.push({ text: "Cod.", value: "id", sortable: false })
			}

			headingsResult.push(
				// { text: "Nro.", value: "id", sortable: false },
				{ text: "Color", value: "color", sortable: false },
			)

			if (this.mode === 'DistributeStock') {
				headingsResult.push(
					{ text: "Stock", value: "originalStock", sortable: false },
					{ text: "Cantidad", value: "stock", sortable: false })
			} else if (this.mode === 'ReduceStock' || this.mode === 'IncreaseStock') {
				headingsResult.push(
					{ text: "Stock", value: "originalStock", sortable: false },
					{ text: "Cantidad", value: "stock", sortable: false })
			} else if (this.mode === 'ShowStockMovement') {
					headingsResult.push({ text: "Cantidad", value: "stock", sortable: false })
			} else {
				headingsResult.push({ text: "Stock", value: "stock", sortable: false })
			}

			return headingsResult;
		},

		availableColors(){
			const colorsInUse = this.details.map(x => x.color);
			const result = this.colors.filter(x => !colorsInUse.some(c => c === x.value))
			return result
		},
	},

	methods: {
		validDetailQuantity(quantity, stock){
			if (this.mode === 'IncreaseStock') {
				return true;
			}
			
			if (quantity > stock) {
				return 'No puede ser mayor al stock.'
			}
			return true;
		},

		verifyColor(item){
			const index = this.details.indexOf(item);
			var cloneDetails = this.details.slice();
			cloneDetails.splice(index, 1);
			const colorsInUse = cloneDetails.map(x => x.color);
			if (colorsInUse.some(x => x === item.color)) {
				return 'Color repetido.'
			}
			return true;
		},

		itemIsEditable(item) {
			return (this.mode ==='NewData' || this.mode ==='Editable' ) && item.canEdit
		},

		stockIsEditable(item) {
			return (this.mode ==='NewData' || this.mode ==='Editable' ) && item.editStock
		},

		enableDisableStock(item) {
			if (!item.editStock) {
				item.stock=0
			}
		},

		newItem(){
			if (!this.$refs.DetailForm.validate()) {
				return;
			}
			this.details.push({ color: this.newDetail.color, stock: this.newDetail.stock, quantity: 0, canEdit: true, editStock: true})
			this.$refs.DetailForm.reset();
			this.newDetail = Object.assign({}, this.defaultNewDetail);
		},

		removeDetail(item){
			const index = this.details.indexOf(item);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},
	},
}
</script>