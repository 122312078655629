<template>
	<v-dialog
		v-model="pdfDialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on, attrs }">
			<base-btn 
				v-on="on"
				v-bind="attrs"
				color="secondary" 
				@click="generatePDF" 
				icon="mdi-printer" 
				btnText="Imprimir"
			></base-btn>
		</template>

		<v-card>
			<v-toolbar
				dark
				color="primary"
			>
				<v-btn
					icon
					dark
					@click="close"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title><b>Reporte:</b> {{ title }}</v-toolbar-title>
				
			</v-toolbar>

			<!-- <iframe id="output" frameborder="0"></iframe> -->
			<v-card-text>
				<!-- <iframe :src="pdfSrc" style="width: 100%; height: 90vh;" frameborder="0"></iframe> -->
					<object :data="pdfSrc" type="application/pdf" style="width: 100%; height: 90vh">
						<!-- <p>It appears you don't have PDF support in this web browser. <a :href="pdfSrc" id="download-link"> Click here to download the PDF</a>.</p> -->
						<a href="" id="download-link"></a>
						<embed :src="pdfSrc" type="application/pdf" />
					</object>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'

export default {
	name: 'PdfViewer',
	props: {
		title: { type: String, required: true },
		type: { type: String, required: true,
			validator: v => ['Data', 'Base64', 'File'].includes(v),
		},
		headers: { type: Array },
		rows: { type: Array },
		file: { type: String },
	},

	data() {
		return {
			//PDF
      pdfDialog: false,
      pdfSrc:''
		}
	},

	methods: {
    generatePDF(){
      var doc = new jsPDF('l','pt','letter')
			var totalPagesExp = '{total_pages_count_string}'
			const title = this.title

      doc.autoTable({
        head: this.headers,
				body: this.rows,
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(20)
          doc.setTextColor(40)
          // if (base64Img) {
          //   doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 10, 10)
          // }
          doc.text(title, data.settings.margin.left + 15, 35)

          // Footer
          var str = 'Pág. ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' de ' + totalPagesExp
          }
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
        margin: { top: 60 },
      })

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }

      // console.log(doc)
      // doc.save('Categorias.pdf')
      this.pdfDialog = true
      // console.log(document.getElementById("pdfOutput"))
      // document.getElementById("pdfOutput").src = doc.output('datauristring');

			
			doc.setProperties({ title: title, author: 'Global Desk', creator: 'Global Desk' });
			this.pdfSrc = doc.output('datauristring', {filename: title});
			// console.log(this.pdfSrc)
		
			
			//3
			// var a = document.getElementById("download-link");
			// console.log(a)
			// // var a = document.createElement("a");
			// // document.body.appendChild(a);
			// // a.style = "display: none";
			// var pdfUriString = doc.output('dataurl');
			// console.log(pdfUriString)
			// return
			// var fileURL = URL.createObjectURL(pdfUriString)
			// a.href = fileURL;
			// //a.download = title;
			// a.click();
			// URL.revokeObjectURL(fileURL);




			//2
			// var pdfUriString = doc.output('blob');
			// console.log(pdfUriString)
			// var fileURL = URL.createObjectURL(pdfUriString)
			// console.log(fileURL)
			// var split = fileURL.split('/')
			// console.log(split)
			// split[split.length-1] = "MYPDF.pdf"
			// console.log(split[split.length-1])

			// var result = split[0];
			// for (let i = 1; i < split.length; i++) {
			// 	const element = split[i]
			// 	if (i==1) {
			// 		result += '//'
			// 	} else if (i == split.length -1) {
			// 		result += element
			// 	}
			// 	else {
			// 		result += element + '/'
			// 	}
			// }
			// console.log(result)
			// window.open(result);
			// return;


			//1
			// var pdfString = doc.output('datauristring', {filename: title});
			// console.log(pdfString)

			// fetch(pdfString)
			// .then(res => res.blob())
			// .then(blob => {
			// 	try {
      //   var blobUrl = URL.createObjectURL(blob)
      //   // This line makes Chrome crash, we have to remove it there
      //   // But it works on Firefox
			// 	blobUrl+= '#filename=MY_CUSTOM_FILENAME.pdf'
			// 	console.log(blobUrl)

			// 	this.pdfSrc = blobUrl

      //   // var pdf= document.querySelector('.pdf')
      //   // var pobj = document.createElement('object')
      //   // pobj.className= "pdf_object"
      //   // pobj.setAttribute('data', blobUrl)
      //   // pobj.setAttribute('type', 'application/pdf')

      //   // pdf.appendChild(pobj)
      // } catch(e) {
      //   console.error(e)
      // }
			// })

      
      // return doc
		},
		
		close(){
			this.pdfDialog = false
			this.pdfSrc = ''
		}
	},
}
</script>