<template>
	<div>
		<base-sub-heading v-if="showHeader" :title="title" dense>
		</base-sub-heading>
		
		<div v-if="stockGroup.length > 1" class="text-end mb-3">
			<v-btn-toggle 
				dense
				rounded
			>
				<v-btn
					small
					class="px-0 mx-2"
					color="secondary"
					@click="prev()"
					:disabled="onboarding == 0"
				>
					<v-icon color="white">mdi-chevron-left</v-icon>
				</v-btn>

				<v-btn
					small
					class="px-0 mx-2"
					color="secondary"
					@click="next()"
					:disabled="onboarding == stockGroup.length - 1"
				>
					<v-icon color="white">mdi-chevron-right</v-icon>
				</v-btn>
			</v-btn-toggle>
		</div>

		<v-window show-arrows-on-hover v-model="onboarding">
			<v-window-item
				v-for="(detail, i) in stockGroup"
				:key="i"
			>
				<base-form-stock-details :ref="`StockForm${productId}-${detail.purchaseId}`" :stockFormId="stockFormId" :title="`Lote C.: ${detail.purchaseId}`" :details="detail.stockDetails" :mode="detailMode">
				</base-form-stock-details>
			</v-window-item>
		</v-window>
	</div>
</template>

<script>
export default {
	name: 'BaseStockDetailsPicker',

	props: {
		title: { type: String, default: '' },
		productId: { type: Number, requered: true },
		stockFormId: { type: Number, requered: true },
		stockGroup: { type: Array, default: [] },
		detailMode: { type: String, requered: true }
	},

	data() {
		return {
			onboarding: 0
		}
	},

	computed: {
		showHeader() {
			// if (this.stockGroup.length > 1) {
			// 	return true;
			// } else 
			if (!!this.title && this.title.length > 0) {
				return true;
			}
			return false;
		}
	},

	methods: {
		next() {
			this.onboarding = this.onboarding + 1 === this.stockGroup.length
				? 0
				: this.onboarding + 1
		},

		prev() {
			this.onboarding = this.onboarding - 1 < 0
				? this.stockGroup.length - 1
				: this.onboarding - 1
		},

		validateStockFormDetails() {
			for (let i = 0; i < this.stockGroup.length; i++) {
				const element = this.stockGroup[i];
				var form = this.$refs[`StockForm${this.productId}-${element.purchaseId}`];
				if (form === undefined || form.length <= 0) {
					continue;	
				}
				if (!form[0].validate()) {
					return false;
				}	
			}
			// this.onboarding = 0;
			return true;
		},
	},
}
</script>