import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    barImage: require("@/assets/sidebar.jpg"),
    defaultProductImage: require("@/assets/product/no_photo.jpg"),
    drawer: null,
    adminDrawer: null,
    showDialogToChangeBranch: null,
    loading: {
      title: '',
      status: false,
    },
    availableMenu: [],

    token: null,
    authData: null,
    // authData: {
    //   data: ''
    //   roles: [],
    // },
    sessionTimedOut: false,

    lockData: {
      maxIdleTime: 100, //10 segundos = 10000, 60000 = 1 minuto
      showDialog: false,
    },
    

    baseRequest: {
      companyId: 1, 
      userId: 1,
      person: ''
    },

    userDataLoaded: false,
    companyDataLoaded: false,

    userData: {
      identifier: '',
      fullName: '',
      email: '',
      profilePicture: '',
      passwordExpired: false,
      assignedBranchOffices: 0,
    },
    defaultUserData: {
      identifier: '',
      fullName: '',
      email: '',
      profilePicture: '',
      passwordExpired: false,
      assignedBranchOffices: 0,
    },

    companyData: {
      companyVisualName: '',
      branchOfficeId: '',
      branchOfficeVisualName: '',
      logo: '',
      productForms: [],
      stockForms: [],
      enableInvoice: false,
      servicePlan: '',
      subscribedSince: '',
      nextPaymentDate: '',
      branchOfficeLimit: 0,
      userLimit: 0,
      productLimit: 0
    },
    defaultCompanyData: {
      companyVisualName: '',
      branchOfficeId: '',
      branchOfficeVisualName: '',
      logo: '',
      productForms: [],
      stockForms: [],
      enableInvoice: false,
      servicePlan: '',
      subscribedSince: '',
      nextPaymentDate: '',
      branchOfficeLimit: 0,
      userLimit: 0,
      productLimit: 0
    },
    
    swalConfig: {
      errorTitle: 'Oops...',
      serviceNetworkErrorMessage: 'Lo sentimos, tenemos un error de conectividad. Revise su conexion a internet e intentelo nuevamente.', 
      serviceErrorMessage: 'Lo sentimos, ocurrió un error en la aplicación. Contactenos para solucionar este inconveniente.',
      successMessage: 'Solicitud realizada correctamente.',
      position: 'top-end',
      // position: 'bottom-end',
      timer: 2300,
    },
    commonValRules: {
      // required: value => (!!value) || 'Campo requerido.',
      required: value => {
        if (value === undefined || value === null) {
          return 'Campo requerido.'
        }
        const length = value.toString().trim().length
        if (length <= 0) {
          return 'Campo requerido.'
        }
        return true
        // return (!(value.toString())) || 'Campo requerido.'
       },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido.'  
        }
        return true;
      },
      maskedCardNumber: value => { 
        if (value) {
          const pattern = /^\d{4}\*{7,8}\d{4}$/;
          return pattern.test(value) || 'Tarjeta inválida.'
        }
        return true
      },
      cardNumber: value => { 
        if (value) {
          const pattern = /^\d{15,16}$/;
          return pattern.test(value) || 'Tarjeta inválida.'
        }
        return true
      }
    },
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },

    SET_AVAILABLE_MENU (state, payload) {
      state.availableMenu = payload
    },

    SET_ADMIN_DRAWER (state, payload) {
      state.adminDrawer = payload
    },

    SET_SHOW_DIALOG_TO_CHANGE_BRANCH (state, payload) {
      state.showDialogToChangeBranch = payload
    },

    SET_TOKEN(state, payload) { 
      state.token = payload;
    },

    SET_SESSION_TIMED_OUT(state, payload) { 
      state.sessionTimedOut = payload;
    },

    SET_AUTH_DATA(state, payload) {
      // state.authData = payload;
      if (payload) {
        const data = JSON.parse(payload.data);
        state.baseRequest.companyId = parseInt(data.company, 10);
        state.baseRequest.userId = parseInt(data.user, 10);
        state.baseRequest.person = data.person;
        state.companyData.branchOfficeId = data.branchOffice;

        state.authData = { roles: JSON.parse(payload.roles)};
      } else { 
        state.baseRequest.companyId = null;
        state.baseRequest.userId = null;
        state.baseRequest.person = null;
        state.userData = Object.assign({}, state.defaultUserData);
        state.companyData = Object.assign({}, state.defaultCompanyData);

        state.authData = null;
      }
    },

    SHOW_LOCK_DIALOG(state) { 
      state.lockData.showDialog = true
    },
    HIDE_LOCK_DIALOG(state) { 
      state.lockData.showDialog = false
    },

    SET_COMPANY_DATA(state, payload) {
      state.companyData.companyVisualName = payload.companyData.companyVisualName
      state.companyData.branchOfficeVisualName = payload.companyData.branchOfficeVisualName
      if (payload.companyData.logo) {
        state.companyData.logo = payload.companyData.logo
      } else { 
        state.companyData.logo = require("@/assets/company/default-logo.png")
      }
      state.companyData.productForms = payload.companyData.productForms
      state.companyData.stockForms = payload.companyData.stockForms
      state.companyData.enableInvoice = payload.companyData.enableInvoice
      state.companyData.servicePlan = payload.companyData.servicePlan
      state.companyData.subscribedSince = payload.companyData.subscribedSince
      state.companyData.nextPaymentDate = payload.companyData.nextPaymentDate
      state.companyData.branchOfficeLimit = payload.companyData.branchOfficeLimit
      state.companyData.userLimit = payload.companyData.userLimit
      state.companyData.productLimit = payload.companyData.productLimit

      state.companyDataLoaded = true;
    },

    SET_USER_DATA(state, payload) {
      state.userData = payload.userData
      if (!payload.userData.profilePicture) {
        state.userData.profilePicture = require("@/assets/profile/default-profile-picture.png")
      }
      state.userDataLoaded = true;
    },

    SHOW_LOADING(state) { 
      // state.loading.title = payload.title
      state.loading.status = true
    },
    HIDE_LOADING(state) { 
      // state.loading.title = ''
      state.loading.status = false
    },

    SET_PROFILE_PICTURE(state, payload) {
      if (payload) {
        state.userData.profilePicture = payload
      } else { 
        state.userData.profilePicture = require("@/assets/profile/default-profile-picture.png")
      }
    },

    SET_USER_FULLNAME(state, payload) {
      state.userData.fullName = payload
    },

    SET_COMPANY_LOGO(state, payload) {
      if (payload) {
        state.companyData.logo = payload
      } else { 
        state.companyData.logo = require("@/assets/company/default-logo.png")
      }
    },

    SET_COMPANY_VISUALNAME(state, payload) {
      state.companyData.companyVisualName = payload.companyVisualName
    },

    SET_COMPANY_LIMITS(state, payload) {
      if (state.companyData.branchOfficeLimit != payload.branchOfficeLimit) {
        state.companyData.branchOfficeLimit = payload.branchOfficeLimit
      }

      if (state.companyData.userLimit != payload.userLimit) {
        state.companyData.userLimit = payload.userLimit
      }
      
      if (state.companyData.productLimit != payload.productLimit) {
        state.companyData.productLimit = payload.productLimit
      }
    },
  },
  actions: {
    saveTemporaryToken({ commit }, payload) {
      commit('SET_TOKEN', payload);
      commit('SET_AUTH_DATA', decode(payload));
      localStorage.setItem('token', payload);
    },

    removeTemporaryToken({ commit }) { 
      commit('SET_TOKEN', null);
      commit('SET_AUTH_DATA', null);
      localStorage.removeItem('token');
    },

    saveToken({ commit }, payload) {
      commit('SET_TOKEN', payload);
      commit('SET_AUTH_DATA', decode(payload));
      localStorage.setItem('token', payload);

      // commit('HIDE_LOCK_DIALOG');

      router.push({ name: 'InitialData' });
    },

    renewToken({ commit }, payload) { 
      commit('SET_TOKEN', payload);
      commit('SET_AUTH_DATA', decode(payload));
      localStorage.setItem('token', payload);
    },

    autoLogin({ commit }) { 
      let token = localStorage.getItem('token');
      if (token) {
        commit('SET_TOKEN', token);
        commit('SET_AUTH_DATA', decode(token));
        
        // router.push({ name: 'InitialData' });
      }
    },

    logout({ commit }) { 
      commit('SET_TOKEN', null);
      commit('SET_AUTH_DATA', null);
      localStorage.removeItem('token');
      router.push({ name: 'Login' });
    },

    cleanToken({ commit }) { 
      commit('SET_TOKEN', null);
      commit('SET_AUTH_DATA', null);
      localStorage.removeItem('token');
    },

    lock({ commit }) {
      commit('SHOW_LOCK_DIALOG');
    }
  },
  getters: {
    activeSession: state => !!state.token && !!localStorage.getItem('token'),
    isAdmin: state => !!state.token && state.authData.roles.includes('Admin'),

    mainDataLoaded: state => state.userDataLoaded && state.companyDataLoaded
  },
  modules: {
  }
})
