<template>
	<v-data-table :class="classes"
		:headers="headers" 
		:items="items" 
		:search="search" 
		:sort-by="sortBy"
		hide-default-footer
		:items-per-page.sync="itemsPerPage.value"
		:page.sync="page"
		@page-count="numberOfPages = $event"

		hide-default-header
		:sort-desc="sortDesc"

		:expanded.sync="expanded"

		v-bind="$attrs"
		v-on="$listeners"
	>
		<template v-if="!hideHeader" v-slot:top>
			<v-toolbar v-if="$vuetify.breakpoint.mdAndUp" dark color="blue darken-1">
				<template v-if="!hideReloadButton">
					<base-btn color="secondary" @click="$emit('loadDataTable')" icon="mdi-reload" btnText="Actualizar"></base-btn>
					<v-divider class="mx-4" inset vertical></v-divider>
				</template>
				
				<template v-if="!hideSearchEngine">
					<base-text-field v-model="search" color="white"
							class="text-xs-center"
							clearable
							single-line
							hide-details 
							prepend-inner-icon="mdi-magnify" 
							label="Búsqueda"></base-text-field>
					<v-spacer></v-spacer>
				</template>

				<base-select color="white"
					v-bind:value="sortBy"
					v-on:change="$emit('change', $event)"
					single-line
					hide-details
					:items="keys"
					prepend-inner-icon="mdi-sort"
					label="Ordene por"
				></base-select>

				<v-btn-toggle
					v-model="sortDesc"
					dense
					rounded
					group
					mandatory
				>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" depressed color="blue"	:value="false">
								<v-icon>mdi-sort-ascending</v-icon>
							</v-btn>
						</template>
						<span>{{ ascTooltip }}</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" depressed color="blue" :value="true">
								<v-icon>mdi-sort-descending</v-icon>
							</v-btn>
						</template>
						<span>{{ descTooltip }}</span>
					</v-tooltip>
				</v-btn-toggle>

				<template v-if="!hideNewItemButton">
					<v-divider class="mx-4" inset vertical></v-divider>
					<base-btn color="primary" @click="$emit('newItem')" :icon="newItemButtonIcon" :btnText="newItemButtonText"></base-btn>
				</template>
			</v-toolbar>

			<v-card v-else dark color="blue darken-1" class="mb-2" elevation="10">
				<v-container class="no-padding px-4" fluid>
					<v-row align="center" justify="center" class="pb-1">
						<v-col v-if="!hideSearchEngine" cols="12" sm="6" >
							<base-text-field v-model="search" color="white"
							class="text-xs-center"
							clearable
							single-line
							hide-details 
							prepend-inner-icon="mdi-magnify" 
							label="Búsqueda"></base-text-field>
						</v-col>

						<v-col cols="12" sm="6" class="d-flex justify-center">
							<base-select color="white"
								v-bind:value="sortBy"
								v-on:change="$emit('change', $event)"
								single-line
								hide-details
								:items="keys"
								prepend-inner-icon="mdi-sort"
								label="Ordene por"
								class="pt-2 mr-1"
							></base-select>

							<v-btn-toggle
								v-model="sortDesc"
								dense
								rounded
								group
								mandatory
							>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" depressed color="blue"	:value="false">
											<v-icon>mdi-sort-ascending</v-icon>
										</v-btn>
									</template>
									<span>{{ ascTooltip }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" depressed color="blue" :value="true">
											<v-icon>mdi-sort-descending</v-icon>
										</v-btn>
									</template>
									<span>{{ descTooltip }}</span>
								</v-tooltip>
							</v-btn-toggle>
						</v-col>

						<v-col v-if="!hideReloadButton" cols="6" sm="6" align="center">
							<v-btn rounded color="secondary" @click="$emit('loadDataTable')">
								<v-icon left>mdi-reload</v-icon> Actualizar
							</v-btn>
						</v-col>

						<v-col v-if="!hideNewItemButton" cols="6" sm="6" align="center">
							<v-btn rounded color="primary" @click="$emit('newItem')">
								<v-icon left>{{newItemButtonIcon}}</v-icon> {{newItemButtonText}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>

			<v-dialog v-model="showDialog" persistent scrollable  max-width="800px">
				<slot name="content"></slot>
			</v-dialog>
		</template>

		<template v-if="$vuetify.breakpoint.smAndUp" v-slot:header="{ props: { headers } }">
			<thead>
				<tr :class="headerBackgroundColor">
					<th v-for="(h, i) in headers" :key="i" class="custom-data-table-header">
						<span>{{h.text}}</span>
					</th>
				</tr>
			</thead>
		</template>

		<template v-slot:group.header="{items, isOpen, toggle}">
			<slot :name="`group.header`" v-bind="{items, isOpen, toggle}">
			</slot>
		</template>

		<template v-for="h in headers.filter(x => x.value !== 'data-table-expand')" v-slot:[`item.${h.value}`]="{ item }">
			<slot :name="`item.${h.value}`" v-bind:item="item">
				{{item[h.value]}}
			</slot>
		</template>

		<template v-slot:expanded-item="{ headers, item }">
			<td class="py-4" :colspan="headers.length">
				<slot name="expanded-item" v-bind="{ item }">
				</slot>
			</td>
		</template>

		<template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
			<slot name="item-data-table-expand" v-bind="{ expand, isExpanded, item }">
			</slot>
		</template>
		
		<template v-if="showEnabledField" v-slot:item.enabled="{ item }">
			<template v-if="item.enabled">
				<span class="blue--text">Activo</span>
			</template>
			<template v-else>
				<span class="red--text">Inactivo</span>
			</template>
		</template>

		<template v-slot:no-data>
			<slot name="no-data">Datos no disponibles.</slot>
		</template>

		<template v-slot:no-results>
			<v-alert
				text
				elevation="2"
				class="text-center"
				color="red"
				transition="scale-transition"
			>
				<v-icon color="red" left>mdi-alert-circle</v-icon> <slot name="no-results">No se encontraron registros coincidentes con <strong>'{{search}}'</strong></slot>
			</v-alert>
		</template>

		<template v-if="!hideFooter" v-slot:footer>
			<v-divider class="my-0"></v-divider>
			<v-container fluid>
				<v-row align="center" justify="center" no-gutters>
					<v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''">
						<span v-if="$vuetify.breakpoint.smAndDown" class="grey--text pt-2">Filas</span>
						<span v-else class="grey--text">Filas por página</span>
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dark
									text
									color="grey"
									:class="$vuetify.breakpoint.smAndDown ? '' : 'ml-2'"
									v-bind="attrs"
									v-on="on"
								>
									{{ itemsPerPage.text }}
									<v-icon>mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-for="(number, index) in itemsPerPageArray"
									:key="index"
									@click="updateItemsPerPage(number)"
								>
									<v-list-item-title>{{ number.text }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>

					<v-col cols="12" sm="6" :align="$vuetify.breakpoint.smAndDown ? '' : 'end'" :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''">
						<span v-if="$vuetify.breakpoint.smAndDown" class="mr-4 pt-2 grey--text">
							Pág. {{ page }} de {{ numberOfPages }}
						</span>
						<span v-else class="mr-4 grey--text">
							Página {{ page }} de {{ numberOfPages }}
						</span>

						<v-btn
							fab
							text
							small 
							class="mr-1"
							@click="formerPage"
						>
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<v-btn
							fab
							text
							small
							class="ml-1"
							@click="nextPage"
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: 'BaseDataTable',
	
	model: {
    prop: 'sortBy',
    event: 'change'
	},
	
	props: {
		headers: Array,
		items: Array,
		sortBy: String,
		desc: {type: Boolean, default: false},
		newItemButtonText: {type: String, default: 'Nuevo'},
		newItemButtonIcon: {type: String, default: 'mdi-plus-thick'},
		hideReloadButton: {type: Boolean, default: false},
		hideSearchEngine: {type: Boolean, default: false},
		hideNewItemButton: {type: Boolean, default: false},
		showEnabledField: {type: Boolean, default: false},
		showDialog: {type: Boolean, default: false},
		hideHeader: {type: Boolean, default: false},
		hideFooter: {type: Boolean, default: false},
		elevation: {type: [String, Number], default: 1},
		headerBackgroundColor: {type: String, default: 'grey lighten-3'},

		showBorder: {type: Boolean, default: false}
	},

	data() {
		return {
			search: '',
			itemsPerPageArray: [
				{text: '5', value: 5},
				{text: '10', value: 10},
				{text: '15', value: 15},
				{text: '20', value: 20}
			],
			page: 1,
			itemsPerPage: {text: '10', value: 10},
			numberOfPages: 0,
			sortDesc: false,
			ascTooltip:'Orden Ascendente',
			descTooltip:'Orden Descendente',
			expanded: []
		}
	},

	computed: {
		classes () {
			let result = []
			result.push(`elevation-${this.elevation}`)
			if (this.showBorder) {
				result.push('data-table-border')
			}
			return result;
		},

		keys(){
			var result=[];
			this.headers.map(function(x){
        if (x.sortable === undefined || x.sortable === true) {
					result.push({text:x.text, value:x.value});
        }
			});

			function compare(a, b) {
				if (a.text < b.text)
					return -1;
				if (a.text > b.text)
					return 1;
				return 0;
			};
			result = result.sort(compare);
			return result;
		},
	},

	created() {
		this.sortDesc = this.desc;
	},

	methods: {
		nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
		},
		
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
		},
		
		updateItemsPerPage (number) {
			if (number.value === -1) this.page = 1
			this.itemsPerPage = Object.assign({}, number)
		},

		expandAll() {
			this.$data.expanded = this.items;
		},

		collapseAll() {
			this.$data.expanded = [];
		}
	},
}
</script>

<style scoped>
.data-table-border {
  border: 1px solid rgb(207, 207, 207);
}
</style>