<template>
	<v-container class="no-padding-y" fluid>
		<v-row justify="center">
			<v-col cols="6" sm="3" align="center">
				<base-text-field v-model="details.material" label="Material" counter maxlength="20" 
				placeholder="Ej. Madera" :rules="[commonValRules.required, validationRules.materialRules.counter]" clearable required></base-text-field>
			</v-col>
			<v-col cols="6" sm="3" align="center">
				<base-number-field v-model="details.high" label="Alto" counter maxlength="5"
				prefix="cm" :rules="[validationRules.dataRules.counter]" required></base-number-field>
			</v-col>
			<v-col cols="6" sm="3" align="center">
				<base-number-field v-model="details.width" label="Ancho" counter maxlength="5" 
				prefix="cm" :rules="[validationRules.dataRules.counter]" required></base-number-field>
			</v-col>
			<v-col cols="6" sm="3" align="center">
				<base-number-field v-model="details.depth" label="Profundidad" counter maxlength="5" 
				prefix="cm" :rules="[validationRules.dataRules.counter]" required></base-number-field>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
// Mixins
import DataTools from '@/mixins/data-tools'

export default {
	name: 'BaseProductFurnitureDetails',
	mixins: [DataTools],

	props: {
		details: {type: Object, required: true},
	},

	data() {
		return {
			validationRules: {
        materialRules: {
          counter: value => (value != null && value.length <= 20) || 'No debe tener más de 20 caracteres.',
        },
				dataRules: {
          counter: value => (value != null && value.length <= 5) || 'No debe tener más de 5 caracteres.',
        },
			}
		}
	},
}
</script>