<template>
	<v-card>
		<v-tabs :value="paymentTab" grow  icons-and-text @change="paymentTypeOnChange($event)" 
			background-color="secondary accent-4"
		>
			<v-tabs-slider></v-tabs-slider>
			<v-tab :disabled="disabledFields">
				Efectivo
				<v-icon large>mdi-cash</v-icon>
			</v-tab>
			<v-tab :disabled="disabledFields">
				Tarjeta
				<v-icon large>mdi-credit-card-outline</v-icon>
			</v-tab>
			<v-tab :disabled="disabledFields">
				Crédito
				<v-icon large>mdi-account-cash</v-icon>
			</v-tab>

			<v-tab-item>
				<v-card v-if="editedItem.paymentTypeId === 1" flat>
					<v-card-title class="justify-center pb-4"><h3>Pago en efectivo</h3></v-card-title>
					<v-card-text>
						<v-row align="center" justify="center" no-gutters>
							<v-col cols="6" xs="6" sm="4" align="center">
								<base-currency-field 
									label="Efectivo"
									v-bind:value="editedItem.amountPaid" v-on:input="amountPaidOnChange($event)"
									:rules="[validationRules.amountPaidRules.verify]" 
									required
									:disabled="disabledFields"
								></base-currency-field>
							</v-col>
							<v-col cols="6" xs="6" sm="4" align="center">
								<h3>
									<strong>Total</strong><p class="mt-2">Bs. {{totalPrice}}</p>
								</h3>
							</v-col>
							<v-col cols="6" xs="6" sm="4" align="center">
								<h3>
									<strong>Cambio</strong><p class="mt-2">Bs. {{cashChange}}</p>
								</h3>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card v-if="editedItem.paymentTypeId === 2" flat>
					<v-card-title class="justify-center pb-4"><h3>Pago con tarjeta</h3></v-card-title>
					<v-card-text>
						<v-row justify="center" no-gutters>
							<v-col cols="12" sm="8" align="center">
								<base-card-number-field v-bind:value="editedItem.cardNumber" v-on:input="cardNumberOnChange($event)"
								 required masked :disabled="disabledFields"></base-card-number-field>
							</v-col>

							<v-col cols="6" sm="4" align="center">
								<h3>
									<strong>Total</strong><p class="mt-2">Bs. {{totalPrice}}</p>
								</h3>
							</v-col>

							<v-expand-transition v-if="!disabledFields">
								<v-col v-if="totalPrice <= 0" cols="12" class="mt-2">
									<v-alert
										text
										elevation="2"	
										color="red"
									>
										<v-icon color="red" left>mdi-alert-circle</v-icon>
										<strong>El monto a pagar debe ser mayor a 0.</strong>
									</v-alert>
								</v-col>
							</v-expand-transition>
						</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card v-if="editedItem.paymentTypeId === 3" flat>
					<v-card-title class="justify-center pb-4"><h3>Pago a crédito</h3></v-card-title>
					<v-card-text>
						<v-row align="center" justify="center" no-gutters>
							<v-col v-if="!disabledFields" cols="6" align="center">
								<base-label text="*Tipo de pago">
									<v-radio-group class="mt-0 pt-0"
										v-bind:value="editedItem.creditPaymentTypeId"
										v-on:change="creditPaymentTypeOnChange($event)"
										mandatory
										row
									>
										<v-radio color="info"
											label="Efectivo"
											value="1"
										></v-radio>
										<v-radio color="info"
											label="Tarjeta"
											value="2"
										></v-radio>
									</v-radio-group>
								</base-label>
							</v-col>
							<v-col cols="6" align="center">
								<base-currency-field 
									label="A Cuenta"
									v-bind:value="editedItem.amountPaid" v-on:input="amountPaidOnChange($event)"
									:rules="[validationRules.creditRules.validAmount, validationRules.creditRules.creditCard, validationRules.creditRules.verify]" 
									required
									:disabled="disabledFields"
								></base-currency-field>
							</v-col>

							<v-col v-if="showNextPaymentDate" cols="6" align="center" class="mb-4">
								<base-date-picker-dialog label="Próximo Pago" 
									only-later-dates  
									v-bind:input="editedItem.nextPaymentDate" v-on:getValue="nextPaymentDateOnChange($event)"
									:rules="[commonValRules.required]" 
									required 
									:disabled="disabledFields"
								></base-date-picker-dialog>
							</v-col>
							<v-col v-if="!disabledFields" cols="6" align="center" class="mb-4">
								<h3>
									<strong>Saldo</strong><p class="mt-2 mb-0">Bs. {{ totalRemainingAmount}}</p>
								</h3>
							</v-col>

							<v-expand-transition v-if="!disabledFields">
								<v-col v-if="totalRemainingAmount <= 0" cols="12">
									<v-alert
										text
										elevation="2"	
										color="red"
									>
										<v-icon color="red" left>mdi-alert-circle</v-icon>
										<strong>El monto a pagar debe ser menor al saldo, caso contrario cambie el tipo de pago.</strong>
									</v-alert>
								</v-col>
							</v-expand-transition>

							<template v-if="disabledFields">
								<v-col v-if="status == 6" cols="12">
									<v-alert
										text
										elevation="2"	
										color="secondary"
									>
										<v-icon color="secondary" left>mdi-alert-circle</v-icon>
										<strong>Crédito Pendiente.</strong>
										<p class="mb-0 mt-2">El saldo podrá verlo en el historial de pagos del crédito.</p>
									</v-alert>
								</v-col>
								<v-col v-else-if="status == 7" cols="12">
									<v-alert
										text
										elevation="2"	
										color="success"
									>
										<v-icon color="success" left>mdi-check-circle</v-icon>
										<strong>Crédito Completado.</strong>
									</v-alert>
								</v-col>
							</template>

							<v-expand-transition>
								<v-col v-if="showCreditCardNumber" cols="12" sm="8" align="center">
									<base-card-number-field v-bind:value="editedItem.cardNumber" v-on:input="cardNumberOnChange($event)"
								 		required masked :disabled="disabledFields"
									></base-card-number-field>
								</v-col>
							</v-expand-transition>
						</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
		</v-tabs>
	</v-card>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: 'BaseFormPaymentType',
	mixins: [MainExternalData],

	model:{
		prop:'paymentDetails',
		event:'change'
	},

	props: {
		totalPrice: {type: [Number, String], required: true},
		paymentDetails: {type: Object, required: true},
		disabledFields: {type: Boolean, default: false},
		status: {type: Number}
	},

	data() {
		return {
			paymentTab: 0,
			editedItem: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
      },
      defaultItem: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
			},
			validationRules: {
				amountPaidRules: {
					verify: value => (parseFloat(value) >= parseFloat(this.totalPrice)) || 'Debe ser mayor o igual al precio total.',
				},

				creditRules: {
					verify: value => (parseFloat(value) >= 0) || 'Debe ser mayor o igual a 0.',
					creditCard: value => {
						if (!this.showCreditCardNumber) {
							return true;
						}
						if (parseFloat(value) <= 0) {
							return 'Debe ser mayor a 0.'
						}
						return true;
					},
					validAmount: value => (value != null && this.totalRemainingAmount > 0) || 'Debe ser menor al saldo.'
				},
			},
		}
	},

	watch: {
		paymentDetails:{
			deep: true,
			immediate: true,
			handler: 'setPaymentDetails'
		}
	},

	computed: {
		showCreditCardNumber() {
			if (this.disabledFields) {
				return false;
			}

			if (this.editedItem.creditPaymentTypeId == 2) {
				return true;
			}
			return false;
		},

		showNextPaymentDate() {
			if (!this.disabledFields) {
				return true;
			}

			if (this.totalRemainingAmount > 0) {
				return true;
			}
			return false;
		},

		totalRemainingAmount() {
			// const result = (this.totalPrice - this.editedItem.amountPaid).toFixed(2);
			let result = (this.totalPrice - this.editedItem.amountPaid).toFixed(2);
			if (result < 0) {
				result = '0.00';
			}
			return result;
		},

		cashChange() {
			let result = (this.editedItem.amountPaid - this.totalPrice).toFixed(2);
			if (result < 0) {
				result = '0.00';
			}
			return result;
		}
	},

	methods: {
		setPaymentDetails(val) {
			this.editedItem = val;
			this.paymentTab = this.editedItem.paymentTypeId - 1;
		},

		response() {
			this.$emit('change', this.editedItem)
		},

		paymentTypeOnChange(event){
			this.editedItem = Object.assign({}, this.defaultItem);
			this.editedItem.paymentTypeId = event + 1;
			if (this.editedItem.paymentTypeId == 2) {
				this.editedItem.amountPaid = this.totalPrice;
			}
			this.response();
		},

		amountPaidOnChange(event){
			if (this.editedItem.amountPaid != event) {
				this.editedItem.amountPaid = event;
				this.response();
			}

			// this.editedItem.amountPaid = event;
			// this.response();
		},

		cardNumberOnChange(event){
			if (this.editedItem.cardNumber != event) {
				this.editedItem.cardNumber = event;
				this.response();
			}

			// this.editedItem.cardNumber = event;
			// this.response();
		},

		nextPaymentDateOnChange(event){
			if (this.editedItem.nextPaymentDate != event) {
				this.editedItem.nextPaymentDate = event;
				this.response();
			}

			// this.editedItem.nextPaymentDate = event;
			// this.response();
		},

		creditPaymentTypeOnChange(event){
			if (this.editedItem.creditPaymentTypeId != event) {
				this.editedItem.cardNumber = '';
				this.editedItem.creditPaymentTypeId = event;
				this.response();
			}

			// this.editedItem.cardNumber = '';
			// this.editedItem.creditPaymentTypeId = event;
			// this.response();
		},

		reset() {
			this.paymentTab = 0;
			this.editedItem = Object.assign({}, this.defaultItem);
			this.response();
		}
	},
}
</script>