<template>
	<v-textarea color="info"
		v-bind="$attrs"
    v-on="$listeners"
		:label="customLabel"
		:required="required"
	></v-textarea>
</template>

<script>
export default {
	name: 'BaseTextarea',

	props: {
		label: {type: String },
		required: {type: Boolean, default: false},
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		},
	},
}
</script>