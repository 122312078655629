import { Line } from 'vue-chartjs'

export default {
  name: 'BaseChartLine',
  extends: Line,

  inheritAttrs: false,

  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.options.animation = {
      onComplete: function(animation) {
        var firstSet = animation.chart.config.data.datasets[0].data
        var dataSum = 0
        if (typeof firstSet === "object" && firstSet.length > 0) {
          dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue);  
        }
        
        if (typeof firstSet !== "object" || dataSum === 0) {
          let ctx = animation.chart.ctx;
          let width = animation.chart.width;
          let height = animation.chart.height;
          ctx.save();
          ctx.fillStyle = '#9E9E9E';
          ctx.font = '15px "Roboto", sans-serif'
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText('Datos no disponibles.', width / 2, height / 2);
          ctx.restore();
        }
      }
    }

    this.renderChart(this.chartdata, this.options)
  }
}


