<template>
	<base-form-simple ref="DistributeStockForm" :formTitle="formTitle"
		:formLoading="formLoading"
		:hideSaveButton="stockSum<=0"
		@cancelButton="close"
		@saveButton="save"
	>
		<template v-slot:formContent>
			<v-container class="no-padding" fluid>
				<v-row dense>
					<v-col cols="12" sm="5" md="5">
						<base-text-field v-model="customCode" label="Código" readonly></base-text-field>
					</v-col>
					<v-col cols="12" sm="7" md="7">
						<base-text-field v-model="visualName" label="Nombre" readonly></base-text-field>
					</v-col>
				</v-row>
				<v-divider></v-divider>
				<v-row justify="center">
					<v-col cols="8" sm="4" md="4">
						<v-scale-transition v-model="editBranchOffice" mode="out-in">
							<base-select v-if="editBranchOffice" v-model="editedItem.from" :items="branchOfficesStock" label="Sucursal Origen" @change="getStock"
							:rules="[commonValRules.required]" required></base-select>
							<base-text-field v-else v-model="branchOfficeSelected" label="Sucursal Origen" disabled>
							</base-text-field>
						</v-scale-transition>
					</v-col>
					<v-col cols="4" sm="2" md="2">
						<base-text-field class="centered-input" type="number" v-model.number="stockAvailable" label="Disponible" readonly></base-text-field>
					</v-col>

					<template v-if="mode == 'Distribute'">
						<v-col cols="8" sm="4" md="4">
							<base-select v-model="editedItem.to" :items="filteredBranchOfficesAvailable" label="Sucursal Destino" 
							:rules="[commonValRules.required, validationRules.targetBranchOfficeRules.equals]" required></base-select>
						</v-col>
						
						<v-col cols="4" sm="2" md="2">
							<base-text-field class="centered-input" type="number" v-model.number="stockSum" label="Stock" 
							:rules="[commonValRules.required, validationRules.stockAssignedRules.number, validationRules.stockAssignedRules.valid]" required readonly></base-text-field>
						</v-col>
					</template>
					<template v-if="mode == updateMode">
						<v-col v-if="editedStockDetails.show" cols="6" sm="2" md="2">
							<base-text-field class="centered-input" type="number" v-model="stockAvailableSum" label="Stock Habilitado" readonly></base-text-field>
						</v-col>
						<v-col v-if="editedStockDetails.show" cols="6" sm="2" md="2">
							<base-text-field class="centered-input" type="number" v-model.number="stockSum" label="Stock Dañado" 
							:rules="[commonValRules.required, validationRules.stockAssignedRules.number, validationRules.stockAssignedRules.valid]" required readonly></base-text-field>
						</v-col>
					</template>

					<v-col cols="12" sm="6">
						<v-scale-transition v-model="editBranchOffice" mode="out-in">
							<base-btn v-if="editBranchOffice" key="Disable" block dark color="green darken-1" @click="getStockDetails()" icon="mdi-list-status" btnText="Ver Detalles de Stock" :disabled="!editedItem.from"></base-btn>
							<base-btn v-else key="Enable" block dark color="secondary" @click="enableOriginBranchOffice()" icon="mdi-format-list-checks" btnText="Cambiar sucursal origen"></base-btn>
						</v-scale-transition>
					</v-col>
				</v-row>

				<!-- Distribucion por compras -->
				<template v-if="editedStockDetails.show">
					<p align="center" class="my-3">Tiene <b>{{ detailsCount > 1 ? `${detailsCount} lotes` : `${detailsCount} lote`}}</b> de stock en la sucursal de origen <b>{{branchOfficeSelected}}</b>.</p>
					
					<v-expand-transition>
						<v-alert v-if="editedStockDetails.show"
							text
							elevation="2"	
							color="info"
							class="text-justify my-6"
						>
							<p><v-icon color="info" left>mdi-information</v-icon><b>¡IMPORTANTE!</b></p>
							<template v-if="mode == 'Distribute'">
								<p>En este apartado usted podrá realizar la <b>transferencia de stock entre sucursales</b>, indicando la <b>cantidad de traspaso</b> de los distintos lotes de compra según vea conveniente.</p>
								Para que se efectuen los cambios de la transferencia de stock, debe presionar el botón <b>guardar.</b>
							</template>
							<template v-else-if="mode == 'Update'">
								<p>En este apartado usted podrá <b>habilitar o deshabilitar</b> los lotes que serán visibles para las ventas, presionando los botones que se encuentran en el encabezado de cada lote: <v-icon color="green darken-1">mdi-check-bold</v-icon> para <b>habilitar</b> y <v-icon color="red darken-1">mdi-close-thick</v-icon> para <b>deshabilitar</b>.</p>
								<p>Le recomendamos realizar esta acción en un horario donde no esten realizando ventas.</p>
								<p>Con respecto a los detalles del stock usted podrá realizar el <b>registro de stock dañado</b> de la sucursal seleccionada, indicando la <b>cantidad de stock</b> de los distintos lotes de compra según vea conveniente.</p>
								Para que se efectuen los cambios del registro de stock dañado, debe presionar el botón <b>guardar.</b>
							</template>
						</v-alert>
					</v-expand-transition>

					<base-sub-heading title="Detalles" dense>
						<v-btn-toggle v-if="detailsCount > 1"
							dense
							rounded
						>
							<v-btn
								small
								class="px-0 mx-2"
								text
								@click="prev"
							>
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>

							<v-btn
								small
								class="px-0 mx-2"
								text
								@click="next"
							>
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</v-btn-toggle>
					</base-sub-heading>

					<v-window show-arrows-on-hover v-model="onboarding">
						<v-window-item
							v-for="(detail, i) in editedStockDetails.detailsAvailable"
							:key="i"
						>
							<v-row v-if="mode == 'Update'" justify="center" no-gutters>
								<v-col v-if="detail.unitPriceExceeded" cols="12">
									<v-alert
										text
										elevation="2"	
										color="red"
										class="text-justify"
									>
										<p><v-icon color="red" left>mdi-alert-circle</v-icon><b>¡IMPORTANTE!</b></p>
										El precio de venta del producto es menor o igual al costo unitario del lote de compra, considere aumentar el precio de venta para evitar futuras perdidas en sus utilidades.
									</v-alert>
								</v-col>
							</v-row>

							<base-form-stock-details :ref="`StockForm${detail.purchaseId}`" :stockFormId="editedStockDetails.stockFormId" :title="`Lote: ${detail.purchaseId}`" :details="detail.stockDetails" :mode="detailMode">
								<template v-if="mode == updateMode" v-slot:titleContent>
									<template v-if="detail.enabled">
										<base-icon-btn color="red darken-1" @click="enableDisableItem(detail, 2)" icon="mdi-close-thick" tooltip="Deshabilitar"></base-icon-btn>
									</template>
									<template v-else>
										<base-icon-btn color="green darken-1" @click="enableDisableItem(detail, 1)" icon="mdi-check-bold" tooltip="Habilitar"></base-icon-btn>
									</template>
								</template>
							</base-form-stock-details>
						</v-window-item>
					</v-window>
				</template>
				
			</v-container>
		</template>
	</base-form-simple>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: 'BaseFormStockDistribution',
	mixins: [MainExternalData],

	props: {
		productId: {type: [Number, String], required: true},
		customCode: {type: String, required: true},
		visualName: {type: String, required: true},
		returnInventoryItem: {type: Boolean, default: false},
		mode: {
			type: String,
			required: true,
			validator: v => ['Distribute', 'Update', ''].includes(v),
		},
	},

	data() {
		return {
			updateMode: 'Update',
			editBranchOffice: true,
			formLoading: false,
			editedItem: {
        from:'',
				to: '',
				stockAvailable:0,
				stockAssigned: 0
      },
      defaultItem: {
        from:'',
				to: '',
				stockAvailable:0,
				stockAssigned: 0
			},
			branchOfficesStock: [],
			branchOfficesAvailable: [],

			editedStockDetails: {
				show: false,
				// salePricePerUnit: '',
				stockFormId: 0,
				detailsAvailable: [],
				enabled: false
			},
			defaultStockDetails: {
				show: false,
				// salePricePerUnit: '',
				stockFormId: 0,
				detailsAvailable: [],
				enabled: false
			},

			onboarding: 0,
			
			validationRules: {
				targetBranchOfficeRules: {
					equals: value => (value != this.editedItem.from) || 'Las sucursales no pueden ser iguales.',
				},
				stockAssignedRules: {
					number: value => (value > 0) || 'Debe ser mayor a 0.',
					valid: value => (this.editedItem.stockAvailable >= value ) || 'No debe ser mayor al disponible.',
				},
			},
		}
	},

	computed: {
		detailMode() {
			switch (this.mode) {
				case 'Distribute':
					return 'DistributeStock';
				case 'Update':
					return 'ReduceStock'
				default:
					return ''
			}
		},

		formTitle() {
			switch (this.mode) {
				case 'Distribute':
					return 'Distribución de stock';
				case 'Update':
					return 'Actualización de stock'
				default:
					return ''
			}
		},

		branchOfficeSelected(){
			if (this.editedItem.from == '') {
				return ''
			}
			return this.branchOfficesStock.find(x => x.value === this.editedItem.from).text
		},

		filteredBranchOfficesAvailable() {
			this.editedItem.to = '';
			if (this.editedItem.from == '') {
				return []
			}
			return this.branchOfficesAvailable.filter(f => f.value != this.editedItem.from);
		},

		detailsCount() {
			return this.editedStockDetails.detailsAvailable.length;
		},

		stockAvailable(){
			return this.editedItem.stockAvailable - this.editedItem.stockAssigned
		},

		stockSum(){
			let total = 0;
			for (let i = 0; i < this.editedStockDetails.detailsAvailable.length; i++) {
				const element = this.editedStockDetails.detailsAvailable[i]
				const quantityArray = element.stockDetails.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
				if (quantityArray.length > 0) {
					const sum = quantityArray.reduce((a,b) => a+b)
					total += sum	
				}
			}
			this.editedItem.stockAssigned = total;
			return total;
		},

		stockAvailableSum(){
			let total = 0;
			for (let i = 0; i < this.editedStockDetails.detailsAvailable.length; i++) {
				const element = this.editedStockDetails.detailsAvailable[i]
				if (element.enabled == true) {
					const originalStockArray = element.stockDetails.filter(f => f.originalStock !== null && f.originalStock).map(x => parseInt(x.originalStock))
					if (originalStockArray.length > 0) {
						const sum = originalStockArray.reduce((a,b) => a+b)
						total += sum
					}
					const quantityArray = element.stockDetails.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
					if (quantityArray.length > 0) {
						const reduce = quantityArray.reduce((a,b) => a+b)
						total -= reduce;	
					}	
				}
			}

			// total -= this.stockSum; 
			return total;
		}
	},

	created() {
		this.initialize()
	},

	methods: {
		next () {
			this.onboarding = this.onboarding + 1 === this.editedStockDetails.detailsAvailable.length
				? 0
				: this.onboarding + 1
		},
		prev () {
			this.onboarding = this.onboarding - 1 < 0
				? this.editedStockDetails.detailsAvailable.length - 1
				: this.onboarding - 1
		},

		getStock(){
			this.editedItem.stockAvailable = this.branchOfficesStock.find(x => x.value == this.editedItem.from).stock;
			this.editedItem.stockAssigned = 0;
		},

		async initialize(){
			let me=this;
      try {
        me.SHOW_LOADING()
				if (me.productId === undefined || me.productId <= 0) {
					return;
				}

				if (!await me.listOfBranchOfficesStock()) {
					return;
				}

				if (!await me.listOfBranchOffices()) {
					return;
				}
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async listOfBranchOfficesStock(){
      let me=this;
			var result = false;
			var branchOfficesArray=[];
			let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'productId': me.productId,
      };
			await me.getObjectResponse('api/CompanyInventory/GetBranchOfficeStock', request).then(data => {
				if (data === undefined) {
					return result;
				}
				branchOfficesArray = data.branchOffices;
				branchOfficesArray.map(function(x){
					me.branchOfficesStock.push({text:x.visualName, value:x.id, stock:x.stock});
				});
				result = true;
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
			return result;
		},

		async listOfBranchOffices(){
      let me=this;
			var result = false;
			var branchOfficesArray=[];
			await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
				if (data === undefined) {
					return result;
				}
				branchOfficesArray = data.branchOffices;
				branchOfficesArray.map(function(x){
					me.branchOfficesAvailable.push({text:x.visualName, value:x.id});
				});
				result = true;
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
      return result;
		},

		close(inventoryItem){
			this.$refs.DistributeStockForm.reset();
			this.editedItem = Object.assign({}, this.defaultItem);
			this.editedStockDetails = Object.assign({}, this.defaultStockDetails);
			this.editBranchOffice = true;
			this.onboarding = 0;
			this.$emit('close', inventoryItem);
		},

		getUrl(action) {
			let url = '';
			switch (action) {
				case 1://getStockDetails
					url = 'api/ProductInventory/GetStockDetailsAvailable'
					if (this.mode == this.updateMode) {
						url = 'api/ProductInventory/GetAllStockDetailsAvailable';
					}
					break;
				case 2://save
					url = 'api/ProductInventory/StockTransfer'
					if (this.mode == this.updateMode) {
						url = 'api/ProductInventory/DamagedInventory';
					}
					break;
				default:
					break;
			}
			return url;
		},

		async getStockDetails(){
			let me=this;
			try {
        me.SHOW_LOADING()
      	me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.from,
					'productId': parseInt(me.productId, 10)
				};
				let url = me.getUrl(1);
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedStockDetails.show = true;
					me.editedStockDetails.stockFormId = data.stockFormId;
					me.editedStockDetails.detailsAvailable = me.parseStockDetails(data.stockDetailsAvailable, data.stockFormId, false);
					me.editBranchOffice = false;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		parseStockDetails(details, stockFormId, serialize){
			var result = []
			let cloneDetails = details.slice();
			if (serialize) {
				cloneDetails.map((x) => {
					let stockDetailsAvailable = x.stockDetails.filter(f => f.stock !== null && f.stock && parseInt(f.stock) > 0).map(x => x);
					if (stockDetailsAvailable.length <= 0) {
						return;
					}
					stockDetailsAvailable = stockDetailsAvailable.map((x) => {
						const res  = Object.fromEntries(Object.entries(x).filter(e=>e[0]!=='originalStock'));
						return res;
					});
					let stockValues = stockDetailsAvailable.map(x => parseInt(x.stock)).reduce((a,b) => a+b);
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:null
						});
					} else {
						let stockDetails = JSON.stringify(stockDetailsAvailable);
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:stockDetails
						});
					}
				});
			} else {
				cloneDetails.map((x) => {
					let stockDetails = []
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						stockDetails.push({
							id:1,
							stock:x.stock
						});
					} else {
						stockDetails = JSON.parse(x.stockDetails);
					}

					for (let i = 0; i < stockDetails.length; i++) {
						const element = stockDetails[i];
						element.originalStock = element.stock
						element.stock = 0
					}

					if (this.mode == 'Distribute') {
						result.push({ 
							purchaseId:x.purchaseId,
							stock:x.stock,
							stockDetails:stockDetails
						});		
					} else if (this.mode == this.updateMode) {
						result.push({ 
							purchaseId:x.purchaseId,
							stock:x.stock,
							stockDetails:stockDetails,
							enabled:x.enabled,
							unitPriceExceeded:x.unitPriceExceeded
						});
					}
				});
			}
			return result;
		},

		enableOriginBranchOffice(){
			this.editedStockDetails = Object.assign({}, this.defaultStockDetails);
			this.editBranchOffice = true;
			this.onboarding = 0;
		},

		validateAllStockFormDetails(){
			for (let i = 0; i < this.editedStockDetails.detailsAvailable.length; i++) {
				const element = this.editedStockDetails.detailsAvailable[i];
				const formName = `StockForm${element.purchaseId}`;
				var form = this.$refs[formName];
				if (form) {
					if (!form[0].validate()) {
						return false;
					}
				}
			}
			return true;
		},

		async save(){
			let me=this;

			if (!me.validateAllStockFormDetails()) {
				return;
			}
			
			try {
        me.SHOW_LOADING()
      	me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.from,
					'productId': parseInt(me.productId, 10),
					'returnInventoryItem': me.returnInventoryItem
				};

				if (me.mode == 'Distribute') {
					let distributeRequest = {
						'destinationbranchOfficeId': me.editedItem.to,
						'transfers': me.parseStockDetails(me.editedStockDetails.detailsAvailable, me.editedStockDetails.stockFormId, true),
					}
					request = Object.assign({}, request, distributeRequest);
				} else if (me.mode == 'Update') {
					let damagedRequest = {
						'damagedStocks': me.parseStockDetails(me.editedStockDetails.detailsAvailable, me.editedStockDetails.stockFormId, true),
					}
					request = Object.assign({}, request, damagedRequest);
				}

				let url = me.getUrl(2);
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						return;
					}
					me.close(data.inventory);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `el lote <strong>${item.purchaseId}</strong>, esta acción afectara a las ventas en proceso. Te aconsejamos que realices esta acción cuando no esten realizando ventas.`)) {
				return;
			}

			if (action==1) {
				await me.enable(item);
			}
			else if (action==2) {
				await me.disable(item);
			}
    },

		async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'branchOfficeId': me.editedItem.from,
					'productId': parseInt(me.productId, 10),
					'purchaseId': item.purchaseId,
        };
        await me.getBaseResponse('api/ProductInventory/EnableInventory', request).then(data => {
          if (data) {
            item.enabled = true
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'branchOfficeId': me.editedItem.from,
					'productId': parseInt(me.productId, 10),
					'purchaseId': item.purchaseId,
        };
        await me.getBaseResponse('api/ProductInventory/DisableInventory', request).then(data => {
          if (data) {
            item.enabled = false
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },
	},
}
</script>