<template>
	<div>
		<v-dialog v-model="editedEdModal.show" max-width="290px">
			<v-card>
				<v-card-title>
					<span class="headline" v-if="title">{{title}}</span>
				</v-card-title>

				<v-card-text v-if="text">{{text}}</v-card-text>

				<v-card-actions v-if="showActions">
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="enableDisableClose">Cancelar</v-btn>
					<v-btn v-if="editedEdModal.action==1" color="orange darken-4" text @click="enable">Activar</v-btn>
					<v-btn v-if="editedEdModal.action==2" color="orange darken-4" text @click="disable">Desactivar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	name: 'BaseAlert',
	// props:{
	// 	settings
	// }
}
</script>