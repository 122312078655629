<template>
	<base-icon-btn v-if="$vuetify.breakpoint.xs && enableSmallBtn"
		v-bind="$attrs"
		v-on="$listeners"
		size="25"
		:icon="icon"
		fabMode
		color="white"
		:btnColor="$attrs.color"
		:tooltip="btnText"
	>
	</base-icon-btn>

	<v-btn v-else rounded
		v-bind="$attrs"
		v-on="$listeners"
	>
		<v-icon size="25" left>{{ icon }}</v-icon> {{ btnText }}
	</v-btn>
</template>

<script>
export default {
	name: 'BaseBtn',
	props: {
		btnText: { type: String, required: true },
		icon: { type: String, required: true },
		enableSmallBtn: { type: Boolean, default: false },
	}
}
</script>