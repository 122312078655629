<template>
	<v-card v-bind="$attrs" v-on="$listeners">
		<template v-if="title">
			<v-card-title class="title font-weight-regular justify-space-between grey lighten-3">
				<span >{{ title }}</span>
			</v-card-title>
			<v-divider></v-divider>
		</template>
		<v-card-text class="no-padding">
			<v-container class="no-padding" fluid>
			<v-row no-gutters>

				<template v-if="$vuetify.breakpoint.smAndUp">
					<v-col cols="12" sm="6" md="6">
						<v-list two-line rounded subheader class="no-padding">
							<v-subheader class="title font-weight-regular">
								<span>{{ firstTitle }}</span>
							</v-subheader>
							<v-divider></v-divider>

							<draggable class="list-group" :list="firstList" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" >
								<transition-group type="transition" :name="'flip-list'">
									<template v-if="firstList.length === 0">
										<v-list-item :key="-1">
											{{emptyList}}
										</v-list-item>
									</template>
									<template v-else>
										<v-list-item
											v-for="fItem in firstList"
											:key="fItem.id"
											class="v-list-item-move"
										>
											<v-list-item-avatar>
												<v-icon v-if="fItem.icon" large v-text="fItem.icon"></v-icon>
												<v-icon v-else large v-text="firstIcon"></v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title v-text="fItem.visualName"></v-list-item-title>
												<v-list-item-subtitle class="text-wrap" v-text="fItem.description"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</template>
								</transition-group>
							</draggable>
						</v-list>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-list two-line rounded subheader>
							<v-subheader class="title font-weight-regular">
								<span>{{ secondTitle }}</span>
							</v-subheader>
							<v-divider></v-divider>

							<draggable class="list-group" :list="secondList" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" >
								<transition-group type="transition" :name="'flip-list'">
									<template v-if="secondList.length === 0">
										<v-list-item :key="-1">
											{{emptyList}}
										</v-list-item>
									</template>
									<template v-else>
										<v-list-item
											v-for="sItem in secondList"
											:key="sItem.id"
											class="v-list-item-move"
										>
											<v-list-item-avatar>
												<v-icon v-if="sItem.icon" large v-text="sItem.icon"></v-icon>
												<v-icon v-else large v-text="secondIcon"></v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title v-text="sItem.visualName"></v-list-item-title>
												<v-list-item-subtitle class="text-wrap" v-text="sItem.description"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</template>
								</transition-group>
							</draggable>
						</v-list>
					</v-col>
				</template>

				<template v-else>
					<v-col cols="12">
						<v-list two-line rounded subheader>
							<v-list-item
								v-for="jItem in joinedLists"
								:key="jItem.id"
								class="no-padding"
							>
								<v-list-item-avatar>
									<v-icon v-if="jItem.assigned" large v-text="secondIcon"></v-icon>
									<v-icon v-else large v-text="firstIcon"></v-icon>
								</v-list-item-avatar>

								<v-list-item-content class="justify-space-between">
									<v-list-item-title v-text="jItem.visualName"></v-list-item-title>
									<v-list-item-subtitle v-text="jItem.description"></v-list-item-subtitle>
								</v-list-item-content>

								<v-switch v-model="jItem.assigned" inset @change="changeStatus(jItem)" ></v-switch>
							</v-list-item>
						</v-list>
					</v-col>
				</template>

			</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
import draggable from 'vuedraggable'

export default {
	name:"BaseDraggable",

	components: {
    draggable,
  },

	props:{
		pair: {
      type: Array,
      required: true,
    },
		title: String,
		group: String,
		firstTitle: {type: String, default: 'Disponibles'},
		firstIcon: {type: String, default: 'mdi-lock'},
		//firstList: Array,
		//{id:0, visualName:'', description:'', icon:'', assigned:false}		
		secondTitle: {type: String, default: 'Asignados'},
		secondIcon: {type: String, default: 'mdi-check-outline'},
		//secondList: Array,
		//{id:0, visualName:'', description:'', icon:'', assigned:false}		
		
	},

	data() {
		return {
			activeNames: ['1'],
			isDragging: true,
			emptyList: '¡Arrastre un ítem aquí!',
			firstList:[],
			secondList:[],
		}
	},

	watch: {
		pair:{
			deep: true,
			immediate: true,
			handler: 'setList'
		}
	},

	computed: {
		dragOptions() {
      return {
        animation: 0,
        group: this.group,
        disabled: false,
				ghostClass: "ghost",
      };
		},
		
		joinedLists(){
      var joinedList=[];
			var assigned = this.secondList.map(x => x.id);
			var mergedArray = this.firstList.concat(this.secondList);
      mergedArray.map(function(x){
        if (assigned.includes(x.id)) {
          x.assigned = true;
        }
        else{
          x.assigned = false;
        }
        joinedList.push(x);
			});

			function compare(a, b) {
				if (a.id < b.id)
					return -1;
				if (a.id > b.id)
					return 1;
				return 0;
			};
			joinedList = joinedList.sort(compare);
			return joinedList;
    },
	},

	methods: {
		onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      
      if (!draggedElement) {
        return false
			}
			
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
		},

		setList(){
			this.firstList = this.pair[0];
			this.secondList = this.pair[1];
		},

		onChange(){
			const result = Object.assign({}, {firstList:this.firstList, secondList:this.secondList});
			this.$emit('onChange', this.firstList, this.secondList)
		},
		
		changeStatus(jItem){
      if (jItem.assigned) {
        var index = this.firstList.indexOf(jItem)
        this.firstList.splice(index, 1)
        this.secondList.push(jItem)
      }
      else {
        var index = this.secondList.indexOf(jItem)
        this.secondList.splice(index, 1)
        this.firstList.push(jItem)
			}
    },
	},
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 50px;
  height: 300px;
  overflow-y: auto;
}

.v-list-item-move {
  cursor: move;
}
</style>