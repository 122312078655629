<template>
	<div>
		<!-- <div class="d-flex flex-wrap justify-space-between align-center mb-2"> -->
		<div class="justify-space-between text-center mb-2">
			<h3 :class="headerClasses">{{title}}</h3>
			<slot></slot>
		</div>
		<v-responsive
			:class="dividerClasses"
			class="base-divider"
			:max-width="maxWidth"
			v-bind="$attrs"
			v-on="$listeners"
		>
			<v-divider />
		</v-responsive>
	</div>
</template>

<script>
export default {
	name: 'BaseSubHeading',
	props: {
		title: {
			type: String,
			default: 'Título'
		},
		color: {
			type: String,
			default: 'primary',
		},
		dense: Boolean,
		space: {
			type: [Number, String],
			default: 4,
		},
		maxWidth: {
			type: [Number, String],
			default: '100%'
		},
		vertical: {
			type: Boolean,
			default: false
		}
	},
		
	computed: {
		headerClasses () {
			const classes = [
				// 'display-1',

				// 'text-center',
				// 'justify-center',
				// 'text-capitalize',
				// 'font-weight-bold',
				'font-weight-regular',
				// 'mb-1',

				// `text-start`,
				//this.theme.isDark && 'white--text',
			]

			return classes
		},
		dividerClasses () {
			return [
				this.color,
				`mb-${this.space}`,
				this.dense && 'base-divider--dense',
			]
		},
		dividerMargin () {
			switch (this.align) {
				case 'left': return 'mr-auto'
				case 'right': return 'ml-auto'
				default: return 'mx-auto'
			}
		},
	},
}
</script>

<style lang="sass">
  .base-divider .v-divider
    border-width: 3px 0 0 0 !important

  .base-divider.base-divider--dense .v-divider
    border-width: 2px 0 0 0 !important
</style>