<template>
	<v-card>
		<v-card-text>

			<!-- <template v-slot:activator="{ on, attrs }"> -->
					<!-- v-on="on"
						v-bind="attrs" -->
					<base-btn 
					
						color="secondary" 
						@click="generatePDF()" 
						icon="mdi-printer" 
						btnText="Imprimir"
					></base-btn>
				<!-- </template> -->

			<base-pdf-viewer :title="type" :src="pdfSrc" :show="showViewer" @close="close">
			</base-pdf-viewer>

<!-- style="display:none;" -->
			<div id="voucher">
				<header>
						<div id="logo">
								<img :src="companyData.logo" id="image">
								<div id="voheaders">
										<b id="companyname" class="uppercase">{{companyData.companyVisualName}}</b><br>
										<b id="branchname" class="uppercase">Casa Matriz</b><br>
										José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
								</div>
						</div>

						<div id="branchdata">
								<div id="vouchertype">{{ type==='Venta' ? 'Recibo' : voucherType}}</div>
								<p id="voheaders">
										<b id="branchname" class="uppercase">{{companyData.branchOfficeVisualName}}</b><br>
										José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
								</p>
						</div>

						<div v-if="type==='Venta'" id="saledata" class="uppercase">
								<p>Original - Cliente</p>
								Venta Nº: {{ number }}<br>
						</div>
						<div v-else id="saledata" class="uppercase">
								Compra Nº: {{ number }}<br>
								Código Nº: {{ number }}<br>
								Comprobante N°: {{}}<br>
								Autorización N°: {{}}<br>
						</div>
				</header>
				<br>
				<section style="padding-top: 20px;">
						<div>
								<table id="vocustomer">
										<tbody>
												<tr>
														<td id="customer">
																<p style="margin-bottom: 0;"><strong class="uppercase">Lugar y Fecha:</strong> La Paz, 14 de enero de 2021</p>
																<span class="uppercase"><strong>Señor(a):</strong> {{ customer }}</span><br>
														</td>
												</tr>
										</tbody>
								</table>
						</div>
				</section>
				<br>
				<section>
						<div id="details">
								<table id="detable">
										<thead>
												<tr id="detheader">
														<th style="width: 120px;">CODIGO</th>
														<th>DESCRIPCION</th>
														<th style="width: 80px;">CANT</th>
														<th style="width: 100px;">PRECIO UNIT</th>
														<th style="width: 80px;">DESC.</th>
														<th style="width: 100px;">TOTAL</th>
												</tr>
										</thead>
										<tbody>
												<tr v-for="(prod, index) in products" :key="index">
														<td>{{prod.customCode}}</td>
														<td>{{prod.product}}</td>
														<td style="text-align:center;">{{prod.quantity}}</td>
														<td style="text-align:right;">{{prod.unitPrice}}</td>
														<td style="text-align:right;">{{((prod.unitPrice * prod.quantity) * (prod.discount / 100)).toFixed(2)}}</td>
														<td style="text-align:right;">{{prod.totalPrice}}</td>
												</tr>
										</tbody>
								</table>
						</div>
				</section>
				<hr>
				<section>
						<div>
								<table id="totaltable">
										<tr>
												<td style="vertical-align: top;">
														<b>SON:</b> DIECISEIS 80/100 BOLIVIANOS<br>
														<b>FORMA DE PAGO:</b> CONTADO<br>
												</td>
												<td style="text-align:right; vertical-align: top;">
														SUB TOTAL Bs.: {{subtotal}}<br>
														DESCUENTO ({{discount}}%) Bs.: {{totalDiscount}}<br>
														<b>TOTAL Bs.: {{totalPrice}}</b><br><br>
														EFECTIVO Bs.: {{cash}}<br>
														CAMBIO Bs.: {{(cash - totalPrice).toFixed(2)}}<br>
												</td>
										</tr>
								</table>
						</div>
				</section>
				<br>
				<footer>
						<div id="gracias">
								<b>Gracias por su compra!</b><br>
								<b>Emitido por el sistema GAVCOM de Global Desk</b>
						</div>
				</footer>
			</div>
		</v-card-text>
	</v-card>

<!-- <div>
  <div id="voucher">
		<header>
        <div id="logo">
            <img :src="companyData.logo" id="image">
            <div id="voheaders">
                <b id="companyname" class="uppercase">{{companyData.companyVisualName}}</b><br>
                <b id="branchname" class="uppercase">Casa Matriz</b><br>
                José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
            </div>
        </div>

        <div id="branchdata">
            <div id="vouchertype">{{ type==='Venta' ? 'Recibo' : voucherType}}</div>
            <p id="voheaders">
                <b id="branchname" class="uppercase">{{companyData.branchOfficeVisualName}}</b><br>
                José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
            </p>
        </div>

        <div v-if="type==='Venta'" id="saledata" class="uppercase">
            <p>Original - Cliente</p>
            Venta Nº: {{ number }}<br>
        </div>
				<div v-else id="saledata" class="uppercase">
            Compra Nº: {{ number }}<br>
						Código Nº: {{ number }}<br>
						Comprobante N°: {{}}<br>
						Autorización N°: {{}}<br>
        </div>
    </header>
		<br>
    <section style="padding-top: 20px;">
        <div>
            <table id="vocustomer">
                <tbody>
                    <tr>
                        <td id="customer">
                            <p style="margin-bottom: 0;"><strong class="uppercase">Lugar y Fecha:</strong> La Paz, 14 de enero de 2021</p>
                            <span class="uppercase"><strong>Señor(a):</strong> {{ customer }}</span><br>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    <br>
		<section>
        <div id="details">
            <table id="detable">
                <thead>
                    <tr id="detheader">
                        <th style="width: 120px;">CODIGO</th>
                        <th>DESCRIPCION</th>
                        <th style="width: 80px;">CANT</th>
                        <th style="width: 100px;">PRECIO UNIT</th>
                        <th style="width: 80px;">DESC.</th>
                        <th style="width: 100px;">TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(prod, index) in products" :key="index">
                        <td>{{prod.customCode}}</td>
                        <td>{{prod.product}}</td>
                        <td style="text-align:center;">{{prod.quantity}}</td>
                        <td style="text-align:right;">{{prod.unitPrice}}</td>
                        <td style="text-align:right;">{{((prod.unitPrice * prod.quantity) * (prod.discount / 100)).toFixed(2)}}</td>
                        <td style="text-align:right;">{{prod.totalPrice}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    <hr>
		<section>
        <div>
            <table id="totaltable">
                <tr>
                    <td style="vertical-align: top;">
                        <b>SON:</b> DIECISEIS 80/100 BOLIVIANOS<br>
                        <b>FORMA DE PAGO:</b> CONTADO<br>
                    </td>
                    <td style="text-align:right; vertical-align: top;">
                        SUB TOTAL Bs.: {{subtotal}}<br>
                        DESCUENTO ({{discount}}%) Bs.: {{totalDiscount}}<br>
                        <b>TOTAL Bs.: {{totalPrice}}</b><br><br>
                        EFECTIVO Bs.: {{cash}}<br>
                        CAMBIO Bs.: {{(cash - totalPrice).toFixed(2)}}<br>
                    </td>
                </tr>
            </table>
        </div>
    </section>
    <br>
    <footer>
        <div id="gracias">
            <b>Gracias por su compra!</b><br>
            <b>Emitido por el sistema GAVCOM de Global Desk</b>
        </div>
    </footer>
  </div>
	</div> -->
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
	name: "BasePdfVoucher",
	mixins: [MainExternalData],
	props: {
		type: { type: String, required: true,
			validator: v => ['Compra', 'Venta'].includes(v),
		},
		voucherType: { type: String, required: true},
		number: { type: String, required: true },
		date: { type: String, required: true },

		customer: { type: String, required: true },
		products: { type: Array, required: true },

		subtotal: { type: String, required: true },
		discount: { type: [String, Number], required: true },
		totalDiscount: { type: String, required: true },
		totalPrice: { type: String, required: true },
		cash: { type: String, required: true },
	},

	data() {
		return {
			showViewer: false,
      pdfSrc:''
		}
	},

	methods: {
		generatePDF() {
			var quotes = document.getElementById('voucher');
			// console.log(quotes)
			const fileName = `Comprobante-${this.number}`;
			html2canvas(quotes).then(function (canvas) {
				let pdfResult = '';

				var doc = new jsPDF('p', 'pt', 'letter');

				var imgData = canvas.toDataURL('image/png');

				var pageSize = doc.internal.pageSize
				var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
					
				var imgWidth = pageSize.getWidth() - 10;
				// var pageHeight = 295;
				var imgHeight = canvas.height * imgWidth / canvas.width;
				var heightLeft = imgHeight;
				// var doc = new jsPDF('l', 'px', 'letter');
				var position = 0;

				doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				doc.setProperties({ title: fileName, author: 'Global Desk', creator: 'Global Desk' });
				doc.save(fileName+'.pdf');
				// pdfResult = doc.output('datauristring');
				// console.log(pdfResult)

				// var split = pdfResult.split(';')
				// console.log(split)
				// // console.log(split[0] +';'+split[2])
				// console.log(split[2].split('base64,'))
				// return pdfResult;
			})

			// this.showViewer = true
		},

		close() {
			this.pdfSrc = ''
			this.showViewer = false
		}
	},
};
</script>

<style>
    #voucher {
				padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    #logo {
        float: left;
        text-align: center;
        margin-left: 2%;
        margin-right: 2%;
        margin-bottom: 1%;
    }

    #image {
        width: 100px;
    }

    #companyname {
        font-size: 14px;
    }

    #branchdata {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #vouchertype {
        padding: 30px;
        font-family: Arial, sans-serif;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    #branchname {
        font-size: 12px;
    }

    #saledata {
        float: left;
        padding: 20px;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;

        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    #voheaders {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
    }



    section {
        clear: left;
    }

    .uppercase {
        text-transform: uppercase;
    }

    #customer {
        text-align: left;
    }

    #vocustomer {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 12px;
    }


    #detheader {
        color: #000000;
        margin-top: 10px;
    }

    #details {
        min-height: 700px;
    }

    #detable {
        font-size: 12px;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #detable thead {
        padding: 20px;
        background: #F1F1F1;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #detable thead tr th {
        padding: 5 0 5 0;
        border: 1px solid #000000;
    }

    #totaltable {
        width: 100%;
        font-size: 12px;
    }

    #gracias {
        text-align: center;
    }
</style>