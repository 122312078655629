import { mapState, mapMutations, mapGetters } from 'vuex';
import axios from 'axios';

export default {
	name: 'MainExternalData',

	data() {
		return {
			loadingTitle: 'Obteniendo información...',
		}
	},

	computed: {
		...mapState(["baseRequest", "companyData", "swalConfig", "commonValRules", "token", "userData", "defaultProductImage"]),
		...mapGetters(['activeSession'])
  },

	methods: {
		...mapMutations(['SHOW_LOADING', 'HIDE_LOADING']),

		async getObjectResponse(endpoint, request) {
			let me = this;
			if (!me.activeSession) {
				me.$router.push({ name: 'Login' });
				return;
			}
			let baseResponse;
			try {

				let header = { 'Authorization': 'Bearer ' + me.token };
				let configuration = { headers: header };
				// me.SHOW_LOADING({ title: me.loadingTitle })
				await axios.post(endpoint, request, configuration).then(function (response) {
					baseResponse = response.data;
				}).catch(function (error) {
					if (!error.response) {
						console.log(error)
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceNetworkErrorMessage, 'error');
					}
					else { 
						if (error.response.status === 401) {
							//Acceso no autorizado
							me.$store.dispatch('logout')
						}
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceErrorMessage, 'error');
					}
				});

				if (!baseResponse) {
					return;
				}

				if (!baseResponse.success) {
					switch (baseResponse.code) {
						case 244://InvalidToken
							// if (endpoint == 'api/Account/GetUserInfo' || endpoint == 'api/Account/GetCompanyInfo') {
							// 	me.$store.dispatch('logout')		
							// } else {
							// 	me.$store.dispatch('lock')
							// }
							//Mostrar el dialog de bloqueo
							// me.$store.dispatch('lock')
							me.$store.dispatch('logout')
							return;
						case 245://SessionExpired
							// if (endpoint == 'api/Account/GetUserInfo' || endpoint == 'api/Account/GetCompanyInfo') {
							// 	me.$store.dispatch('logout')		
							// } else {
							// 	me.$store.dispatch('lock')
							// }
							//Mostrar el dialog de bloqueo
							//me.$store.dispatch('lock')
							me.$store.dispatch('logout')
							return;
						case 12://ListOfValidationErrors
							var errorsResult = JSON.parse(baseResponse.message);
							return {code: baseResponse.code, message: errorsResult};
						default:
							break;
					}
					me.$swal(me.swalConfig.errorTitle, baseResponse.message, 'error');
					return;
				}
				else if (baseResponse.code === 246) {//RenewToken
					me.$store.dispatch('renewToken', baseResponse.message)
					return me.getObjectResponse(endpoint, request);
				}
				else if (baseResponse.code === 247) {//TokenRenewed
					return me.getObjectResponse(endpoint, request);
				}	
				else {
					return baseResponse.dataObject;
				}

			} catch (error) {
				console.log(error);
			}
			finally { 
				// me.HIDE_LOADING()
			}
		},

		async getBaseResponse(endpoint, request) {
			let me = this;
			if (!me.activeSession) {
				me.$router.push({ name: 'Login' });
				return false;
			}
			let baseResponse;
			try {
				let header = { 'Authorization': 'Bearer ' + me.token };
				let configuration = { headers: header };
				// me.SHOW_LOADING({ title: me.loadingTitle })
				await axios.post(endpoint, request, configuration).then(function (response) {
					baseResponse = response.data;
				}).catch(function (error) {
					if (!error.response) {
						console.log(error)
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceNetworkErrorMessage, 'error');
					}
					else { 
						if (error.response.status === 401) {
							//Acceso no autorizado
							me.$store.dispatch('logout')
						}
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceErrorMessage, 'error');
					}
				});

				if (!baseResponse) {
					return false;
				}

				if (!baseResponse.success) {
					switch (baseResponse.code) {
						case 244://InvalidToken
							// if (endpoint == 'api/Account/GetUserInfo' || endpoint == 'api/Account/GetCompanyInfo') {
							// 	me.$store.dispatch('logout')		
							// } else {
							// 	me.$store.dispatch('lock')
							// }
							//Mostrar el dialog de bloqueo
							// me.$store.dispatch('lock')
							me.$store.dispatch('logout')
							return false;
						case 245://SessionExpired
							// if (endpoint == 'api/Account/GetUserInfo' || endpoint == 'api/Account/GetCompanyInfo') {
							// 	me.$store.dispatch('logout')		
							// } else {
							// 	me.$store.dispatch('lock')
							// }
							//Mostrar el dialog de bloqueo
							//me.$store.dispatch('lock')
							me.$store.dispatch('logout')
							return false;
						default:
							break;
					}
					me.$swal(me.swalConfig.errorTitle, baseResponse.message, 'error');
				}
				else if (baseResponse.code === 246) {//RenewToken
					me.$store.dispatch('renewToken', baseResponse.message)
					return me.getBaseResponse(endpoint, request);
				}
				else if (baseResponse.code === 247) {//TokenRenewed
					return me.getBaseResponse(endpoint, request);
				}	
				else {
					// me.$swal({ toast: true, position: me.swalConfig.position, icon: 'success', title: me.swalConfig.successMessage, timer: me.swalConfig.timer, showConfirmButton: false });
				}
				return baseResponse.success;

			} catch (error) {
				console.log(error);
			}
			finally { 
				// me.HIDE_LOADING()
			}
		},

		async getObjectResponseWithoutHeaders(endpoint, request) {
			let me = this;
			let baseResponse;
			try {
				// me.SHOW_LOADING({ title: me.loadingTitle })
				await axios.post(endpoint, request).then(function (response) {
					baseResponse = response.data;
				}).catch(function (error) {
					if (!error.response) {
						console.log(error)
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceNetworkErrorMessage, 'error');
					}
					else { 
						if (error.response.status === 401) {
							//Acceso no autorizado
							me.$store.dispatch('logout')
						}
						me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceErrorMessage, 'error');
					}
				});

				if (!baseResponse) {
					return;
				}

				if (!baseResponse.success) {
					me.$swal(me.swalConfig.errorTitle, baseResponse.message, 'error');
					return;
				}
				else {
					return baseResponse.dataObject;
				}

			} catch (error) {
				console.log(error);
			}
			finally { 
				// me.HIDE_LOADING()
			}
		},

		async getBaseResponseWithoutHeaders(endpoint, request, getAllResponse = false) {
			let me = this;
			let baseResponse = {
				success: false,
				code: -1,
				message: ''
			};
			try {
				// me.SHOW_LOADING({ title: me.loadingTitle })
				await axios.post(endpoint, request).then(function (response) {
					baseResponse = response.data;
				}).catch(function (error) {
					if (!error.response) {
						console.log(error)
						if (getAllResponse) {
							baseResponse.message = me.swalConfig.serviceNetworkErrorMessage
						} else {
							me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceNetworkErrorMessage, 'error');
						}
					}
					else { 
						if (error.response.status === 401) {
							//Acceso no autorizado
							me.$store.dispatch('logout')
						}

						if (getAllResponse) {
							baseResponse.message = me.swalConfig.serviceErrorMessage
						} else {
							me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceErrorMessage, 'error');
						}
					}
				});
				
				if (!baseResponse) {
					return false;
				}

				if (getAllResponse) {
					return baseResponse;
				}

				if (!baseResponse.success) {
					me.$swal(me.swalConfig.errorTitle, baseResponse.message, 'error');
				}
				else {
					// me.$swal({ toast: true, position: me.swalConfig.position, icon: 'success', title: me.swalConfig.successMessage, timer: me.swalConfig.timer, showConfirmButton: false });
				}
				return baseResponse.success;

			} catch (error) {
				console.log(error);
			}
			finally { 
				// me.HIDE_LOADING()
			}
		},

		async showEnableDisableConfirmDialog(action, supplementaryText = 'el ítem'){
			let me = this;
			let actionMessage = '';
			if (action==1) {
        actionMessage = 'habilitar';
      }
      else if (action==2) {
        actionMessage = 'deshabilitar';
			}
			
			return await me.showConfirmationDialog(`Estás a punto de <strong>${actionMessage}</strong> ${supplementaryText}.`, `Si, ${actionMessage}!`);
		},

		async showConfirmationDialog(text, confirmButtonText) {
			let me = this;
			return await me.$swal({
				title: '¿Estás seguro?',
				html: text,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: confirmButtonText,
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
				
				// confirmButtonColor: '#40BD38',
				// cancelButtonColor: '#ff5252'
				// buttonsStyling: false,
			}).then((result) => {
				return result.value;
			});
		},

		catchError(error) { 
			let me=this;
			console.log(error)
      me.$swal(me.swalConfig.errorTitle, me.swalConfig.serviceErrorMessage, 'error');
		},

		showSuccessModal() {
			let me=this;
			me.$swal({ toast: true, position: me.swalConfig.position, icon: 'success', title: me.swalConfig.successMessage, timer: me.swalConfig.timer, showConfirmButton: false });
		},


		async getParameterValuesResponse(parameterId) {
			// console.log('getParameterValuesResponse', parameterId)
			let me = this;
			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'ids': [parameterId],
			};
			// console.log('getParameterValuesResponse', request)
			try {
				//Obtener la version del parametro
				var parameterVersionResponse = await me.getObjectResponse('api/Parameter/GetVersionsByIdList', request);
				// console.log('parameterVersionResponse', parameterVersionResponse)
				if (parameterVersionResponse == undefined) {
					return [];
				}
				let parameterVersion = parameterVersionResponse.parameters[0];
				// console.log('parameterVersion', parameterVersion)
				if (parameterVersion.version == -1) {
					//Eliminamos el parametro IDB
					await me.$IDB.deleteParameter(parameterId);
					return [];
				}
				//Verificar si tenemos el parametro en el IDB
				var idbParameter = await me.$IDB.getParameter(parameterId);
				// console.log('idbParameter', idbParameter)
				if (idbParameter != undefined || idbParameter != null) {
					//SI: Verificar que la version es la misma
					// console.log('version compare', [idbParameter.version, parameterVersion.version])
					if (idbParameter.version == parameterVersion.version) {
						//Retornar el objeto del IDB
						return idbParameter.values;
					}
				}
				//NO: Obtenemos el parametro del servidor
				var parameterValuesResponse = await me.getObjectResponse('api/Parameter/GetValuesByIdList', request);
				// console.log('parameterValuesResponse', parameterValuesResponse)
				if (parameterValuesResponse == undefined) {
					return [];
				}
				let parameterValues = parameterValuesResponse.parameters[0];
				// console.log('parameterValues', parameterValues)
				//Guardamos el parametro en el IDB
				await me.$IDB.addOrUpdateParameter(parameterValues);
				//Retornar el parametro
				return parameterValues.values;
			} catch (error) {
				console.log(error);
				return [];
			}
		},

		async getListOfParameterValuesResponse(parameters) {
			// console.log('1-getListOfParameterValuesResponse', parameters)
			let me = this;
			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'ids': parameters,
			};
			// console.log('2-getListOfParameterValuesResponse', request)
			let parametersResponse = [];
			parameters.forEach(id => {
				parametersResponse.push({ id: id, values: [] });
			});
			// console.log('3-getListOfParameterValuesResponse', parametersResponse)
			function setParameterValues(id, values) {
				// console.log('setParameterValues', [id, values])
				parametersResponse.find(x => x.id == id).values = values;
				// console.log('newParametersResponse', parametersResponse)
			}
			try {
				//Obtener la version de los parametros
				var parameterVersionResponse = await me.getObjectResponseWithoutHeaders('api/Parameter/GetVersionsByIdList', request);
				// console.log('1-try-GetVersionsByIdList', parameterVersionResponse)
				if (parameterVersionResponse == undefined) {
					return parametersResponse;
				}
				let parametersFound = parameterVersionResponse.parameters;
				let getParameterValues = [];
				for (let i = 0; i < parametersFound.length; i++) {
					const item = parametersFound[i];
					// console.log('2-try-parameterVersion', item)
					if (item.version == -1) {
						//Eliminamos el parametro IDB
						// console.log('idb-delete', item.id)
						await me.$IDB.deleteParameter(item.id);
						continue;
					}
					//Verificar si tenemos el parametro en el IDB
					var idbParameter = await me.$IDB.getParameter(item.id);
					// console.log('idbParameter', idbParameter)
					if (idbParameter != undefined || idbParameter != null) {
						//SI: Verificar que la version es la misma
						// console.log('version compare', [idbParameter.version, item.version])
						if (idbParameter.version == item.version) {
							//Actualizamos los valores con los del IDB
							setParameterValues(item.id, idbParameter.values);
							continue;
						}
					}
					//Agregamos el Id del parametro para obtener los valores del servidor
					// console.log('getParameterValues', item.id)
					getParameterValues.push(item.id);
				}
				//NO: Tenemos parametros para obtener valores del servidor
				if (getParameterValues.length <= 0) {
					// console.log('3-try-parametersResponse', parametersResponse)
					return parametersResponse;
				}
				//SI: Tenemos parametros para obtener valores del servidor
				request.ids = getParameterValues;
				// console.log('4-try-request', request)
				var parameterValuesResponse = await me.getObjectResponseWithoutHeaders('api/Parameter/GetValuesByIdList', request);
				// console.log('5-try-parameterValuesResponse', parameterValuesResponse)
				if (parameterValuesResponse == undefined) {
					return parametersResponse;
				}
				let parameterValues = parameterValuesResponse.parameters;
				for (let i = 0; i < parameterValues.length; i++) {
					const item = parameterValues[i];
					// console.log('6-try-parameterValues', item)
					//Guardamos el parametro en el IDB
					await me.$IDB.addOrUpdateParameter(item);
					//Actualizamos los valores con los obtenidos
					setParameterValues(item.id, item.values);
				}
			} catch (error) {
				console.log(error);
			}
			// console.log('4-getListOfParameterValuesResponse', parametersResponse)
			return parametersResponse;
		},
		
		onlyNumbers($event) {
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
 
			// only allow numbers
			if ((keyCode < 48 || keyCode > 57)) {
			 $event.preventDefault();
			}
		},

		// onlyForCurrency($event, price) {
		// 	let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
		// 	// only allow number and one dot
		// 	if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || price.indexOf('.') != -1)) { // 46 is dot
		// 		$event.preventDefault();
		// 	}
 
		// 	// restrict to 2 decimal places
		// 	if (price != null && price.indexOf(".") > -1 && (price.split('.')[1].length > 1)) {
		// 		$event.preventDefault();
		// 	}
		// },

		getNameOfMonth(value, fullname = true) { 
			var name=''
			switch (value) {
				case 1:
					name = fullname ? 'Enero' : 'Ene'
					break;
				case 2:
					name = fullname ? 'Febrero' : 'Feb'
					break;
				case 3:
					name = fullname ? 'Marzo' : 'Mar'
					break;
				case 4:
					name = fullname ? 'Abril' : 'Abr'
					break;
				case 5:
					name = fullname ? 'Mayo' : 'May'
					break;
				case 6:
					name = fullname ? 'Junio' : 'Jun'
					break;
				case 7:
					name = fullname ? 'Julio' : 'Jul'
					break;
				case 8:
					name = fullname ? 'Agosto' : 'Ago'
					break;
				case 9:
					name = fullname ? 'Septiembre' : 'Sep'
					break;
				case 10:
					name = fullname ? 'Octubre' : 'Oct'
					break;
				case 11:
					name = fullname ? 'Noviembre' : 'Nov'
					break;
				case 12:
					name = fullname ? 'Diciembre' : 'Dic'
						break;
				default:
					break;
			}
			return name;
		},

		getNameOfDay(value, fullname = true) { 
			var name=''
			switch (value) {
				case 1:
					name = fullname ? 'Lunes' : 'Lu'
					break;
				case 2:
					name = fullname ? 'Martes' : 'Ma'
					break;
				case 3:
					name = fullname ? 'Miércoles' : 'Mi'
					break;
				case 4:
					name = fullname ? 'Jueves' : 'Ju'
					break;
				case 5:
					name = fullname ? 'Viernes' : 'Vi'
					break;
				case 6:
					name = fullname ? 'Sábado' : 'Sa'
					break;
				case 7:
					name = fullname ? 'Domingo' : 'Do'
					break;
				default:
					break;
			}
			return name;
		},
	},
}