<template>
	<v-dialog fullscreen hide-overlay v-model="lockData.showDialog" persistent scrollable>
		<v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.90);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
					<v-card shaped
						class="mx-auto"
						max-width="500"
						:loading="formLoading" :disabled="formLoading"
					>
						<v-card-title class="pl-1 pt-1">
							<v-card flat color="transparent">
								<v-img
									width="180"
									:src="require('@/assets/logo_globaldesk.svg')"
								></v-img>
							</v-card>
						</v-card-title>

						<v-card class="px-md-4 pt-6" flat align="center">
							<v-card flat color="transparent">
								<v-img
									width="150"
									:src="require('@/assets/logo_gavcom_150_90.png')"
								></v-img>
							</v-card>
						</v-card>

						<v-form ref="LoginForm">
							<v-card-text>
								<h3 class="title font-weight-light text-center mb-4">
									¿Deseas continuar en el sistema?
								</h3>
								<v-row no-gutters justify="center">
									<v-col cols="12" sm="10" md="8">
										<base-text-field 
											v-model="editedItem.password"
											label="Contraseña"
											prepend-icon="mdi-lock"
											:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPwd ? 'text' : 'password'"
											maxlength="25"
											@click:append="showPwd = !showPwd"
											:rules="[commonValRules.required]"
											required
											@keypress.enter="login()"
										></base-text-field>
									</v-col>
								</v-row>
							</v-card-text>
						</v-form>

						<v-card-actions>
							<v-btn rounded
								color="secondary"
								depressed
								@click="$router.push({ name: 'Logout' })"
								:loading="formLoading"
							>
								<v-icon size="25" left>mdi-logout</v-icon>
								Salir
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn rounded
								color="primary"
								depressed
								@click="login()"
								:loading="formLoading"
							>
								<v-icon size="25" left>mdi-login</v-icon>
								Ingresar
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import MainExternalData from '@/mixins/main-external-data'
export default {
	name: 'BaseFormLock',
	mixins: [MainExternalData],

	data() {
		return {
			formLoading: false,
			showPwd: false,
			editedItem: {
				password:''
			},
			defaultItem: {
				password:''
			},
		}
	},

	computed: {
    ...mapState(["lockData"]),
  },

	methods: {
		async login() {
			let me=this;
			if (!me.$refs.LoginForm.validate()) {
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'token': me.token,
					'password': me.editedItem.password,
					'branchOfficeId': me.companyData.branchOfficeId,
				}

				await me.getObjectResponse('api/Account/NewLoginToSelectedBranch', request).then(data => {
					// console.log('lock-login',data)
					if (data == undefined) {
						// me.$store.dispatch('logout');
						return;
					}
					me.saveToken(data.token);
					me.clean();
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		clean() {
			this.lockData.showDialog = false;
			this.$refs.LoginForm.reset();
			this.editedItem = Object.assign({}, this.defaultItem);
		},
	},

}
</script>
<style>
	
</style>