<template>
	<base-form-container
		:title="title"
		titleClasses="grey lighten-3"
	>
		<template v-if="$slots.titleContent" v-slot:titleContent>
			<v-spacer></v-spacer>
			<slot name="titleContent"></slot>
		</template>

		<template v-slot:content>
			<v-alert v-if="mode !== 'DistributeStock' && mode !== 'ReduceStock' && mode !== 'IncreaseStock' && mode !== 'IncreaseAndAddStock' && mode !== 'ShowStockMovement'"
				text
				elevation="2"
				class="text-center mb-0"
				color="success"
				transition="scale-transition"
			>
				<v-icon color="success" left>mdi-check</v-icon> <strong>El producto tiene asignado el formulario general para el manejo de stock.</strong>
			</v-alert>

			<v-row v-else justify="center" dense>
				<v-col cols="12" sm="9">
					<base-data-table
						:headers="detailHeaders" 
						:items="details"
						sortBy="id"
						hideHeader
					>
						<template v-if="mode === 'DistributeStock' || mode === 'ReduceStock' || mode === 'IncreaseStock' || mode === 'IncreaseAndAddStock'" v-slot:item.stock="{ item }">
							<base-number-field v-model="item.stock" maxlength="6"
							prepend-inner-icon="mdi-cart-arrow-right" :rules="[validDetailQuantity(item), validationRules.quantityRules.valid, validationRules.quantityRules.counter]"></base-number-field>
						</template>

						<template v-slot:item.originalStock="{ item }">
							<v-icon small>mdi-cart-variant</v-icon> {{ item.originalStock }}
						</template>
					</base-data-table>
				</v-col>
			</v-row>
		</template>
	</base-form-container>
</template>
<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: 'BaseStockGenericDetails',
	mixins: [MainExternalData],

	props: {
		title: {type: String, required: true},
		details: {type: Array, required: true},
		mode: {
			type: String,
			required: true,
			validator: v => ['NewData', 'Editable', 'ShowData', 'DistributeStock', 'ReduceStock', 'IncreaseStock', 'IncreaseAndAddStock', 'ShowStockMovement'].includes(v),
		},
	},

	data() {
		return {
			validationRules: {
				quantityRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) >= 0) || 'No puede ser vacío o menor a 0.',
				},
			},
		}
	},

	computed: {
		detailHeaders(){
			var headingsResult=[]
			if (this.mode !== 'NewData') {
				headingsResult.push({ text: "Cod.", value: "id", sortable: false })
			}
			if (this.mode === 'DistributeStock') {
				headingsResult.push(
					{ text: "Stock", value: "originalStock", sortable: false },
					{ text: "Cantidad", value: "stock", sortable: false })
			} else if (this.mode === 'ReduceStock' || this.mode === 'IncreaseStock' || this.mode === 'IncreaseAndAddStock') {
				headingsResult.push(
					{ text: "Stock", value: "originalStock", sortable: false },
					{ text: "Cantidad", value: "stock", sortable: false })
			} else if (this.mode === 'ShowStockMovement') {
				headingsResult.push({ text: "Cantidad", value: "stock", sortable: false })
			}
			return headingsResult;
		},
	},

	methods: {
		validDetailQuantity(item){
			const id = item.id;
			const quantity = item.stock;
			const stock = item.originalStock;

			if (this.mode === 'IncreaseStock') {
				return true;
			}

			if (this.mode === 'IncreaseAndAddStock') {
				if (quantity <= 0) {
					return 'No puede ser menor a 1.'
				}
				return true;
			}
			
			if (quantity > stock) {
				return 'No puede ser mayor al stock.'
			}
			return true;
		},
	},
}
</script>