<template>
<div>
	<v-card class="text-center" max-height="350px"
    v-bind="$attrs"
	  v-on="$listeners"
  >
		<v-card-title class="title font-weight-regular justify-space-between grey lighten-3">
      <span>{{ title }}</span>

      <input v-if="!filesUploaded" type="file" ref="uploadFile" :name="name" class="d-none" @change="fileChange($event.target.name, $event.target.files);"
      :accept="accept.join(',')">
			<transition name="fade" mode="out-in">
        <v-btn v-if="!filesUploaded" key="upload" color="blue-grey" class="white--text" @click="$refs.uploadFile.click()" >Subir <v-icon right dark>mdi-cloud-upload</v-icon></v-btn>
				<v-btn v-else key="clean" color="secondary" @click="clean()" >Quitar <v-icon right dark>mdi-text-box-remove-outline</v-icon></v-btn>
			</transition>
		</v-card-title>
		<v-divider></v-divider>
    <v-alert v-model="editedFileError.show"
      text
      dense
      type="error"
      border="left"
      justify="center"
      v-text="editedFileError.message"
      transition="scale-transition"
    >
    </v-alert>
		<v-card-text class="flex-shrink-1" wrap>
      <!-- <v-img :src="imageSrc" contain height="200" width="auto"></v-img> -->
      <v-img :src="imageSrc" contain aspect-ratio="1.5"></v-img>
		</v-card-text>
	</v-card>
</div>
</template>

<script>
export default {
	name: 'BaseDataTable',

	props: {
    title: {type: String, default:'Subir Archivo', require: true},
    name: {type: String, require: true},
    image: String,
    accept: Array,
	},

	data() {
		return {
      defaultImg: require("@/assets/product/no_photo.jpg"),
      filesUploaded: false,
      editedFile:{
        name:'',
        type:'',
        base64:'',
      },
      defaultFile:{
        name:'',
        type:'',
        base64:'',
      },
      editedFileError:{
        message: '',
        show: false,
      },
      defaultFileError:{
        message: '',
        show: false,
      },
		}
  },

  computed: {
    imageSrc(){
      if (this.image) {
        this.filesUploaded = true;
        this.editedFile.base64 = this.image;
        return this.image;
      }else {
        this.filesUploaded = false;
        this.editedFile = Object.assign({}, this.defaultFile);
        return this.defaultImg;
      }
    }
  },

	methods: {
		fileChange(name, files){
      let me = this;
      me.editedFileError = Object.assign({}, me.defaultFileError);
      me.editedFile = Object.assign({}, me.defaultFile);

      const file = files[0];
      if (me.accept) {
        let ext = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        if (!ext || !me.accept.includes(ext.toLowerCase())) {
          me.editedFileError.message='Archivo no válido';
          me.editedFileError.show=true;
          return;
        }
      }
      
      //1 Megabytes = 1048576 Bytes
      if (file.size > 1024 * 20480) {
        me.editedFileError.message='El archivo no debe ser mayor a 20MB.';
        me.editedFileError.show=true;
        return;
      }

      me.editedFile.name=file.name;
      me.editedFile.type=file.type;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        me.editedFile.base64 = e.target.result
        this.$emit('onChange', name, me.editedFile);
      };
    },
    
    clean(){
      this.editedFile = Object.assign({}, this.defaultFile);
      this.$emit('onChange', this.name, null);
    },
	},
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>