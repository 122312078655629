<template>
  <v-row justify="center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      bottom
      min-width="200px"
      rounded
      offset-y
      origin="top right" transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-avatar
            color="grey"
            size="42px"
          >
            <v-img :src="userData.profilePicture" />
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar
              color="grey"
              size="120"
            >
              <v-img :src="userData.profilePicture" />
            </v-avatar>
            <h3 class="pt-4 mx-2">{{ userData.fullName }}</h3>
            <p class="caption mt-1 mx-2">
              {{ userData.email }}
            </p>
            <template v-if="!userData.passwordExpired">              
              <v-divider class="my-1"></v-divider>
              <v-btn
                depressed
                block
                text
                :to="{ name: 'Perfil'}"
                @click="menu = false"
              >
                Ver tú perfil
              </v-btn>
              <template v-if="userData.assignedBranchOffices > 1">
                <v-divider class="my-1"></v-divider>
                <v-btn
                  depressed
                  block
                  text
                  @click="changeBranchOffice()"
                >
                  Cambiar de sucursal
                </v-btn>
              </template>
            </template>
            <v-divider class="my-1"></v-divider>
            <v-btn
              depressed
              block
              text
              @click="$router.push({ name: 'Logout' })"
            >
              Cerrar Sesion
            </v-btn>
            <v-divider class="mt-1"></v-divider>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
	name: 'BaseProfileMenu',

	data() {
		return {
			menu: false,
		}
  },

  computed: {
    ...mapState(["userData"]),
  },
  
  methods: {
    ...mapActions(['logout']),

    changeBranchOffice()
    {
      this.menu = false;
      this.$store.commit("SET_SHOW_DIALOG_TO_CHANGE_BRANCH", true);
    }
  },
}
</script>