var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-container',{attrs:{"title":_vm.title,"titleClasses":"grey lighten-3"},scopedSlots:_vm._u([(_vm.$slots.titleContent)?{key:"titleContent",fn:function(){return [_c('v-spacer'),_vm._t("titleContent")]},proxy:true}:null,{key:"content",fn:function(){return [(_vm.showForm)?_c('v-form',{ref:"DetailForm",staticClass:"px-4 pt-2",attrs:{"onSubmit":"return false;"}},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3","align":"center"}},[_c('base-color-picker',{attrs:{"items":_vm.availableColors,"label":"Color","placeholder":"Seleccione...","rules":[_vm.commonValRules.required, _vm.verifyColorBefore],"required":""},model:{value:(_vm.newDetail.colorId),callback:function ($$v) {_vm.$set(_vm.newDetail, "colorId", $$v)},expression:"newDetail.colorId"}})],1),_c('v-col',{attrs:{"cols":"8","sm":"2","align":"center"}},[_c('base-number-field',{attrs:{"label":"Stock","maxlength":"6","prepend-inner-icon":"mdi-cart-variant","rules":[_vm.validationRules.stockRules.valid, _vm.validationRules.stockRules.counter],"required":""},model:{value:(_vm.newDetail.stock),callback:function ($$v) {_vm.$set(_vm.newDetail, "stock", $$v)},expression:"newDetail.stock"}})],1),_c('v-col',{staticClass:"pt-3",attrs:{"cols":"4","sm":"2","align":"center"}},[_c('base-icon-btn',{attrs:{"fabMode":"","size":"25","btnColor":"primary","color":"white","icon":"mdi-plus-thick","tooltip":"Agregar"},on:{"click":_vm.newItem}})],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9"}},[_c('base-data-table',{attrs:{"headers":_vm.detailHeaders,"items":_vm.details,"sortBy":_vm.showForm ? '' : 'id',"hideHeader":""},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [(_vm.itemIsEditable(item))?_c('base-icon-btn',{attrs:{"color":"red darken-1","icon":"mdi-close","tooltip":"Eliminar Detalle"},on:{"click":function($event){return _vm.removeDetail(item)}}}):(_vm.mode === 'Editable')?_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.enableDisableStock(item)}},model:{value:(item.editStock),callback:function ($$v) {_vm.$set(item, "editStock", $$v)},expression:"item.editStock"}}):_vm._e()]}},{key:"item.colorId",fn:function(ref){
var item = ref.item;
return [(_vm.itemIsEditable(item))?_c('base-color-picker',{staticClass:"custom-max-width",attrs:{"items":_vm.colors,"rules":[_vm.commonValRules.required, _vm.verifyColor(item)],"required":""},model:{value:(item.colorId),callback:function ($$v) {_vm.$set(item, "colorId", $$v)},expression:"item.colorId"}}):_c('base-color-picker',{attrs:{"view-mode":"","item-value":{'text': item.colorName, 'hexCode': item.hexCode}}})]}},(_vm.mode != 'DistributeStock' && _vm.mode != 'ReduceStock' && _vm.mode != 'IncreaseStock' && _vm.mode != 'IncreaseAndAddStock')?{key:"item.stock",fn:function(ref){
var item = ref.item;
return [(_vm.stockIsEditable(item))?_c('base-number-field',{attrs:{"maxlength":"6","prepend-inner-icon":"mdi-cart-variant","rules":[_vm.validationRules.stockRules.valid, _vm.validationRules.stockRules.counter],"required":""},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}}):[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cart-variant")]),_vm._v(" "+_vm._s(item.stock)+" ")]]}}:{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('base-number-field',{attrs:{"maxlength":"6","prepend-inner-icon":"mdi-cart-arrow-right","rules":[_vm.validDetailQuantity(item), _vm.validationRules.quantityRules.valid, _vm.validationRules.quantityRules.counter]},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}})]}},{key:"item.originalStock",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cart-variant")]),_vm._v(" "+_vm._s(item.originalStock)+" ")]}}],null,true)})],1)],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }