const Paramaters = {
	DOCUMENTS: 1,
	EXTENSIONS: 2,
	COLORS: 3,
	ADULT_CLOTHING_SIZES: 4,
};

const DocumentType = {
	CI: 11
};

const Status = {
	REGISTERED: 1,
	PENDING: 2,
	CANCELLED: 3,
	APPROVED: 4,
	CREDIT_PENDING: 6,
	CREDIT_COMPLETED: 7
};

const StockForm = {
	GENERAL: 1,
	CLOTHING: 2,
	COLOR: 3
};

const InventoryMovementStatus = {
	REGISTERED: 1,
	CANCELLED: 2,
	SHIPPED: 3,
	DELIVERED: 4,
	APPROVED: 5
};

const InventoryMovementType = {
	DISTRIBUTION: 1,
	DAMAGED_STOCK: 2,
	ADJUSTMENT: 3
};

export default {
	install(Vue, options) {
		Vue.prototype.$Const = {
			PARAMETERS: Paramaters,
			DOCUMENT_TYPE: DocumentType,
			STATUS: Status,
			STOCK_FORM: StockForm,
			INVENTORY_MOVEMENT_STATUS: InventoryMovementStatus,
			INVENTORY_MOVEMENT_TYPE: InventoryMovementType,
			APP_VERSION: options.appVersion
		}
	}
}