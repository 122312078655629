<template>
	<base-text-field class="centered-input"
		:class="{'custom-max-width' : !fullWidth}"
		v-bind="$attrs"
    v-on="$listeners"
		v-bind:value="value" v-on:input="$emit('input', $event)"
		:placeholder="placeholder"
		:rules="customRules"
		:required="required"
		:prefix="prefix" 
		
		@keypress="onlyNumbers($event)"
		type="number"
	></base-text-field>
	<!-- :clearable="customClearable" -->
</template>

<script>
// Mixins
import DataTools from '@/mixins/data-tools'

export default {
	name: 'BaseNumberField',
	mixins: [DataTools],

	model:{
		prop:'value',
		event:'input'
	},

	props: {
		value: {type: [String, Number] },
		prefix: {type: String, default: '' },
		required: {type: Boolean, default: false},
		disableDefaultRules: {type: Boolean, default: false},
		rules: { type: Array },
		fullWidth: {type: Boolean, default: false},
	},

	data() {
		return {
			placeholder: '0'
		}
	},

	computed: {
		customRules() {
			let result = [];
			if (this.required) {
				result.push(this.commonValRules.required);
			}

			if (this.disableDefaultRules) {
				this.rules.forEach(rule => {
					result.push(rule)
				});
				return result;
			}

			if (this.rules && this.rules.length > 0) {
				this.rules.forEach(rule => {
					result.push(rule)
				});
			}
			return result;
		},

		customClearable() {
			if (this.$attrs.disabled || this.$attrs.readonly) {
				return ;
			}
			return true;
		}
	},
}
</script>