<template>
	<div>
		<v-form ref="StockDetails" onSubmit="return false;">
			<!-- StockForm = 1 General -->
			<base-stock-generic-details v-if="stockFormId===$Const.STOCK_FORM.GENERAL" :title="title" :details="details" :mode="mode">
				<template v-if="$slots.titleContent" v-slot:titleContent>
					<slot name="titleContent"></slot>
				</template>

				<template v-if="$slots.actions" v-slot:actions>
					<slot name="actions"></slot>
				</template>
			</base-stock-generic-details>
			<!-- StockForm = 2 Clothing -->
			<base-stock-clothing-details v-else-if="stockFormId===$Const.STOCK_FORM.CLOTHING" :title="title" :details="details" :mode="mode">
				<template v-if="$slots.titleContent" v-slot:titleContent>
					<slot name="titleContent"></slot>
				</template>

				<template v-if="$slots.actions" v-slot:actions>
					<slot name="actions"></slot>
				</template>
			</base-stock-clothing-details>
			<!-- StockForm = 3 Color -->
			<base-stock-color-details v-else-if="stockFormId===$Const.STOCK_FORM.COLOR" :title="title" :details="details" :mode="mode">
				<template v-if="$slots.titleContent" v-slot:titleContent>
					<slot name="titleContent"></slot>
				</template>

				<template v-if="$slots.actions" v-slot:actions>
					<slot name="actions"></slot>
				</template>
			</base-stock-color-details>
		</v-form>
	</div>
</template>
<script>
export default {
	name: 'BaseFormStockDetails',

	props: {
		title: {type: String, required: true},
		stockFormId: {type: Number, required: true},
		details: {type: Array, required: true},
		mode: {
			type: String,
			required: true,
			validator: v => ['NewData', 'Editable', 'ShowData', 'DistributeStock', 'ReduceStock', 'IncreaseStock', 'IncreaseAndAddStock', 'ShowStockMovement'].includes(v),
		},
	},

	methods: {
		validate(){
			return this.$refs.StockDetails.validate()
		},
	},
}
</script>