<template>
	<div>
		<h3>{{title}}</h3>
		<small v-if="showRequiredLabel" class="red--text">Campo requerido.</small>
		<v-divider class="my-2" />
		<gmap-map :options="mapOptions"
			:center="myCoordinates"
			:zoom="zoom"
			style="width:auto; height:360px;"
			ref="mapRef"
			@click="handleMapClick"
			@dragend="handleDrag"
		>
			<gmap-marker v-if="showMarker"
				:position="markerCoordinates"
			>
			</gmap-marker>
		</gmap-map>
	</div>
</template>

<script>
export default {
	name: 'BaseMap',

	model:{
		prop:'coordinates',
		event:'onChange'
	},

	props: {
		coordinates: {type: String, default: null},
		title: {type: String, default: 'Indique su hubicación por favor.'},
		searchAddress: {type: String, default: 'La Paz, Bolivia'},
	},

	data() {
		return {
			map: null,
			myCoordinates: {
				lat: 0,
				lng: 0
			},
			zoom: 12,
			defaultZoom: 12,
			showMarker: false,
			markerCoordinates: {
				lat: 0,
				lng: 0
			},
			currentAddress: '',
			mapOptions: {
				mapTypeControl: false
			},

			showRequiredLabel: false,
		}
	},

	computed: {
	},

	watch: {
		searchAddress(val){
			if (val !== this.currentAddress) {
				this.searchLocation()
				return
			}

			if (this.coordinates) {
				this.setCoordinates();
				return
			}
		}
	},

	created() {
		if (this.coordinates) {
			this.setCoordinates();
		} else {
			//get user's coordinates from browser request

			// this.$getLocation({}).then(coordinates => {
			// 	this.myCoordinates = coordinates
			// })//TODO catch
			// .catch(error => alert(error));
		}
	},

	mounted() {
		// add the map to a data object
		this.$refs.mapRef.$mapPromise.then(map => {
			this.map = map
			if (!this.showMarker) {
				this.searchLocation()
			}
		});
	},

	methods: {
		handleDrag() {
			//get center and zoom level, store in localstorage or database
			let zoom = this.map.getZoom();

			let response = {
				center : this.markerCoordinates,
				zoom : zoom
			}
			this.$emit('onChange', JSON.stringify(response));
		},

		handleMapClick(e) {
			this.showMarker= true;
			this.markerCoordinates = {
				lat: e.latLng.lat(),
				lng: e.latLng.lng()
			};
			let zoom = this.map.getZoom();

			let response = {
				center : this.markerCoordinates,
				zoom : zoom
			}
			this.$emit('onChange', JSON.stringify(response));
		},

		searchLocation() {
			if (!this.searchAddress) {
				return
			}

			this.showMarker= false;
			this.currentAddress = this.searchAddress;
			this.zoom= parseInt(this.defaultZoom);
			let geocoder = new google.maps.Geocoder();
      geocoder.geocode({'address': this.searchAddress}, (results, status) => {
				if (status === 'OK') {
          this.myCoordinates.lat = parseFloat(results[0].geometry.location.lat());
					this.myCoordinates.lng = parseFloat(results[0].geometry.location.lng());
        }
			});
		},

		setCoordinates() {
			const coordinatesSaved = JSON.parse(this.coordinates)
			//does the user have a saved center? use it instead of the default
			this.myCoordinates = coordinatesSaved.center
			//does the user have a saved zoom? use it instead of the default
			this.zoom = parseInt(coordinatesSaved.zoom)

			this.markerCoordinates = coordinatesSaved.center
			this.showMarker = true
			this.currentAddress = this.searchAddress;
		},

		validate() {
			this.showRequiredLabel= !this.showMarker
			return this.showMarker;
		},

		reset() {
			this.showRequiredLabel= false
		}

		// geolocation : function() {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.currentLocation = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude
    //     };
    //   });
    // }
	},
}
</script>