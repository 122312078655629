<template>
	<v-checkbox
		v-bind="$attrs"
    v-on="$listeners"
		v-bind:value="value" v-on:change="$emit('change', $event)"
		:label="customLabel"
		:required="required"
		color="info"
	></v-checkbox>
</template>

<script>
export default {
	name: 'BaseCheckbox',

	model:{
		prop:'value',
		event:'change'
	},

	props: {
		value: {type: undefined },
		label: {type: String },
		required: {type: Boolean, default: false},
		// color: {type: String, default: 'info'}
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		},
	},
}
</script>