var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-img',_vm._g(_vm._b({directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
		callback: function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry); },
		once: true,
		throttle: 500,
		intersection: {
			threshold: 0.9,
		},
	}),expression:"{\n\t\tcallback: (isVisible, entry) => visibilityChanged(isVisible, entry),\n\t\tonce: true,\n\t\tthrottle: 500,\n\t\tintersection: {\n\t\t\tthreshold: 0.9,\n\t\t},\n\t}"}],attrs:{"src":_vm.image ? _vm.image : _vm.defaultProductImage,"lazy-src":_vm.defaultProductImage},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])},'v-img',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }