<template>
	<div>
		<input type="file" ref="uploadFile" :name="name" class="d-none" @change="fileChange($event.target.name, $event.target.files);"
      :accept="accept.join(',')">
		<v-btn v-bind="$attrs"
			v-on="$listeners"
			key="upload"
			color="blue-grey"
			class="white--text"
			@click="$refs.uploadFile.click()"
		>
			{{text}} <v-icon right dark>{{icon}}</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'BaseFileInputBtn',

	props: {
		name: {type: String, require: true},
		text: {type: String, default: 'Subir archivo'},
		icon: {type: String, default: 'mdi-cloud-upload'},
		accept: Array,
		sizeAllowed: {type: Number, default: 1024 * 20480}
	},

	data() {
		return {
			editedFile:{
        name:'',
        type:'',
        base64:'',
      },
      defaultFile:{
        name:'',
        type:'',
        base64:'',
			},
			
			editedFileError:{
        message: '',
        success: true,
      },
      defaultFileError:{
        message: '',
        success: true,
      },
		}
	},

	methods: {
		fileChange(name, files){
      let me = this;
      me.editedFileError = Object.assign({}, me.defaultFileError);
      me.editedFile = Object.assign({}, me.defaultFile);

      const file = files[0];
      if (me.accept) {
        let ext = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        if (!ext || !me.accept.includes(ext.toLowerCase())) {
          me.editedFileError.message='Archivo no válido';
          me.editedFileError.success=false;
          return;
        }
      }
      
      //1 Megabytes = 1048576 Bytes
      // if (file.size > 1024 * 20480) {
			if (file.size > me.sizeAllowed) {
        me.editedFileError.message='El archivo no debe ser mayor a 20MB.';
        me.editedFileError.success=false;
        return;
      }

      me.editedFile.name=file.name;
      me.editedFile.type=file.type;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        me.editedFile.base64 = e.target.result
        this.$emit('onChange', name, me.editedFile, me.editedFileError);
      };
    },
	},
}
</script>