<template>
	<v-select v-if="!viewMode" :color="color"
		no-data-text="Datos no disponibles."
		v-bind="$attrs"
    v-on="$listeners"
		:label="customLabel"
		:required="required"
		:menu-props="{ maxHeight: '200' }"
	>
		<template v-slot:item="{item}">
			<div class="dropdown">
				<span :style="{background: item.hexCode}"></span> {{item.text}}
			</div>
		</template>

		<template v-slot:selection="{item}">
			<div class="dropdown dropdown-no-wrap">
				<span :style="{background: item.hexCode}"></span> {{item.text}}
			</div>
		</template>
	</v-select>

	<div v-else-if="itemValue!=undefined" class="dropdown dropdown-no-wrap">
		<span :style="{background: itemValue.hexCode}"></span> {{itemValue.text}}
	</div>



	<!-- <div class="wrapper-dropdown">
		<span @click="toggleDropdown()" v-html="selector"></span>
		<ul class="dropdown" v-show="active">
			<li v-if="emptyOption" @click="setColor();">
				<span class="noColor" v-if="emptyOption !== 'true'"></span> {{emptyOption === "true" ? "" : emptyOption}}
			</li>
			<li v-for="color in colors" :key="color.id" @click="setColor(color.hex, color.text)">
				<span :style="{background: color.hex}"></span> {{color.text}}
			</li>
		</ul>
		
		<input type="hidden" v-model="selectedColor">
	</div> -->

	<!-- <input type="hidden" :name="inputId" :id="inputId" v-model="selectedColor"> -->
</template>

<script>
export default {
	name: 'BaseColorPicker',

	props: {
		label: {type: String },
		required: {type: Boolean, default: false},
		color: {type: String, default: 'info'},

		viewMode: {type: Boolean, default: false},
		itemValue: {type: Object },

		// colorOptions: { type: Array },
		// label: { type: String, default: ''},
		// emptyOption: { type: String },
		// color: {type: String, default: 'info'}
	},

	// props: ['color-options', 'label', 'empty-option', 'input-id'],

	// data() {
	// 	return {
	// 		active: false,
	// 		selectedColor: '',
	// 		selectedColorName: '',
	// 		colors: this.colorOptions,
	// 		noSelection: true
	// 	}
	// },

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		},
	},

	// computed: {
	// 	selector() {
	// 		if(!this.selectedColor && !this.emptyOption) {
	// 			return this.label;
	// 		}
	// 		else if(!this.selectedColor && this.emptyOption) {
	// 			if(this.emptyOption === "true") {
	// 				return this.label;
	// 			}
	// 			else if(this.emptyOption !== "true" && this.noSelection === false) {
	// 				return '<span class="noColor"></span> ' + this.emptyOption;
	// 			}
	// 			else {
	// 				return this.label;
	// 			}
	// 		}
	// 		else {
	// 			return '<span style="background: ' + this.selectedColor + '"></span> ' + this.selectedColorName;
	// 		}
	// 	},
	// },

	// methods: {
	// 	setColor(color, colorName) {
	// 		this.selectedColor = color;
	// 		this.selectedColorName = colorName;
	// 		this.active = false;
	// 		this.noSelection = false;
	// 		this.$emit('input', this.selectedColor);
	// 	},

	// 	toggleDropdown() {
	// 		this.active = !this.active;
	// 	},
	// },
}
</script>

<style scoped>
/* * {
	box-sizing: border-box;
	font-family: "Arial";
} */
/* .wrapper-dropdown {
    position: relative;
    width: 200px;
    background: #FFF;
    color: #2e2e2e;
    outline: none;
    cursor: pointer;
}

.wrapper-dropdown > span {
	width: 100%;
	display: block;
	border: 1px solid #ababab;
	padding: 5px;
}
.wrapper-dropdown > span > span {
  padding: 0 12px;
  margin-right: 5px;
}
.wrapper-dropdown > span > span.noColor {
	background: #CCC;
	position: relative;
}
.wrapper-dropdown > span > span.noColor:after {
	content: "";
	background: red;
	-webkit-transform: rotate(-32deg);
  transform: rotate(-32deg);
  display: inline-block;
  width: 28px;
  height: 2px;
  position: absolute;
  bottom: 7px;
  left: -2px;
}
.wrapper-dropdown > span:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 16px;
    top: calc(50% + 4px);
    margin-top: -6px;
	  border-width: 6px 6px 0 6px;
    border-style: solid;
	  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
    position: absolute;
	  z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    font-weight: normal;
	  list-style-type: none;
	  padding-left: 0;
	  margin: 0;
	  border: 1px solid #ababab;
	  border-top: 0;
}

.wrapper-dropdown .dropdown li {
    display: block;
    text-decoration: none;
    color: #2e2e2e;
	  padding: 5px;
	  cursor: pointer;
	  min-height: 28px;
}

.wrapper-dropdown .dropdown li > span {
  padding: 0 12px;
  margin-right: 5px;
}
.wrapper-dropdown .dropdown li > span.noColor {
	background: #CCC;
	position: relative;
}
.wrapper-dropdown .dropdown li > span.noColor:after {
	content: "";
	background: red;
	-webkit-transform: rotate(-32deg);
  transform: rotate(-32deg);
  display: inline-block;
  width: 28px;
  height: 2px;
  position: absolute;
  bottom: 7px;
  left: -2px;
}

.wrapper-dropdown .dropdown li:hover {
    background: #eee;
	  cursor: pointer;
} */

/* .dropdown {
	  z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    
    font-weight: normal;
	  list-style-type: none;
	  padding-left: 0;
	  margin: 0;
	  
	  border-top: 0;
} */

.dropdown-no-wrap {
	flex: 1 1 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis; */

.dropdown > span {
  padding: 0 12px;
  margin-right: 5px;
}
/* .dropdown > span.noColor {
	background: #CCC;
	position: relative;
} */
/* .dropdown > span.noColor:after {
	content: "";
	background: red;
	-webkit-transform: rotate(-32deg);
  transform: rotate(-32deg);
  display: inline-block;
  width: 28px;
  height: 2px;
  position: absolute;
  bottom: 7px;
  left: -2px;
} */
</style>