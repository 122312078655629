<template>
	<v-dialog
		v-model="show"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card>
			<v-toolbar
				
				color="rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)"
			>
				<v-btn
					icon
					
					@click="$emit('close')"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title><b>Reporte:</b> {{ title }}</v-toolbar-title>
			</v-toolbar>

			<v-card-text>
				<!-- <iframe :src="src" style="width: 100%; height: 90vh" frameborder="0"> -->
					<object :data="src" type="application/pdf" style="width: 100%; height: 90vh">
					<!-- <p>Oops...! Al parecer tu navegador no tiene soporte para archivos PDF. Puedes descargar el archivo haciendo click en el siguiente enlace: <a :href="src" target="_blank" id="downloadLink" type="application/octet-stream" :download="fileName">{{fileName}}</a></p> -->
						<p class="pt-4 text-justify">Oops...! Al parecer tu navegador no tiene soporte para archivos PDF.</p> 
						<p>Puedes descargar el archivo haciendo click en el siguiente enlace: <a :href="src" id="downloadLink" :download="fileName">{{fileName}}</a></p>
						<embed :src="src" type="application/pdf" />
						<!-- It appears you don't have PDF support in this web browser. <a :href="src" type="application/pdf" id="download-link"> Click here to download the PDF</a>. -->
					</object>
				<!-- </iframe> -->
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'PdfViewer',
	props: {
		title: { type: String, required: true },
		src: { type: String, required: true },
		fileName: { type: String, required: true },
		// contentType: { type: String, required: true },
		show: { type: Boolean, default: false }
	}
}
</script>