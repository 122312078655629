<template>
	<v-dialog
		v-model="pdfDialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on, attrs }">
			<base-btn 
				v-on="on"
				v-bind="attrs"
				color="secondary" 
				@click="pdfDialog = true" 
				icon="mdi-printer" 
				btnText="Imprimir"
			></base-btn>
		</template>
<div id="voucher">
					<header>
							<div id="logo">
									<img :src="companyData.logo" id="image">
									<div id="voheaders">
											<b id="companyname" class="uppercase">{{companyData.companyVisualName}}</b><br>
											<b id="branchname" class="uppercase">Casa Matriz</b><br>
											José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
									</div>
							</div>

							<div id="branchdata">
									<div id="vouchertype">{{ type==='Venta' ? 'Recibo' : voucherType}}</div>
									<p id="voheaders">
											<b id="branchname" class="uppercase">{{companyData.branchOfficeVisualName}}</b><br>
											José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
									</p>
							</div>

							<div v-if="type==='Venta'" id="saledata" class="uppercase">
									<p>Original - Cliente</p>
									Venta Nº: {{ number }}<br>
							</div>
							<div v-else id="saledata" class="uppercase">
									Compra Nº: {{ number }}<br>
									Código Nº: {{ number }}<br>
									Comprobante N°: {{}}<br>
									Autorización N°: {{}}<br>
							</div>
					</header>
					<br>
					<section style="padding-top: 20px;">
							<div>
									<table id="vocustomer">
											<tbody>
													<tr>
															<td id="customer">
																	<p style="margin-bottom: 0;"><strong class="uppercase">Lugar y Fecha:</strong> La Paz, 14 de enero de 2021</p>
																	<span class="uppercase"><strong>Señor(a):</strong> {{ customer }}</span><br>
															</td>
													</tr>
											</tbody>
									</table>
							</div>
					</section>
					<br>
					<section>
							<div id="details">
									<table id="detable">
											<thead>
													<tr id="detheader">
															<th style="width: 120px;">CODIGO</th>
															<th>DESCRIPCION</th>
															<th style="width: 80px;">CANT</th>
															<th style="width: 100px;">PRECIO UNIT</th>
															<th style="width: 80px;">DESC.</th>
															<th style="width: 100px;">TOTAL</th>
													</tr>
											</thead>
											<tbody>
													<tr v-for="(prod, index) in products" :key="index">
															<td>{{prod.customCode}}</td>
															<td>{{prod.product}}</td>
															<td style="text-align:center;">{{prod.quantity}}</td>
															<td style="text-align:right;">{{prod.unitPrice}}</td>
															<td style="text-align:right;">{{((prod.unitPrice * prod.quantity) * (prod.discount / 100)).toFixed(2)}}</td>
															<td style="text-align:right;">{{prod.totalPrice}}</td>
													</tr>
											</tbody>
									</table>
							</div>
					</section>
					<hr>
					<section>
							<div>
									<table id="totaltable">
											<tr>
													<td style="vertical-align: top;">
															<b>SON:</b> DIECISEIS 80/100 BOLIVIANOS<br>
															<b>FORMA DE PAGO:</b> CONTADO<br>
													</td>
													<td style="text-align:right; vertical-align: top;">
															SUB TOTAL Bs.: {{subtotal}}<br>
															DESCUENTO ({{discount}}%) Bs.: {{totalDiscount}}<br>
															<b>TOTAL Bs.: {{totalPrice}}</b><br><br>
															EFECTIVO Bs.: {{cash}}<br>
															CAMBIO Bs.: {{(cash - totalPrice).toFixed(2)}}<br>
													</td>
											</tr>
									</table>
							</div>
					</section>
					<br>
					<footer>
							<div id="gracias">
									<b>Gracias por su compra!</b><br>
									<b>Emitido por el sistema GAVCOM de Global Desk</b>
							</div>
					</footer>
				</div>
		<v-card>
			<v-toolbar
				dark
				color="primary"
			>
				<v-btn
					icon
					dark
					@click="close"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title><b>Reporte:</b> {{ type }}</v-toolbar-title>
				
			</v-toolbar>

			<!-- <iframe id="output" frameborder="0"></iframe> -->
			<v-card-text>
				<base-btn 
				color="secondary" 
				@click="generatePDF" 
				icon="mdi-printer" 
				btnText="Imprimir"
			></base-btn>

				<!-- <div id="voucher">
					<header>
							<div id="logo">
									<img :src="companyData.logo" id="image">
									<div id="voheaders">
											<b id="companyname" class="uppercase">{{companyData.companyVisualName}}</b><br>
											<b id="branchname" class="uppercase">Casa Matriz</b><br>
											José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
									</div>
							</div>

							<div id="branchdata">
									<div id="vouchertype">{{ type==='Venta' ? 'Recibo' : voucherType}}</div>
									<p id="voheaders">
											<b id="branchname" class="uppercase">{{companyData.branchOfficeVisualName}}</b><br>
											José Gálvez 1368, Chongoyape - Chiclayo, Perú<br>Telefono:(+51)931742904<br>Email:jcarlos.ad7@gmail.com
									</p>
							</div>

							<div v-if="type==='Venta'" id="saledata" class="uppercase">
									<p>Original - Cliente</p>
									Venta Nº: {{ number }}<br>
							</div>
							<div v-else id="saledata" class="uppercase">
									Compra Nº: {{ number }}<br>
									Código Nº: {{ number }}<br>
									Comprobante N°: {{}}<br>
									Autorización N°: {{}}<br>
							</div>
					</header>
					<br>
					<section style="padding-top: 20px;">
							<div>
									<table id="vocustomer">
											<tbody>
													<tr>
															<td id="customer">
																	<p style="margin-bottom: 0;"><strong class="uppercase">Lugar y Fecha:</strong> La Paz, 14 de enero de 2021</p>
																	<span class="uppercase"><strong>Señor(a):</strong> {{ customer }}</span><br>
															</td>
													</tr>
											</tbody>
									</table>
							</div>
					</section>
					<br>
					<section>
							<div id="details">
									<table id="detable">
											<thead>
													<tr id="detheader">
															<th style="width: 120px;">CODIGO</th>
															<th>DESCRIPCION</th>
															<th style="width: 80px;">CANT</th>
															<th style="width: 100px;">PRECIO UNIT</th>
															<th style="width: 80px;">DESC.</th>
															<th style="width: 100px;">TOTAL</th>
													</tr>
											</thead>
											<tbody>
													<tr v-for="(prod, index) in products" :key="index">
															<td>{{prod.customCode}}</td>
															<td>{{prod.product}}</td>
															<td style="text-align:center;">{{prod.quantity}}</td>
															<td style="text-align:right;">{{prod.unitPrice}}</td>
															<td style="text-align:right;">{{((prod.unitPrice * prod.quantity) * (prod.discount / 100)).toFixed(2)}}</td>
															<td style="text-align:right;">{{prod.totalPrice}}</td>
													</tr>
											</tbody>
									</table>
							</div>
					</section>
					<hr>
					<section>
							<div>
									<table id="totaltable">
											<tr>
													<td style="vertical-align: top;">
															<b>SON:</b> DIECISEIS 80/100 BOLIVIANOS<br>
															<b>FORMA DE PAGO:</b> CONTADO<br>
													</td>
													<td style="text-align:right; vertical-align: top;">
															SUB TOTAL Bs.: {{subtotal}}<br>
															DESCUENTO ({{discount}}%) Bs.: {{totalDiscount}}<br>
															<b>TOTAL Bs.: {{totalPrice}}</b><br><br>
															EFECTIVO Bs.: {{cash}}<br>
															CAMBIO Bs.: {{(cash - totalPrice).toFixed(2)}}<br>
													</td>
											</tr>
									</table>
							</div>
					</section>
					<br>
					<footer>
							<div id="gracias">
									<b>Gracias por su compra!</b><br>
									<b>Emitido por el sistema GAVCOM de Global Desk</b>
							</div>
					</footer>
				</div> -->
				<!-- <iframe :src="pdfSrc" style="width: 100%; height: 90vh;" frameborder="0"></iframe> -->
					<object :data="pdfSrc" type="application/pdf" style="width: 100%; height: 90vh">
						<!-- <p>It appears you don't have PDF support in this web browser. <a :href="pdfSrc" id="download-link"> Click here to download the PDF</a>.</p> -->
						<a href="" id="download-link"></a>
						<embed :src="pdfSrc" type="application/pdf" />
					</object>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";

export default {
	name: 'BasePdfInvoice',
	mixins: [MainExternalData],
	props: {
		type: { type: String, required: true,
			validator: v => ['Compra', 'Venta'].includes(v),
		},
		voucherType: { type: String, required: true},
		number: { type: String, required: true },
		date: { type: String, required: true },

		customer: { type: String, required: true },
		products: { type: Array, required: true },

		subtotal: { type: String, required: true },
		discount: { type: [String, Number], required: true },
		totalDiscount: { type: String, required: true },
		totalPrice: { type: String, required: true },
		cash: { type: String, required: true },
	},

	data() {
		return {
      pdfDialog: false,
      pdfSrc:''
		}
	},

	methods: {
		generatePDF() {
			var element = document.getElementById("voucher");
			// console.log('1', element)

			let a4Size = { w: this.convertPointsToUnit(595.28, 'px'), h: this.convertPointsToUnit(841.89, 'px') }
			// console.log('1.1', a4Size)
			html2canvas(element).then(function (canvas) {
				let doc = new jsPDF("p", "px", "letter");
					
					//Obtengo la dimensión en pixeles en base a la documentación
					// https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L59
					// let a4Size = { w: this.convertPointsToUnit(595.28, 'px'), h: this.convertPointsToUnit(841.89, 'px') }

					/*Nuevo Canvas donde generare mis imágenes separadas*/
					let canvastoPrint = document.createElement('canvas');
					let ctx = canvastoPrint.getContext("2d");

					/* Medidas de mi hoja*/
					canvastoPrint.width = a4Size.w;
					canvastoPrint.height = a4Size.h;

					/* Tomo cuanto corresponde esos los 700 pixeles restantes de el total de mi imagen*/
					let aspectRatioA4 = a4Size.w / a4Size.h;
					let rezised = canvas.width / aspectRatioA4;
					
					let printed = 0, page = 0;
					// console.log('2')
					while (printed < canvas.height) {
							// console.log('3')
							//Tomo la imagen en proporcion a el ancho y alto.
							ctx.drawImage(canvas, 0, printed, canvas.width, rezised, 0, 0, a4Size.w, a4Size.h);
							var imgtoPdf = canvastoPrint.toDataURL("image/png");
							let width = doc.internal.pageSize.getWidth();
							let height = doc.internal.pageSize.getHeight();
							if (page == 0) { // si es la primera pagina, va directo a doc
								// console.log('4')
									doc.addImage(imgtoPdf, 'PNG', 0, 0, width, height);
							}
							else { // Si no ya tengo que agregar nueva hoja.
								// console.log('5')
									let page = doc.addPage();
									page.addImage(imgtoPdf, 'PNG', 0, 0, width, height);
							}
							ctx.clearRect(0, 0, canvastoPrint.width, canvastoPrint.height); // Borro el canvas
							printed += rezised; //actualizo lo que ya imprimi
							page++; // actualizo mi pagina
					}
					// console.log('6')
					doc.save('test.pdf');
			});

// 			html2canvas(element, {
// 				useCORS: true,
// 				onrendered: (canvas) => {
// 					let doc = new jsPDF("p", "mm", "a4");
					
// 					//Obtengo la dimensión en pixeles en base a la documentación
// 					// https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L59
// 					let a4Size = { w: this.convertPointsToUnit(595.28, 'px'), h: this.convertPointsToUnit(841.89, 'px') }

// 					/*Nuevo Canvas donde generare mis imágenes separadas*/
// 					let canvastoPrint = document.createElement('canvas');
// 					let ctx = canvastoPrint.getContext("2d");

// 					/* Medidas de mi hoja*/
// 					canvastoPrint.width = a4Size.w;
// 					canvastoPrint.height = a4Size.h;

// 					/* Tomo cuanto corresponde esos los 700 pixeles restantes de el total de mi imagen*/
// 					let aspectRatioA4 = a4Size.w / a4Size.h;
// 					let rezised = canvas.width / aspectRatioA4;
					
// 					let printed = 0, page = 0;
// 					console.log('2')
// 					while (printed < canvas.height) {
// 							console.log('3')
// 							//Tomo la imagen en proporcion a el ancho y alto.
// 							ctx.drawImage(canvas, 0, printed, canvas.width, rezised, 0, 0, a4Size.w, a4Size.h);
// 							var imgtoPdf = canvastoPrint.toDataURL("image/png");
// 							let width = doc.internal.pageSize.getWidth();
// 							let height = doc.internal.pageSize.getHeight();
// 							if (page == 0) { // si es la primera pagina, va directo a doc
// 								console.log('4')
// 									doc.addImage(imgtoPdf, 'JPEG', 0, 0, width, height);
// 							}
// 							else { // Si no ya tengo que agregar nueva hoja.
// 								console.log('5')
// 									let page = doc.addPage();
// 									page.addImage(imgtoPdf, 'JPEG', 0, 0, width, height);
// 							}
// 							ctx.clearRect(0, 0, canvastoPrint.width, canvastoPrint.height); // Borro el canvas
// 							printed += rezised; //actualizo lo que ya imprimi
// 							page++; // actualizo mi pagina
// 					}
// console.log('6')
// 					doc.save('test.pdf');
// 				}
// 			})
		},

		convertPointsToUnit(points, unit) {
			// Unit table from https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791
			var multiplier;
			switch (unit) {
				case "pt":
					multiplier = 1;
					break;
				case "mm":
					multiplier = 72 / 25.4;
					break;
				case "cm":
					multiplier = 72 / 2.54;
					break;
				case "in":
					multiplier = 72;
					break;
				case "px":
					multiplier = 96 / 72;
					break;
				case "pc":
					multiplier = 12;
					break;
				case "em":
					multiplier = 12;
					break;
				case "ex":
					multiplier = 6;
				default:
					throw "Invalid unit: " + unit;
			}
			return points * multiplier;	
		},

    // async generatePDF(){
		// 	this.pdfDialog = true

		// 	// var quotes = document.getElementById('voucher');
		// 	var quotes = this.$refs.Voucher;
		// 	console.log(quotes)
		// 	const fileName = `Comprobante-${this.number}`;
		// 	await html2canvas(quotes).then(function (canvas) {
		// 		let pdfResult = '';

		// 		var doc = new jsPDF('p', 'pt', 'letter');

		// 		var imgData = canvas.toDataURL('image/jpeg');

		// 		var pageSize = doc.internal.pageSize
		// 		var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
					
		// 		var imgWidth = pageSize.getWidth() - 10;
		// 		// var pageHeight = 295;
		// 		var imgHeight = canvas.height * imgWidth / canvas.width;
		// 		var heightLeft = imgHeight;
		// 		// var doc = new jsPDF('l', 'px', 'letter');
		// 		var position = 0;

		// 		doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
		// 		doc.setProperties({ title: fileName, author: 'Global Desk', creator: 'Global Desk' });
		// 		doc.save(fileName+'.pdf');
		// 		// pdfResult = doc.output('datauristring');
		// 		// console.log(pdfResult)

		// 		// var split = pdfResult.split(';')
		// 		// console.log(split)
		// 		// // console.log(split[0] +';'+split[2])
		// 		// console.log(split[2].split('base64,'))
		// 		// return pdfResult;
		// 	})

		// 	// this.showViewer = true
		// },
		
		close(){
			this.pdfDialog = false
			this.pdfSrc = ''
		}
	},
}
</script>

<style>
    #voucher {
				padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    #logo {
        float: left;
        text-align: center;
        margin-left: 2%;
        margin-right: 2%;
        margin-bottom: 1%;
    }

    #image {
        width: 100px;
    }

    #companyname {
        font-size: 14px;
    }

    #branchdata {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #vouchertype {
        padding: 30px;
        font-family: Arial, sans-serif;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    #branchname {
        font-size: 12px;
    }

    #saledata {
        float: left;
        padding: 20px;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;

        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    #voheaders {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
    }



    section {
        clear: left;
    }

    .uppercase {
        text-transform: uppercase;
    }

    #customer {
        text-align: left;
    }

    #vocustomer {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 12px;
    }


    #detheader {
        color: #000000;
        margin-top: 10px;
    }

    #details {
        min-height: 700px;
    }

    #detable {
        font-size: 12px;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #detable thead {
        padding: 20px;
        background: #F1F1F1;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #detable thead tr th {
        padding: 5 0 5 0;
        border: 1px solid #000000;
    }

    #totaltable {
        width: 100%;
        font-size: 12px;
    }

    #gracias {
        text-align: center;
    }
</style>