<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		:close-on-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<base-text-field
				v-model="dateFormatted"
				:label="label"
				hint="Formato DD/MM/YYYY"
				persistent-hint
				prepend-icon="mdi-calendar"
				readonly
				v-bind="$attrs"
				@blur="date = parseDate(dateFormatted)"
				v-on="on"
			></base-text-field>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			scrollable
			:first-day-of-week="1"
			locale="bo"
			:max="maxDate"
			@input="inputDate"
		>
			<v-spacer></v-spacer>
			<v-btn
				text
				color="info"
				@click="cancelButton"
			>
				Cancel
			</v-btn>
			<v-btn
				text
				color="primary"
				@click="todayButton"
			>
				Hoy
			</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'BaseDatePicker',

	props: {
		label: {type:String, default:'Fecha'},
	},

	data() {
		return {
			date: null,
			maxDate: this.parseDate(new Date().toLocaleDateString('bo')),
			dateFormatted: '',
			menu: false,
		}
	},

	watch: {
		date (val) {
			this.dateFormatted = this.formatDate(this.date)
		},
	},

	computed: {
	},

	methods: {
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
		},

		parseDate(date) {
			if (!date) return null

			const [day, month, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},

		cancelButton(){
			this.date = null
			this.menu = false
			this.$emit('date', null);
		},

		inputDate(){
			this.menu = false
			this.$emit('date', this.dateFormatted = this.formatDate(this.date));
		},

		todayButton(){
			this.menu = false
			this.date = this.maxDate
			this.$emit('date', this.dateFormatted = this.formatDate(this.date));
		}
	},
}
</script>