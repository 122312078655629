<template>
	<div>
		<v-data-iterator :class="classes" 
			:items="items"
			:items-per-page.sync="itemsPerPage.value"
			:page.sync="page"
			@page-count="numberOfPages = $event"
			:search="search"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			:custom-filter="customFilter"
			hide-default-footer
			v-bind="$attrs"
			v-on="$listeners"
			class="white"
		>
			<template v-if="!hideHeader" v-slot:header>
				<v-toolbar v-if="$vuetify.breakpoint.mdAndUp" dark color="blue darken-1" class="mb-2">
					<template v-if="!hideReloadButton">
						<base-btn color="secondary" @click="$emit('loadDataTable')" icon="mdi-reload" btnText="Actualizar"></base-btn>
						<v-divider class="mx-4" inset vertical></v-divider>
					</template>

					<base-text-field color="white"
						v-model="search"
						class="text-xs-center"
						clearable
						single-line
						hide-details
						prepend-inner-icon="mdi-magnify"
						label="Búsqueda"></base-text-field>

					<v-spacer></v-spacer>

					<base-select color="white"
						v-bind:value="sortBy"
						v-on:change="$emit('change', $event)"
						single-line
						hide-details
						:items="keys"
						prepend-inner-icon="mdi-sort"
						label="Ordene por"
					></base-select>

					<v-btn-toggle
						v-model="sortDesc"
						dense
						rounded
						group
						mandatory
					>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" depressed color="blue"	:value="false">
									<v-icon>mdi-sort-ascending</v-icon>
								</v-btn>
							</template>
							<span>{{ ascTooltip }}</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" depressed color="blue" :value="true">
									<v-icon>mdi-sort-descending</v-icon>
								</v-btn>
							</template>
							<span>{{ descTooltip }}</span>
						</v-tooltip>
					</v-btn-toggle>

					
					<template v-if="!hideNewItemButton">
						<v-divider class="mx-4" inset vertical></v-divider>
						<base-btn color="primary" @click="$emit('newItem')" icon="mdi-plus-thick" btnText="Nuevo"></base-btn>
					</template>
				</v-toolbar>

				<v-card v-else dark color="blue darken-1" class="mb-4" elevation="10">
					<v-container class="no-padding px-4" fluid>
						<v-row dense align="center" justify="center" class="pb-1">
							<v-col cols="12" sm="6" >
								<base-text-field color="white"
								v-model="search" 
								class="text-xs-center"
								clearable
								single-line
								hide-details
								prepend-inner-icon="mdi-magnify"
								label="Búsqueda"></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="d-flex justify-center">
								<base-select color="white"
									v-bind:value="sortBy"
									v-on:change="$emit('change', $event)"
									single-line
									hide-details
									:items="keys"
									prepend-inner-icon="mdi-sort"
									label="Ordene por"
									class="pt-2 mr-1"
								></base-select>

								<v-btn-toggle
									v-model="sortDesc"
									dense
									rounded
									group
									mandatory
								>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn v-on="on" depressed color="blue"	:value="false">
												<v-icon>mdi-sort-ascending</v-icon>
											</v-btn>
										</template>
										<span>{{ ascTooltip }}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn v-on="on" depressed color="blue" :value="true">
												<v-icon>mdi-sort-descending</v-icon>
											</v-btn>
										</template>
										<span>{{ descTooltip }}</span>
									</v-tooltip>
								</v-btn-toggle>
							</v-col>
							<v-col cols="12" sm="12" align="center" class="d-flex justify-center">
								<v-btn v-if="!hideReloadButton" rounded color="secondary" @click="$emit('loadDataTable')">
									<v-icon left>mdi-reload</v-icon> Actualizar
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn v-if="!hideNewItemButton" rounded color="primary" @click="$emit('newItem')">
									<v-icon left>mdi-plus-thick</v-icon> Nuevo
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card>

				<v-dialog v-model="showDialog" persistent scrollable  max-width="800px">
					<slot name="content"></slot>
				</v-dialog>
			</template>

			<template v-slot:default="props">
				<slot name="props" v-bind:props="props"></slot>
			</template>

			<template v-slot:no-data>
				<div class="text-center py-4 grey--text">
					<slot name="no-data">Datos no disponibles.</slot>
				</div>
			</template>

			<template v-slot:no-results>
				<v-alert
					text
					elevation="2"
					class="text-center"
					color="red"
					transition="scale-transition"
				>
					<v-icon color="red" left>mdi-alert-circle</v-icon> <slot name="no-results">No se encontraron registros coincidentes con <strong>'{{search}}'</strong></slot>
				</v-alert>
			</template>

			<template v-if="!hideFooter" v-slot:footer>
				<v-divider class="my-0"></v-divider>
				<v-container fluid>
					<v-row align="center" justify="center" no-gutters>
						<v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''">
							<span v-if="$vuetify.breakpoint.smAndDown" class="grey--text pt-2">Filas</span>
							<span v-else class="grey--text">Filas por página</span>
							<v-menu offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										dark
										text
										color="grey"
										:class="$vuetify.breakpoint.smAndDown ? '' : 'ml-2'"
										v-bind="attrs"
										v-on="on"
									>
										{{ itemsPerPage.text }}
										<v-icon>mdi-chevron-down</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item
										v-for="(number, index) in itemsPerPageArray"
										:key="index"
										@click="updateItemsPerPage(number)"
									>
										<v-list-item-title>{{ number.text }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="6" :align="$vuetify.breakpoint.smAndDown ? '' : 'end'" :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''">
							<span v-if="$vuetify.breakpoint.smAndDown" class="mr-4 pt-2 grey--text">
								Pág. {{ page }} de {{ numberOfPages }}
							</span>
							<span v-else class="mr-4 grey--text">
								Página {{ page }} de {{ numberOfPages }}
							</span>

							<v-btn
								fab
								text
								small 
								
								class="mr-1"
								@click="formerPage"
							>
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>
							<v-btn
								fab
								text
								small 
								
								class="ml-1"
								@click="nextPage"
							>
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</v-data-iterator>
	</div>
</template>

<script>
export default {
	name: 'BaseDataTable',

	model: {
    prop: 'sortBy',
    event: 'change'
	},
	
	props: {
		headers: Array,
		items: {
      type: Array,
      default: [],
    },
		sortBy: String,
		desc: {type: Boolean, default: false},
		hideReloadButton: {type: Boolean, default: false},
		hideNewItemButton: {type: Boolean, default: false},
		showDialog: {type: Boolean, default: false},
		hideHeader: {type: Boolean, default: false},
		hideFooter: {type: Boolean, default: false},
		elevation: {type: [String, Number], default: 1},

		showBorder: {type: Boolean, default: false}
	},

	data() {
		return {
			search: '',
			itemsPerPageArray: [
				{text: '4', value: 4},
				{text: '8', value: 8},
				{text: '12', value: 12},
				{text: '20', value: 20}
			],
			page: 1,
			itemsPerPage: {text: '8', value: 8},
			numberOfPages: 0,
			sortDesc: false,
			ascTooltip:'Orden Ascendente',
			descTooltip:'Orden Descendente',
			filterFields:[],
		}
	},

	watch: {
		items:{
			deep: true,
			immediate: true,
			handler(val){
				if (this.hideFooter) {
					const rows = val.length;
					this.itemsPerPage = Object.assign({}, {text: rows, value: parseInt(rows, 10)})	
				}
				return val;
			}
		},
	},

	computed: {
		classes() {
			let result = []
			result.push(`elevation-${this.elevation}`)
			if (this.showBorder) {
				result.push('data-table-border')
			}
			return result;
		},

		keys(){
			var result=[];
			this.headers.map(function(x){
        if (x.sortable === undefined || x.sortable === true) {
					result.push({text:x.text, value:x.value});
        }
			});

			function compare(a, b) {
				if (a.text < b.text)
					return -1;
				if (a.text > b.text)
					return 1;
				return 0;
			};
			result = result.sort(compare);
			return result;
		},
	},

	created() {
		this.sortDesc = this.desc;
		this.getFilterFields();
	},

	methods: {
		nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
		},
		
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
		},
		
		updateItemsPerPage (number) {
			if (number.value === -1) this.page = 1
			this.itemsPerPage = Object.assign({}, number)
		},

		getFilterFields(){
			const result = [];
			this.headers.map(function(x){
        if (x.filter === undefined || x.filter === true) {
					result.push(x.value);
        }
			});
			this.filterFields = result;
		},

		customFilter(items, search) {
			if (search === null || search === undefined || search === '') {
				return items;
			}
			search = search.toString().toLowerCase()
			const result = items.filter(i => (
				Object.keys(i)
				.filter(key => this.filterFields.includes(key))
				.some(j => this.filter(i[j], search))
			))
			return result;
		},

		filter(item, search){
			item = item.toString().toLowerCase()
			return item.includes(search)
		},
	},
}
</script>

<style scoped>
.data-table-border {
  border: 1px solid rgb(207, 207, 207);
}
</style>