<template>
	<v-select :color="color" 
		no-data-text="Datos no disponibles."
		v-bind="$attrs"
    v-on="$listeners"
		:label="customLabel"
		:required="required"
		placeholder="Seleccione..."
	>
		<template v-slot:selection="{ item }">
			<slot name="selection" v-bind="{ item }">
				{{ item.text }}
			</slot>
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'BaseSelect',

	props: {
		label: {type: String },
		required: {type: Boolean, default: false},
		color: {type: String, default: 'info'}
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		},
	},
}
</script>