<template>
	<v-dialog
		v-model="dateModal"
		persistent
		width="290px"
	>
		<template v-slot:activator="{ on }">
			<base-text-field
				v-model="dateFormatted"
				:label="label"
				placeholder="dd/mm/yyyy"
				hint="Formato DD/MM/YYYY"
				persistent-hint
				prepend-icon="mdi-calendar"
				readonly
				v-bind="$attrs"
				@blur="date = parseDate(dateFormatted)"
				v-on="on"
			></base-text-field>
		</template>

		<v-date-picker v-if="birthdateMode"
			ref="birthdatePicker"
			v-model="date"
			no-title
			scrollable
			:first-day-of-week="1"
			locale="bo"
			:max="maxDate"
			min="1950-01-01"
			@change="save"
		>
			<v-spacer></v-spacer>
			<v-btn
				text
				color="info"
				@click="cancelButton"
			>
				Cancelar
			</v-btn>
		</v-date-picker>

		<v-date-picker v-else
			v-model="date"
			no-title
			scrollable
			:first-day-of-week="1"
			locale="bo"
			:min="onlyLaterDates ? maxDate : null"
			:max="onlyLaterDates ? null : maxDate"
			@input="inputDate"
		>
			<v-spacer></v-spacer>
			<v-btn
				text
				color="info"
				@click="cancelButton"
			>
				Cancelar
			</v-btn>
			<v-btn
				text
				color="primary"
				@click="todayButton"
			>
				Hoy
			</v-btn>
		</v-date-picker>
	</v-dialog>
</template>

<script>
export default {
	name: 'BaseDatePickerDialog',

	model:{
		prop:'input',
		event:'getValue'
	},

	props: {
		input: {type: String, default: null}, 
		label: {type:String, default:'Fecha'},
		birthdateMode: {type: Boolean, default: false},
		onlyLaterDates: {type: Boolean, default: false},
	},

	data() {
		return {
			date: null,
			maxDate: this.parseDate(new Date().toLocaleDateString('bo')),
			dateFormatted: '',
			dateModal: false,
		}
	},

	watch: {
		input (val) {
			this.date = this.parseDate(val)
		},
		date (val) {
			this.dateFormatted = this.formatDate(this.date)
		},
		dateModal (val) {
			if (this.birthdateMode) {
				val && setTimeout(() => (this.$refs.birthdatePicker.activePicker = 'YEAR'))
			}
		},
	},

	computed: {
	},

	created() {
		this.date = this.parseDate(this.input)
	},

	methods: {
		save (date) {
			this.dateModal = false
			this.$emit('getValue', this.dateFormatted = this.formatDate(date));
		},

		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
		},

		parseDate(date) {
			if (!date) return null

			const [day, month, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},

		cancelButton(){
			this.dateModal = false
			this.$emit('getValue', this.dateFormatted);
		},

		inputDate(){
			this.dateModal = false
			this.$emit('getValue', this.dateFormatted = this.formatDate(this.date));
		},

		todayButton(){
			this.dateModal = false
			this.date = this.maxDate
			this.$emit('getValue', this.dateFormatted = this.formatDate(this.date));
		}
	},
}
</script>