<template>
	<div>
		<v-tooltip v-model="show" bottom>
			<template v-slot:activator="{ on }">
				<v-hover>
					<template v-slot="{ hover }">
						<v-btn :elevation="hover ? btnMaxElevation : btnMinElevation"
							text
							@click="$emit('open')" 
							v-on="on"
							class="px-0"
						>
							<v-icon left color="info darken-1">mdi-calendar-check-outline</v-icon> {{dateTimeToShow}}
						</v-btn>
					</template>
				</v-hover>
			</template>
			<span>Ver Fechas</span>
		</v-tooltip>

		<v-dialog :fullscreen="$vuetify.breakpoint.mobile || $vuetify.breakpoint.smAndDown" v-model="showDialog" persistent scrollable :max-width="maxWidth">
			<base-form-simple
				:formTitle="formTitle"
				:formLoading="formLoading"
				hideLabelRequiredFields
				hideSaveButton
				cancelButtonText="Cerrar"
				@cancelButton="$emit('close')"
			>
				<template v-slot:formContent>
					<v-container class="no-padding pt-5" fluid>
						<base-data-table 
							:headers="headers" 
							:items="items"
							:sortBy="sortBy"
							hideHeader
							hideFooter
							elevation="0"
							showBorder
						>
						</base-data-table>
					</v-container>
				</template>
			</base-form-simple>
		</v-dialog>
	</div>
</template>
<script>
export default {
	name: 'BaseFormDateList',

	props: {
		btnMinElevation: { type: [Number, String], default: 0 },
		btnMaxElevation: { type: [Number, String], default: 0 },
		dateTimeToShow: {type: String, default: ''},

		showDialog: {type: Boolean, default: false},
		maxWidth: { type: [Number, String], default: '800px' },

		formTitle: {type: String, required: true},
		formLoading: {type: Boolean, default: false},

		headers: Array,
		items: {
      type: Array,
      default: [],
    },
		sortBy: String,
	},

	data() {
		return {
			show: false,
		}
	},
}
</script>