<template>
	<v-tooltip v-model="show" bottom>
		<template v-slot:activator="{ on }">
			<v-hover>
				<template v-slot="{ hover }">
					<v-icon class="mx-1" :class="classes"
						v-bind="$attrs" 
						v-on="on" 
						:size="hover ? maxSize : minSize"
					>
						<slot/>
					</v-icon>
				</template>
			</v-hover>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'BaseIcon',

	props: {
		classes: { type : String },
		tooltip: { type: String, default: 'Tooltip...'},
		minSize: { type: [Number, String], default: 25 },
		maxSize: { type: [Number, String], default: 30 },
	},

	data() {
		return {
			show: false,
		}
	},
}
</script>