<template>
	<v-img v-bind="$attrs" v-on="$listeners"
		:src="image ? image : defaultProductImage"
		:lazy-src="defaultProductImage"
		v-observe-visibility="{
			callback: (isVisible, entry) => visibilityChanged(isVisible, entry),
			once: true,
			throttle: 500,
			intersection: {
				threshold: 0.9,
			},
		}"
	>
		<template v-slot:placeholder>
			<v-row
				class="fill-height ma-0"
				align="center"
				justify="center"
			>
				<v-progress-circular
					indeterminate
					color="grey lighten-5"
				></v-progress-circular>
			</v-row>
		</template>
	 <slot/>
	</v-img>
</template>
<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: 'BaseProductImg',
	mixins: [MainExternalData],

	model:{
		prop:'image',
		event:'change'
	},

	props: {
		productId: {type: Number, required: true},
		image: {type: String},
	},

	watch: {
		image(val) {
			if (val == null || val == undefined) {
				this.getMainImage();
			}
		}
	},

	methods: {
		visibilityChanged(isVisible, entry) {
			if (!entry.isIntersecting) {
				return;
			}
			if (!isVisible) {
				return;
			}
			if (!!this.image) {
				return;
			}
			this.getMainImage()
		},

		async getMainImage(){
			let me=this;
			let newImage = me.defaultProductImage;
			let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'productId': me.productId,
			};
			await me.getObjectResponse('api/Product/MainImage', request).then(data => {
				if (data !== undefined && data.mainImage != null) {
					newImage = data.mainImage;
				}
			}).catch(function(error){
				console.log(error)
			});
			me.$emit('change', newImage);
		},
	},
}
</script>