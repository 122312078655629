<template>
	<v-row>
		<!-- ProductForm = 2 Clothing -->
		<base-product-clothing-details v-if="productFormId===2" :details="details">
		</base-product-clothing-details>
		<!-- ProductForm = 3 Furniture -->
		<base-product-furniture-details v-else-if="productFormId===3" :details="details">
		</base-product-furniture-details>
	</v-row>
</template>

<script>
export default {
	name: 'BaseFormProductDetails',

	props: {
		productFormId: {type: Number, required: true},
		details: {type: Object, required: true}
	},
}
</script>