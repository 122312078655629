import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chart'
import './plugins/form'
import './plugins/stock'
import './plugins/product'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueObserveVisibility from 'vue-observe-visibility'
import VueMask from 'v-mask'
import Constants from './plugins/constants'
import indexedDb from './plugins/indexedDb'
import Tools from './plugins/tools'
import 'sweetalert2/dist/sweetalert2.min.css'
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(Constants, { appVersion: '2.3.3' })
Vue.use(indexedDb, {
  database: 'gavcomdb',
  version: 1
})
Vue.use(Tools)
Vue.use(VueMask)
Vue.use(VueObserveVisibility)
Vue.use(VueSweetalert2, options)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCqm_OXyaPDAX9PVgduF6Q4u5HZAWPHPd8'
  },
  installComponents: true
})
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://gavcom.globaldesk.com.bo/'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
