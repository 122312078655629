<template>
	<v-dialog
		ref="dialog"
		v-model="timeModal"
		:return-value.sync="time"
		persistent
		width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<base-text-field
				v-model="time"
				:label="label"
				prepend-icon="mdi-clock-time-four-outline"
				readonly
				v-bind="attrs"
				v-on="on"
			></base-text-field>
		</template>
		<v-time-picker
			v-if="timeModal"
			v-model="time"
			full-width
			scrollable
			format="24hr"
		>
			<v-spacer></v-spacer>
			<v-btn
				text
				color="info"
				@click="timeModal = false"
			>
				Cancelar
			</v-btn>
			<v-btn
				text
				color="primary"
				@click="$refs.dialog.save(time)"
			>
				OK
			</v-btn>
		</v-time-picker>
	</v-dialog>
</template>

<script>
export default {
	name: 'BaseTimePicker',

	model: {
    prop: 'time',
    event: 'getValue'
	},

	props: {
		time: {type: String, default: null},
		label: {type: String, default: 'Rango de Horas'},
	},

	data() {
		return {
			timeModal: false,
		}
	},

	computed: {
		validTime(){
			if (!this.time) {
				return false
			}
			const [hh, mm] = this.time.split(':')
			if (!hh || !mm) {
				return false
			}
			return true
		},
	},

	created() {
  },

	methods: {
		cancelButton(){
			this.time=null;
			this.timeModal = false
			this.$emit('getValue', null);
		},

		okButton(){
			if (!this.validTime) {
				return;	
			}
			this.timeModal = false
			this.$emit('getValue', this.time);
		}
	},
}
</script>